import { useMapQuery } from '@api/map'
import { ISynchronizer } from '@common/services/synchronizer/synchronizer'
import { toSeconds } from '@common/utils/time'
import {
  getMainItems,
  TimelineItemData,
  useTimelineContext,
} from '@modules/timelineViewport'
import { useDriveTrialContext } from '@pages/Details/providers/DriveTrialDataProvider'
import { useEffect } from 'react'
import { DriveCoords, GpsPoint } from '../types/map'
import { drawStartEndMarkers, generateHighlightCords } from '../utils/map'
import { useMapContext } from './useMapContext'

export function useMapCoordinatesAndBounds({
  synchronizer,
  viewportId,
}: {
  synchronizer?: ISynchronizer
  viewportId: number
}) {
  const { highlightMode } = useDriveTrialContext()
  const { timelineSettings, items: timelineItems } = useTimelineContext()

  const {
    highlightLength: timelineSettingsHighlightLength,
    sortBy: timelineSettingsSortBy,
  } = timelineSettings

  const { data } = useMapQuery()

  const {
    setMainHighlightItems,
    setGpsCoordinates,
    setDriveGps,
    setStartEndMarkers,
    setGpsHighCoordinates,
    setGpsHighMap,
    setGpsHighMapData,
  } = useMapContext()

  useEffect(() => {
    if (data?.map) {
      const cords = data.map.map(
        ({ longitude, latitude, speed, yaw }) =>
          [longitude, latitude, speed, yaw] as GpsPoint
      )

      if (highlightMode.id !== -1) {
        const highCords = highlightMode.items.map(
          ({ originalStart, originalEnd, start, dtid }) => ({
            originalStart: Math.floor(originalStart! / 1000),
            originalEnd: Math.floor(originalEnd! / 1000),
            start: Math.floor(toSeconds(start as number)),
            dtid: dtid!,
          })
        )
        const highCordsResult = generateHighlightCords(cords, highCords)
        setGpsHighCoordinates(highCordsResult.highlightCords)
        setGpsHighMap(highCordsResult.highMap)
        setGpsHighMapData(highCordsResult.highMapData)

        const items: TimelineItemData[] = []

        timelineItems.forEach((item) => {
          if (item.isItem) {
            items.push(item)
          }
        })

        const mainItems = getMainItems(
          items,
          highlightMode.id,
          timelineSettingsHighlightLength,
          timelineSettingsSortBy
        )

        setMainHighlightItems(mainItems)
      } else {
        setGpsHighCoordinates([])
        setGpsHighMap({})
      }

      const coordsPerDrive = data.map.reduce((acc, item) => {
        const existingDriveData = acc.find((x) => x.key === item.dtid)
        if (existingDriveData) {
          existingDriveData.coordinates?.push([
            item.longitude,
            item.latitude,
            item.speed,
            item.yaw,
          ])
        } else {
          acc.push({
            key: item.dtid,
            coordinates: [
              [item.longitude, item.latitude, item.speed, item.yaw],
            ],
            speeds: [item.speed],
            yaws: [item.yaw],
          })
        }
        return acc
      }, [] as DriveCoords[])

      setGpsCoordinates(cords)
      setDriveGps(coordsPerDrive)
      setStartEndMarkers(drawStartEndMarkers(coordsPerDrive))
    }
    // run on viewport change and on initial fetch, synchronizer is also
    // needed to prevent bloking video playing on `viewportId` change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.map, viewportId, synchronizer, highlightMode])
}
