import {
  ColumnPinningState,
  SortingState,
  VisibilityState,
} from '@tanstack/react-table'
import { RuleGroupType } from 'react-querybuilder'
import { INIT_OBJECTS_STATE } from '../constants/initialStates'
import { KpiSummaryState } from '../../sidebarToolbox/types/kpiFilters'
import { FilterObject, FiltersBySqlTable } from '../../../models/table'
import { SparklineVariant } from '@common/components/Sparkline/Sparkline'

export interface FetchByOrderState {
  sortBy: string
  sortDirection: 'asc' | 'desc'
}

export interface DisplayModeColumns {
  [key: string]: SparklineVariant
}

export interface Environment {
  weather: string
  roadType: string
  timeOfDay: string
  country: string
}

export type ObjectKeyType = keyof typeof INIT_OBJECTS_STATE

export type FilterObjects = {
  [key in ObjectKeyType]: string | number | undefined
}

export interface General {
  dateRange: string[] | null[]
  processedDateRange: string[] | null[]
}

export interface FiltersState {
  environmentFilters: Environment
  objectFilters: FilterObjects
  generalFilters: General
}

export interface SearchState {
  column?: string[]
  value?: string | number
}

export type ReportType =
  | 'completeness'
  | 'quality'
  | 'characterization'
  | 'lanes'
  | 'signs'
  | 'lights'
  | 'ttd'

export type DashboardParametersType = {
  roadType: string
  weather: string
  timeOfDay: string
}

export type ExternalLinkParametersType = {
  clientID: string
  projectID: string
  clause: string
  startDate: string
  endDate: string
  roadType: string
  timeOfDay: string
  weatherConditions: string
  speedRange: string
  version: string
  kpi: string
  limit: number
  offset: number
  sortColumn: string
  sortOrder: string
}

export type PerserveState = {
  [key in ReportType]: {
    columnVisibility: VisibilityState
    columnPinning: ColumnPinningState
    sorting: SortingState
    fetchByOrder: FetchByOrderState
    filterState?: FiltersState
    filterToSql?: FiltersBySqlTable
    filterKpiState?: KpiSummaryState
    filterKpiToSql?: FilterObject
    displayModeColumns?: DisplayModeColumns
    queryBuilderState?: RuleGroupType
    clause: string
    searchType: SearchType
    sections?: {
      sorting: SortingState
      fetchByOrder: FetchByOrderState
    }
    details?: {
      sorting: SortingState
      fetchByOrder: FetchByOrderState
    }
  }
}

export interface HandleQueryBuilder {
  resetQuery: () => void
}

export type SearchType = 'filter' | 'sql'

export interface BreadcrumbItem {
  name: number | string
  data?: { [key: string]: any }
}

export type ReportDepthRules = {
  [key in ReportType]: number
}

export type HeadersResponse = {
  reportHeaders: Header[]
}

export type Header = {
  id: number
  name: string
  reportID: number
  parentID?: number
}

export type TableHeaders = {
  [key: number]: Header[]
}

export type SubHeader = {
  id?: number
  name: string
}

export type SuperHeader = {
  id?: number
  name: string
  subheaders: SubHeader[]
}

export const TABLE_TYPE_HEADERS = {
  lanes: 1,
  signs: 2,
  lights: 3,
  completeness: 4,
  quality: 5,
  characterization: 6,
  ttd: 1,
  objects: 100,
}

export type DisplayModeOptions = 'bars' | 'line' | 'value&bars' | 'value&line'

export interface SelectedRow {
  published?: boolean
  DTID: number
  parentDTID: number
  version: string
}
