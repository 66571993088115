import { useProjectData } from '@api/table/projects'
import { MediaSyncContext } from '@pages/Details/types/providers'
import { JUMP_FRAME_TIME_OFFSET } from '@common/constants/transport'
import { useCallback, useContext } from 'react'

export type UpdateTimeEvent =
  | React.FocusEvent<HTMLInputElement, Element>
  | React.KeyboardEvent<HTMLInputElement>

export function useUpdateTime() {
  const mediaSyncContext = useContext(MediaSyncContext)

  const { frameRate } = useProjectData()

  return useCallback(
    (e: UpdateTimeEvent) => {
      mediaSyncContext.isSeeking = true
      const time = +e.currentTarget.value / frameRate
      mediaSyncContext.timingObj?.update({
        position: time + JUMP_FRAME_TIME_OFFSET,
      })
    },
    [frameRate, mediaSyncContext]
  )
}
