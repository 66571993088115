import { SPR_ITEM_LIMIT } from '@modules/gallery'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useDriveTrialDescriptions } from '../driveTrial/descriptions'
import { urls } from '../urls'
import { useReportContext } from '@modules/reportTable'

export interface SprItem {
  dtid: number
  frameId: number
  url: string
  isDelegate: boolean
  delegateType?: DelegateType
  totalConstituents?: number
  description?: string
}

interface SprResp {
  data: SprItem[]
  total: number
}

export enum DelegateType {
  RARE = 'Rare',
  COMMON = 'Common',
}

export const useSprGalleryQuery = () => {
  const { projectID } = useReportContext()
  const [searchParams] = useSearchParams()

  const day = Number(searchParams.get('day') || 1)
  const page = Number(searchParams.get('page') || 1)
  const dtid = Number(searchParams.get('dtid')) || undefined
  const frameId = Number(searchParams.get('frameId')) || undefined
  const delegateType =
    (searchParams.get('delegateType') as DelegateType) || DelegateType.RARE

  const descriptionsQuery = useDriveTrialDescriptions()

  const sprQuery = useQuery({
    queryKey: ['spr', projectID, page, day, dtid, frameId, delegateType],
    staleTime: Infinity,
    retry: false, // It should fail when it fails in the API
    queryFn: (): Promise<SprResp> =>
      axios
        .post<SprResp>(urls.sprGallery, {
          page,
          limit: SPR_ITEM_LIMIT,
          projectID,
          dtid,
          frameId,
          delegateType,
          day,
        })
        .then(({ data }) => data),
  })

  const data = useMemo(() => {
    if (sprQuery.data?.data && descriptionsQuery.data) {
      return sprQuery.data.data.map((item) => ({
        ...item,
        description: descriptionsQuery.data[item.dtid],
      }))
    }

    return sprQuery.data?.data || []
  }, [descriptionsQuery.data, sprQuery.data])

  return useMemo(
    () => ({
      data,
      isError: sprQuery.isError,
      isLoading: sprQuery.isLoading,
      error: sprQuery.error,
      count: Math.ceil((sprQuery.data?.total || 1) / SPR_ITEM_LIMIT),
      delegateType,
      page,
      dtid,
      frameId,
      day,
    }),
    [
      data,
      sprQuery.isError,
      sprQuery.isLoading,
      sprQuery.error,
      sprQuery.data?.total,
      delegateType,
      page,
      dtid,
      frameId,
      day,
    ]
  )
}
