import { useContext } from 'react'
import { ReportNavigationContext } from '../contexts/ReportNavigationContextProvider'

export function useReportNavigationContext() {
  const context = useContext(ReportNavigationContext)

  if (!context) {
    throw new Error(
      'useReportNavigationContext must be used within a ReportNavigationContextProvider'
    )
  }

  return context
}
