import React, { useEffect, useState } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Button, Menu, MenuItem } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { NestedMenuItem } from 'mui-nested-menu'
import { Dashboard } from '@api/dashboards/dashboards'
import { useProjectData } from '@api/table/projects'
import { MenuItemType } from '../../containers/ReportNavigation'
import { shouldSkipElement } from '@modules/reportTable'
import { useReportNavigationContext } from '@modules/header/hooks/useReportNavigationContext'

interface NestedReportNavigationMenuProps {
  menus: ReadonlyArray<MenuItemType>
  setPage: (page: number) => void
  data: Dashboard[] | undefined
}

export const NestedReportNavigationMenu: React.FC<
  NestedReportNavigationMenuProps
> = ({ menus, setPage, data }) => {
  const { setDashboardURL } = useReportNavigationContext()
  const [reportNavigationAnchorEl, setReportNavigationAnchorEl] =
    useState<HTMLElement>()

  const navigate = useNavigate()
  const { projectID } = useProjectData()

  const navigateToMenu = (
    menu: MenuItemType,
    item: string,
    data: Dashboard[] | undefined
  ) => {
    const { name, routeBase } = menu

    switch (name) {
      case 'SPR Gallery':
      case 'NL Query':
        navigate(routeBase)
        break

      case 'Dashboards': {
        const chosenDashboard = data!.find((x) => x.name === item)
        if (chosenDashboard) {
          navigate(`${routeBase}Dashboard`)
          setDashboardURL(chosenDashboard.url)
        }
        break
      }

      default:
        navigate(`${routeBase}${item}`)
    }
  }

  const handleNavigationClick = (event: React.MouseEvent<HTMLButtonElement>) =>
    setReportNavigationAnchorEl(event.currentTarget)

  const handleCloseNavigation = () => {
    setReportNavigationAnchorEl(undefined)
  }

  useEffect(() => {
    if (data) {
      setDashboardURL(data[0].url)
    }
  }, [data])

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Button
        data-testid={'report-navigation'}
        sx={{
          minWidth: 0,
          textTransform: 'capitalize',
          p: 0,
          color: '#e5e5e5',
          '&:hover': {
            color: '#fff',
          },
        }}
        onClick={handleNavigationClick}
        disableFocusRipple
        disableRipple
        disableTouchRipple
      >
        {'Report Navigation'}
        <ExpandMoreIcon fontSize='small' />
      </Button>

      <Menu
        anchorEl={reportNavigationAnchorEl}
        open={Boolean(reportNavigationAnchorEl)}
        onClose={handleCloseNavigation}
      >
        {menus?.map((menu) => {
          return menu.projects && !menu.projects.includes(projectID) ? null : (
            <NestedMenuItem
              key={menu.name}
              label={menu.name}
              parentMenuOpen={true}
            >
              {menu.menuItems?.map((item) => {
                if (shouldSkipElement(projectID, item)) {
                  return null
                }

                return (
                  <MenuItem
                    data-testid={`${menu.name}-${item}`}
                    sx={{ textTransform: 'capitalize' }}
                    key={item}
                    onClick={() => {
                      menu.close()
                      setPage(1)
                      navigateToMenu(menu, item, data)
                    }}
                  >
                    {item}
                  </MenuItem>
                )
              })}
            </NestedMenuItem>
          )
        })}
      </Menu>
    </div>
  )
}
