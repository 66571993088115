import { useTableQuery } from '@api/index'
import { SendToDetailsData, VisibleColumns } from '@api/redirectionData'
import { PUBLISH_ADMIN } from '@api/table/adminLevel'
import { useEditDriveTrialMutation } from '@api/timeline/tags'
import { enUS } from '@common/constants/messages'
import { TableRouteParams } from '@common/constants/paths'
import { Loader } from '@common/components/Loader/Loader'
import { StyledTooltip } from '@common/components/StyledTooltip/StyledTooltip'
import AspectRatioIcon from '@mui/icons-material/AspectRatio'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import PublishedWithChangesOutlinedIcon from '@mui/icons-material/PublishedWithChangesOutlined'
import UnpublishedOutlinedIcon from '@mui/icons-material/UnpublishedOutlined'
import { Box, Button, ButtonGroup, Typography } from '@mui/material'
import { Column, Row } from '@tanstack/react-table'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { match } from 'ts-pattern'
import { useAuthContext } from '@modules/auth'
import { ReportDepths } from '../../context/ReportContext'
import { SelectedRow } from '../../types'
import { getStorageAdminLevelID } from '@common/utils/storage'
import {
  CsvDownload,
  PdfDownload,
  sendToDetails,
  sendToDetailsKPI,
  useReportContext,
} from '@modules/reportTable'

interface TopTableToolbarProps {
  selectedRowIds: Set<SelectedRow>
  visibleColumns: Column<any, any>[]
  bodyRows: Row<any>[]
  bodyData: any
}

export function TopTableToolbar({
  selectedRowIds,
  visibleColumns,
  bodyRows,
  bodyData,
}: TopTableToolbarProps) {
  const { editDriveTrialMutation } = useEditDriveTrialMutation()
  const { reportType } = useParams<TableRouteParams>()
  const { pathname } = useLocation()
  const {
    sendToDetailsData,
    breadcrumbs,
    preserveState,
    setPage,
    setLimit,
    setBreadcrumbs,
    setSendToDetailsData,
    setSelectedRowIds,
    setPublishCounter,
    page,
    limit,
    setRowSelection,
  } = useReportContext()
  const { adminLevelID } = useAuthContext()
  const { refetch: refreshTable } = useTableQuery()
  const [isLoading, setIsLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const isKPI = pathname.includes('kpi')

  const isExpandingAvailable = () => {
    const reportExpandingDepth = ReportDepths[reportType!]
    return (
      selectedRowIds.size === 1 && breadcrumbs.length <= reportExpandingDepth
    )
  }

  const kpiVisibleColumns = visibleColumns
    .filter(
      (col) =>
        col.id !== 'select' &&
        col.id !== '1000' &&
        (!isKPI ? col.id !== '1001' : true) &&
        !col.columnDef.header?.toString().includes('Summary')
    )
    .filter((c) => !c.parent)
    .map(
      (parent) =>
        ({
          kpi: parent.columnDef.header?.toString(),
          items: parent.columns
            .filter(
              (leaf) =>
                !leaf.columnDef.header?.toString().includes('Recall') &&
                !leaf.columnDef.header?.toString().includes('Accuracy') &&
                leaf.getIsVisible()
            )
            .map((l) => l.columnDef.header?.toString()),
        }) as VisibleColumns
    )
    .filter((superHeader) => superHeader.items.length > 0)

  const handleRedirectionData = (
    sectionIndex: number,
    detailsData: SendToDetailsData,
    selectedRowDTID: number
  ) => {
    match(breadcrumbs.length)
      .with(1, () => {
        detailsData.DTID = selectedRowDTID
        detailsData.sectionId = undefined
        detailsData.detailId = undefined
      })
      .with(2, () => {
        detailsData.sectionId = sectionIndex
        detailsData.detailId = undefined
      })
      .otherwise(() => {
        detailsData.detailId = selectedRowDTID
      })
  }

  const handleSendToDetails = async () => {
    const sectionIndex = getRowIndex()

    let result = true
    if (isKPI) {
      result = await sendToDetailsKPI(
        selectedRowIds,
        reportType!,
        setIsLoading,
        kpiVisibleColumns,
        preserveState
      )

      if (!result) {
        enqueueSnackbar({
          message: enUS.REDIRECTION_ERROR,
          variant: 'error',
        })
      }
      return
    }

    setIsLoading(true)

    const detailsData = {
      ...sendToDetailsData,
    }
    const selectedRowDTID = [...selectedRowIds][0].DTID

    handleRedirectionData(sectionIndex, detailsData, selectedRowDTID)

    setSendToDetailsData(detailsData)

    result = await sendToDetails(
      detailsData,
      selectedRowIds,
      reportType!,
      setIsLoading,
      kpiVisibleColumns,
      breadcrumbs
    )

    if (!result) {
      enqueueSnackbar({
        message: enUS.REDIRECTION_ERROR,
        variant: 'error',
      })
    }
  }

  const getRowIndex = () => {
    const selected = [...selectedRowIds][0].DTID
    const rows: Record<number, any>[] = Object.values(bodyData)
    const selectedIndex = rows.findIndex((row: any) =>
      PUBLISH_ADMIN === getStorageAdminLevelID() && !isKPI
        ? row[100001] === selected
        : row[100000] === selected
    )
    return (page - 1) * limit + selectedIndex
  }

  const handleDrillDown = () => {
    const detailIndex = getRowIndex()

    const detailsData = {
      ...sendToDetailsData,
    }
    const selectedRowDTID = [...selectedRowIds][0].DTID
    handleRedirectionData(detailIndex, detailsData, selectedRowDTID)

    setSendToDetailsData(detailsData)
    const rows: Record<number, any>[] = Object.values(bodyData)
    const data = rows.find((row: any) =>
      PUBLISH_ADMIN === getStorageAdminLevelID() && !isKPI
        ? row[100001] === selectedRowDTID
        : row[100000] === selectedRowDTID
    ) as object

    setPage(1)
    setLimit(50)
    setBreadcrumbs((prevState) => [
      ...prevState,
      {
        name: selectedRowDTID,
        data: {
          ...data,
          0: 'Total',
        },
      },
    ])
  }

  const handlePublish = async () => {
    const dtidsArray = Array.from(selectedRowIds).map((row) => row.DTID)
    const publishedArray = [...selectedRowIds].some((item) => !item.published)
      ? [...selectedRowIds].map(() => 1)
      : [...selectedRowIds].map(() => 0)

    const dtidsString = dtidsArray.join(',')
    const publishedString = publishedArray.join(',')

    try {
      await editDriveTrialMutation({
        dtids: dtidsString,
        published: publishedString,
      })

      setPublishCounter((prev) => prev + 1)
      setRowSelection({})
      setSelectedRowIds(new Set())
      refreshTable()
    } catch (error) {
      console.error('Error updating drive trials:', error)
    }
  }

  const publishedButtonTitle = () => {
    if (!selectedRowIds.size) return 'Publish'
    return [...selectedRowIds].every((item) => item.published)
      ? 'Unpublish'
      : 'Publish'
  }
  return (
    <Box display='flex' justifyContent='space-between'>
      <Box display='flex' justifyContent='space-between'>
        <div>
          <Typography
            variant='h6'
            sx={{ borderLeft: '2px solid white', color: 'white', pl: 1, mr: 4 }}
            textTransform={reportType === 'ttd' ? 'none' : 'capitalize'}
          >
            {reportType === 'ttd' ? reportType.toLocaleUpperCase() : reportType}
          </Typography>
        </div>
        <ButtonGroup size='medium' variant='text' sx={{ marginRight: '10px' }}>
          <StyledTooltip
            title={
              !reportType && kpiVisibleColumns.length === 0
                ? 'Choose KPI columns'
                : selectedRowIds.size === 0
                  ? 'Select rows'
                  : 'Send to details'
            }
            placement='top'
          >
            <span>
              <Button
                disabled={
                  (isKPI && kpiVisibleColumns.length === 0) ||
                  selectedRowIds.size === 0 ||
                  (breadcrumbs.length > 1 && selectedRowIds.size > 1) ||
                  reportType === 'completeness'
                }
                data-testid='sendToDetails'
                sx={{
                  textTransform: 'capitalize',
                  color: 'whitesmoke',
                  paddingRight: isLoading ? '48px' : '',
                }}
                onClick={handleSendToDetails}
              >
                Send to details
                {!isLoading ? (
                  <OpenInNewIcon sx={{ ml: 1 }} fontSize='small' />
                ) : (
                  <div style={{ position: 'absolute', right: 0 }}>
                    <Loader scale={0.25} />
                  </div>
                )}
              </Button>
            </span>
          </StyledTooltip>
          <StyledTooltip title='Drill down selected row' placement='top'>
            <span>
              <Button
                disabled={!isExpandingAvailable()}
                data-testid='openSelected'
                sx={{
                  textTransform: 'capitalize',
                  color: 'whitesmoke',
                }}
                onClick={handleDrillDown}
              >
                Open
                <AspectRatioIcon sx={{ ml: 1 }} fontSize='small' />
              </Button>
            </span>
          </StyledTooltip>
          {adminLevelID === PUBLISH_ADMIN && !isKPI && (
            <StyledTooltip
              title='Publish/Unpublish Drive Trial'
              placement='top'
            >
              <span>
                <Button
                  disabled={!selectedRowIds.size}
                  data-testid='publishUnpublish'
                  sx={{
                    textTransform: 'capitalize',
                    color: 'whitesmoke',
                  }}
                  onClick={handlePublish}
                >
                  {publishedButtonTitle()}
                  {publishedButtonTitle() === 'Publish' ? (
                    <PublishedWithChangesOutlinedIcon
                      sx={{ ml: 1 }}
                      fontSize='small'
                    />
                  ) : (
                    <UnpublishedOutlinedIcon sx={{ ml: 1 }} fontSize='small' />
                  )}
                </Button>
              </span>
            </StyledTooltip>
          )}
        </ButtonGroup>
      </Box>

      <Box>
        <CsvDownload visibleColumns={visibleColumns} bodyRows={bodyRows} />
        <PdfDownload visibleColumns={visibleColumns} bodyRows={bodyRows} />
      </Box>
    </Box>
  )
}
