import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { urls } from '../urls'
import { useReportContext } from '@modules/reportTable'

export interface DriveTrialDescriptionsResponse {
  [dtid: number]: string
}

export const useDriveTrialDescriptions = () => {
  const { projectID } = useReportContext()

  return useQuery({
    queryKey: [urls.driveTrialDescriptions, projectID],
    staleTime: Infinity,
    enabled: !!projectID,
    queryFn: (): Promise<DriveTrialDescriptionsResponse> =>
      axios
        .get<DriveTrialDescriptionsResponse>(urls.driveTrialDescriptions, {
          params: { projectID },
        })
        .then(({ data }) => data),
  })
}
