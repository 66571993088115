import { MapMouseEvent } from 'mapbox-gl'
import { useCallback } from 'react'
import { getNearestPoint } from '../utils/map'
import { useMapContext } from './useMapContext'

export function useHandleMouseOverRoute() {
  const { gpsCoordinates, gpsHighMap } = useMapContext()

  return useCallback(
    (e: MapMouseEvent) => {
      if (!gpsCoordinates.length) return
      const [lat, lng] = getNearestPoint(
        e,
        gpsCoordinates,
        Object.values(gpsHighMap)
      )
      const elementHtml = document.querySelector(
        '.mapboxgl-canvas-container.mapboxgl-interactive.mapboxgl-touch-drag-pan.mapboxgl-touch-zoom-rotate'
      ) as HTMLElement

      if (lat === null && lng === null && elementHtml) {
        elementHtml.style.cursor = 'grab'
        return
      }
      if (elementHtml) elementHtml.style.cursor = 'all-scroll'
    },
    [gpsCoordinates, gpsHighMap]
  )
}
