import { TimelineItemData } from '@modules/timelineViewport'
import mapboxgl, { LngLatBounds } from 'mapbox-gl'
import {
  createContext,
  Dispatch,
  PropsWithChildren,
  RefObject,
  SetStateAction,
  useRef,
  useState,
} from 'react'
import { MapRef } from 'react-map-gl'
import {
  DriveCoords,
  GpsMap,
  GpsMapData,
  GpsPoint,
  MapOptionsRef,
  MarkerSourceRef,
  StartEndMarker,
} from '../types/map'

export interface MapContextState {
  coordinatesRef: RefObject<HTMLSpanElement>
  mapRef: RefObject<MapRef>
  mapOptionsRef: RefObject<MapOptionsRef>
  markerRef: RefObject<MarkerSourceRef>
  gpsCoordinates: GpsPoint[]
  gpsHighCoordinates: GpsPoint[][]
  gpsHighMapData: GpsMapData
  gpsHighMap: GpsMap
  driveGps: DriveCoords[]
  startEndMarkers: StartEndMarker[][]
  mouseDown: boolean
  dragMap: boolean
  showFollowButton: boolean
  followMode: boolean
  initialZoomFinished: boolean
  isPlaying: boolean
  isAlreadyPlayed: boolean
  mapBounds: LngLatBounds
  mainHighlightItems?: TimelineItemData[]
}

export interface MapContextDispatch {
  setGpsCoordinates: Dispatch<SetStateAction<GpsPoint[]>>
  setGpsHighCoordinates: Dispatch<SetStateAction<GpsPoint[][]>>
  setGpsHighMapData: Dispatch<SetStateAction<GpsMapData>>
  setGpsHighMap: Dispatch<SetStateAction<GpsMap>>
  setDriveGps: Dispatch<SetStateAction<DriveCoords[]>>
  setStartEndMarkers: Dispatch<SetStateAction<StartEndMarker[][]>>
  setMouseDown: Dispatch<SetStateAction<boolean>>
  setDragMap: Dispatch<SetStateAction<boolean>>
  setShowFollowButton: Dispatch<SetStateAction<boolean>>
  setFollowMode: Dispatch<SetStateAction<boolean>>
  setInitialZoomFinished: Dispatch<SetStateAction<boolean>>
  setIsPlaying: Dispatch<SetStateAction<boolean>>
  setIsAlreadyPlayed: Dispatch<SetStateAction<boolean>>
  setMapBounds: Dispatch<SetStateAction<LngLatBounds>>
  setMainHighlightItems: Dispatch<
    SetStateAction<TimelineItemData[] | undefined>
  >
}

export interface MapContextType extends MapContextState, MapContextDispatch {}

export const MapContext = createContext<MapContextType>({} as MapContextType)

export function MapContextProvider({ children }: PropsWithChildren) {
  // refs
  const coordinatesRef = useRef<HTMLSpanElement>(null)
  const mapRef = useRef<MapRef>(null)
  const mapOptionsRef = useRef<MapOptionsRef>(null)
  const markerRef = useRef<MarkerSourceRef>(null)

  // data
  const [gpsCoordinates, setGpsCoordinates] = useState<GpsPoint[]>([])
  const [gpsHighCoordinates, setGpsHighCoordinates] = useState<GpsPoint[][]>([])
  const [gpsHighMapData, setGpsHighMapData] = useState<GpsMapData>({})
  const [gpsHighMap, setGpsHighMap] = useState<GpsMap>({})
  const [driveGps, setDriveGps] = useState<DriveCoords[]>([])
  const [startEndMarkers, setStartEndMarkers] = useState<StartEndMarker[][]>([])

  // booleans
  const [mouseDown, setMouseDown] = useState(false)
  const [dragMap, setDragMap] = useState(true)
  const [showFollowButton, setShowFollowButton] = useState(false)
  const [followMode, setFollowMode] = useState(false)
  const [initialZoomFinished, setInitialZoomFinished] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)
  const [isAlreadyPlayed, setIsAlreadyPlayed] = useState(false)

  // other
  const [mapBounds, setMapBounds] = useState<LngLatBounds>(
    new mapboxgl.LngLatBounds([0, 0], [0, 0])
  )
  const [mainHighlightItems, setMainHighlightItems] =
    useState<TimelineItemData[]>()

  const value: MapContextType = {
    coordinatesRef,
    mapRef,
    mapOptionsRef,
    markerRef,
    gpsCoordinates,
    gpsHighCoordinates,
    gpsHighMapData,
    gpsHighMap,
    driveGps,
    startEndMarkers,
    mouseDown,
    dragMap,
    showFollowButton,
    followMode,
    initialZoomFinished,
    isPlaying,
    isAlreadyPlayed,
    mapBounds,
    mainHighlightItems,
    setGpsCoordinates,
    setGpsHighCoordinates,
    setGpsHighMapData,
    setGpsHighMap,
    setDriveGps,
    setStartEndMarkers,
    setMouseDown,
    setDragMap,
    setFollowMode,
    setInitialZoomFinished,
    setIsPlaying,
    setIsAlreadyPlayed,
    setShowFollowButton,
    setMapBounds,
    setMainHighlightItems,
  }

  return <MapContext.Provider value={value}>{children}</MapContext.Provider>
}
