import { groupHeaders } from '@api/headers'
import { enUS } from '@common/constants/messages'
import Breadcrumbs from '@common/components/Breadcrumbs/Breadcrumbs'
import { Alert, Stack } from '@mui/material'
import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import React, { useEffect, useMemo, useRef } from 'react'
import { useAuthContext } from '@modules/auth'
import { ReportType, TABLE_TYPE_HEADERS } from '../../types'
import {
  StyledAlert,
  StyledTableContainer,
} from '../../components/StyledComponents'
import { Table } from '../../components/Table'
import { TopTableToolbar } from '../TopTableToolbar'
import {
  createColumns,
  createData,
  Pagination,
  useReportContext,
} from '@modules/reportTable'
import { useSelectColumn } from '@modules/reportTable/hooks/useSelectColumn'
import { PUBLISH_ADMIN } from '@api/table/adminLevel'

interface TableCreatorProps {
  tableData: any
  reportType: ReportType
}

export function TableCreator({ tableData, reportType }: TableCreatorProps) {
  const tableContainerRef = useRef<HTMLDivElement>(null)
  const {
    preserveState,
    columnPinning,
    setAllColumns,
    limit,
    setColumnPinning,
    breadcrumbs,
    handleBreadcrumbClick,
    headers,
    projectID,
    rowSelection,
    setRowSelection,
    selectedRowIds,
  } = useReportContext()
  const { adminLevelID } = useAuthContext()

  const displayMode = preserveState[reportType].displayModeColumns
  const tableId = TABLE_TYPE_HEADERS[reportType]
  const groupedHeaders = useMemo(() => {
    return headers
      ? groupHeaders(headers[tableId], reportType, adminLevelID || 0)
      : []
  }, [headers, tableId, reportType, adminLevelID])
  const selectColumn = useSelectColumn()

  const columns = React.useMemo(() => {
    // This should be deleted once new endpoint for TTD is completed //
    const filteredGroupedHeaders = groupedHeaders.filter((header) =>
      +projectID === 1010
        ? header.name.includes('TTD') ||
          header.name === 'Drive Trial Identification'
        : !header.name.includes('TTD')
    )
    return [
      selectColumn,
      ...createColumns(
        reportType === 'lanes' || reportType === 'ttd'
          ? filteredGroupedHeaders
          : groupedHeaders,
        displayMode
      ),
    ]
    // On table data change, memorize new columns
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData, displayMode, groupedHeaders])

  const bodyData = React.useMemo(
    () => createData(tableData, groupedHeaders),
    [tableData, groupedHeaders]
  )

  const numberOfRows = Number(tableData?.data.paging?.total)
  const pageCount =
    (tableData && tableData.data && Math.ceil(numberOfRows / limit)) || 0

  const {
    getLeftHeaderGroups,
    getRightHeaderGroups,
    getCenterHeaderGroups,
    getAllColumns,
    getRowModel,
    getVisibleFlatColumns,
    getVisibleLeafColumns,
  } = useReactTable<any>({
    data: bodyData,
    columns,
    state: {
      columnVisibility: preserveState[reportType].columnVisibility,
      columnPinning,
      rowSelection,
      sorting:
        breadcrumbs.length === 1
          ? preserveState[reportType].sorting
          : breadcrumbs.length === 2
            ? preserveState[reportType].sections?.sorting
            : preserveState[reportType].details?.sorting,
    },
    getRowId: (row) =>
      adminLevelID === PUBLISH_ADMIN ? row[100001] : row[100000],
    getCoreRowModel: getCoreRowModel(),
    onColumnPinningChange: setColumnPinning,
    onRowSelectionChange: setRowSelection,
    manualPagination: true,
    manualSorting: true,
  })

  const { rows, flatRows } = getRowModel()
  const visibleColumns = getVisibleFlatColumns()
  const visibleLeafs = getVisibleLeafColumns()

  useEffect(() => {
    setAllColumns(getAllColumns())
  }, [getAllColumns, setAllColumns, columns])

  if (Object.keys(tableData.data).length === 0) {
    return (
      <Stack width={'100%'}>
        {breadcrumbs.length > 1 && (
          <Breadcrumbs
            items={breadcrumbs.map((x) => x.name)}
            handleClick={handleBreadcrumbClick}
          />
        )}
        <Alert variant='standard' severity='info' style={{ marginTop: '20px' }}>
          {enUS.NO_DATA_TO_DISPLAY}
        </Alert>
      </Stack>
    )
  }

  return visibleLeafs.length === 1 ? (
    <StyledAlert variant='standard' severity='info'>
      All columns have been hidden.
    </StyledAlert>
  ) : (
    <Stack direction='column' spacing={1} width='auto' maxWidth='100%'>
      {breadcrumbs.length > 1 && (
        <Breadcrumbs
          items={breadcrumbs.map((x) => x.name)}
          handleClick={handleBreadcrumbClick}
        />
      )}
      <TopTableToolbar
        selectedRowIds={selectedRowIds}
        visibleColumns={visibleColumns}
        bodyRows={flatRows}
        bodyData={bodyData}
      />
      <StyledTableContainer
        ref={tableContainerRef}
        style={{
          maxHeight: `calc(100vh - ${
            breadcrumbs.length > 1 ? '216px' : '152px'
          })`,
        }}
      >
        {getLeftHeaderGroups()[0].headers.length > 0 && (
          <Table
            rows={rows}
            headerGroups={getLeftHeaderGroups()}
            position='left'
            showHeaders
          />
        )}

        {getCenterHeaderGroups()[0].headers.length > 0 && (
          <Table
            rows={rows}
            headerGroups={getCenterHeaderGroups()}
            position='center'
            showHeaders
          />
        )}

        {getRightHeaderGroups()[0].headers.length > 0 && (
          <Table
            rows={rows}
            headerGroups={getRightHeaderGroups()}
            position='right'
            showHeaders
          />
        )}
      </StyledTableContainer>

      {numberOfRows > limit && <Pagination numberOfPages={pageCount} />}
    </Stack>
  )
}
