import { isAxiosError } from 'axios'

// Handle errors with notifications
export const handleError = (error: unknown) => {
  if (isAxiosError(error)) {
    console.error('Axios error:', error.message)
    // Implement user notification logic here
  } else {
    console.error('Unknown error:', error)
    // Implement user notification logic here
  }
}
