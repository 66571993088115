import { CellContext } from '@tanstack/react-table'
import { Sparkline } from '@common/components/Sparkline/Sparkline'
import { DisplayModeOptions } from '../../types'
import { useMemo } from 'react'

interface SparklineFormatterProps {
  cell: CellContext<any, any>
  variant: DisplayModeOptions
  svgHeight: number
  svgWidth: number
}

export function SparklineFormatter({
  cell,
  variant,
  svgHeight,
  svgWidth,
}: SparklineFormatterProps) {
  const value = cell.getValue()
  const dataArr = useMemo(
    () => value?.sparkLinesArray.split(' ').map(Number) || [],
    [value]
  )
  const max = useMemo(
    () => (dataArr.length > 0 ? Math.max(...dataArr) : 0),
    [dataArr]
  )

  if (!value || value.sparkLinesArray === '-') {
    return <div style={{ textAlign: 'center' }}>Sparkline N/A</div>
  }

  const { avgFlag, metricFloatAvg, compoundLabel } = value

  const sparklineVariant =
    variant === 'value&line'
      ? 'line'
      : variant === 'value&bars'
        ? 'bars'
        : variant

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '8px' }}>
      {variant.includes('value') && (
        <span style={{ display: 'block', marginRight: 1 }}>
          {avgFlag ? metricFloatAvg : compoundLabel}
        </span>
      )}
      <Sparkline
        color='#5c6ab8'
        data={dataArr}
        variant={sparklineVariant}
        svgHeight={svgHeight}
        svgWidth={svgWidth}
        max={max <= 1 ? 1 : undefined}
        min={0}
      />
    </div>
  )
}
