import { useContext } from 'react'
import { MapContext, MapContextType } from '../context/MapContext'

export function useMapContext(): MapContextType {
  const context = useContext(MapContext)

  if (!context) {
    throw new Error('useMapContext must be used within a MapContextProvider')
  }

  return context
}
