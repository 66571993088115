import { useKpiSensorTableQuery } from '@api/table/kpiSensorTable'
import { Loader } from '@common/components/Loader/Loader'
import { useParams } from 'react-router-dom'
import { enUS } from '@common/constants/messages'
import {
  ReportType,
  StyledAlert,
  TableCreator,
  useProjectChange,
  useReportChange,
} from '@modules/reportTable'

export default function KpiSensor() {
  const { reportType } = useParams()
  const { data, isLoading, isError, isFetching } = useKpiSensorTableQuery()
  useReportChange()
  useProjectChange()

  if (isError) {
    return (
      <StyledAlert variant='standard' severity='info'>
        {enUS.NO_DATA_TO_DISPLAY}
      </StyledAlert>
    )
  }

  if (isLoading || isFetching) {
    return <Loader text={`KPI Sensor ${reportType} loading`} center />
  }

  return <TableCreator reportType={reportType as ReportType} tableData={data} />
}
