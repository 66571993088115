import { useQuery } from '@tanstack/react-query'
import axios, { AxiosResponse } from 'axios'
import { useLocation, useParams } from 'react-router-dom'
import { DEFAULT_PAYLOAD_LANES_KPI } from './defaultKPIPayloads'
import { TableRouteParams } from '@common/constants/paths'
import { TableResponse } from '../../models/table'
import { getClientID } from '@common/utils/storage'
import { urls } from '../urls'
import { useAuthContext } from '@modules/auth'
import { useReportContext } from '@modules/reportTable'

export const useKpiSystemTableQuery = () => {
  const { reportType } = useParams<TableRouteParams>()
  const { pathname } = useLocation()
  const {
    preserveState,
    limit,
    page,
    hilVersion,
    projectID,
    publishCounter,
    setSearchColumns,
  } = useReportContext()
  const { adminLevelID } = useAuthContext()

  const offset = (page - 1) * limit

  const ttdPayload = {
    ...DEFAULT_PAYLOAD_LANES_KPI,
    clientId: getClientID(),
    projectId: projectID,
    offset,
    limit,
    startDate: '',
    endDate: '',
    timeOfDay: '',
    weatherConditions: '',
    roadType: '',
    clause: preserveState.lanes.clause,
    version: hilVersion === 'all' ? '' : hilVersion,
    sortColumn: preserveState.lanes.fetchByOrder.sortBy,
    sortOrder: preserveState.lanes.fetchByOrder.sortDirection,
    ...preserveState.lanes.filterKpiToSql,
    adminLevelID: adminLevelID || 0,
  }

  return useQuery({
    enabled: pathname.includes('kpi'),
    queryKey: ['kpiSystemTable', ttdPayload, reportType, publishCounter],
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    queryFn: (): Promise<AxiosResponse<TableResponse>> => {
      return axios.post(urls.kpiSummaryTTD, ttdPayload).then((resp) => {
        setSearchColumns(resp.data.searchColumns)
        return resp
      })
    },
  })
}
