import { useRef, useState } from 'react'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import { ButtonGroup } from '@mui/material'
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable'
import { StyledTooltip } from '@common/components/StyledTooltip/StyledTooltip'

const arrowSx = {
  display: 'flex',
  alignItems: 'center',
  border: 'none',
  padding: '8px 0',
  minWidth: '10px',
}

interface IProps {
  min: number
  max: number
  onJog: (value: number) => void
}

export const JogWheel = ({ min, max, onJog }: IProps) => {
  const jogRef = useRef<HTMLDivElement | null>(null)
  const [dragPosition, setDragPosition] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  })
  const [isMouseDown, setIsMouseDown] = useState(false)

  const onStart = () => {
    setIsMouseDown(true)
  }

  const onDrag = (_e: DraggableEvent, dragData: DraggableData) => {
    setDragPosition({ x: dragData.x, y: 0 })
    onJog(dragData.x)
  }

  const onStop = () => {
    setIsMouseDown(false)
    setDragPosition({ x: 0, y: 0 })
    onJog(0)
  }

  return (
    <StyledTooltip title='Jog wheel' placement='top'>
      <ButtonGroup
        variant='text'
        size='large'
        sx={{ display: 'flex', alignItems: 'center' }}
      >
        <div
          data-testid='jogWheelLeft'
          style={{
            ...arrowSx,
            color: `${dragPosition.x < 0 ? '#fff' : '#b8b8b8'}`,
          }}
        >
          <ChevronLeftIcon fontSize='medium' />
        </div>
        <Draggable
          axis='x'
          bounds={{ left: min, right: max }}
          position={dragPosition}
          onStart={onStart}
          onStop={onStop}
          onDrag={onDrag}
          nodeRef={jogRef}
        >
          <div
            data-testid='jogWheelCenter'
            ref={jogRef}
            style={{
              border: 'none',
              padding: '8px 0',
              minWidth: '10px',
              position: 'relative',
              top: 3,
              color: `${isMouseDown ? '#fff' : '#b8b8b8'}`,
              cursor: 'ew-resize',
            }}
          >
            <FiberManualRecordIcon fontSize='small' />
          </div>
        </Draggable>

        <div
          data-testid='jogWheelRight'
          style={{
            ...arrowSx,
            color: `${dragPosition.x > 0 ? '#fff' : '#b8b8b8'}`,
          }}
        >
          <ChevronRightIcon fontSize='medium' />
        </div>
      </ButtonGroup>
    </StyledTooltip>
  )
}
