import { MapMouseEvent } from 'mapbox-gl'
import { useCallback } from 'react'
import { useHandleMouseOver } from './useHandleMouseOver'
import { useMapContext } from './useMapContext'

export function useHandleMouseDown() {
  const { setDragMap, setMouseDown, setFollowMode, setShowFollowButton } =
    useMapContext()
  const handleMouseOver = useHandleMouseOver()

  return useCallback(
    (e: MapMouseEvent) => {
      const isMouseOver = handleMouseOver(e) as boolean
      setFollowMode(false)
      setShowFollowButton(true)
      setDragMap(!isMouseOver)
      setMouseDown(isMouseOver)
    },
    [
      handleMouseOver,
      setDragMap,
      setFollowMode,
      setMouseDown,
      setShowFollowButton,
    ]
  )
}
