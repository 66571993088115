import React from 'react'
import { TableBody, TableFooter } from '@mui/material'
import { Cell, HeaderGroup, Row, flexRender } from '@tanstack/react-table'
import {
  StyledTable,
  StyledTableHead,
  StyledTableHeaderRow,
  StyledTableBodyRow,
  StyledTableBodyCell,
  StyledTableFooterRow,
  StyledTableFooterCell,
} from '../StyledComponents'
import { HeaderCell, useReportContext } from '@modules/reportTable'

interface TableProps {
  rows: Row<any>[]
  headerGroups: HeaderGroup<any>[]
  position: 'left' | 'center' | 'right'
  showHeaders?: boolean
}

export function Table({
  headerGroups,
  position,
  rows,
  showHeaders,
}: TableProps) {
  const { rowSelection } = useReportContext()
  const totalRow = React.useMemo(
    () => rows.find((row) => row.id === 'Total'),
    [rows]
  )

  const isSelected = (id: number) => (rowSelection[id] ? 'row-selected' : '')

  const renderTableCells = (
    visibleCells: Cell<unknown, unknown>[],
    totalRow: boolean = false
  ) =>
    visibleCells.map((cell) =>
      !totalRow ? (
        <StyledTableBodyCell key={cell.id}>
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </StyledTableBodyCell>
      ) : (
        <StyledTableFooterCell key={cell.id}>
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </StyledTableFooterCell>
      )
    )

  return (
    <StyledTable
      data-testid={`table-${position}`}
      size='small'
      position={position}
    >
      {showHeaders && (
        <StyledTableHead position={position}>
          {headerGroups.map((headerGroup) => (
            <StyledTableHeaderRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <HeaderCell key={header.id} header={header} />
              ))}
            </StyledTableHeaderRow>
          ))}
        </StyledTableHead>
      )}
      <TableBody>
        {rows.map(
          (row) =>
            row.id !== 'Total' && (
              <StyledTableBodyRow
                data-testid={`tableRow-${position}-${row.id}`}
                className={`row-${row.id} ${isSelected(+row.id)}`}
                key={`${row.id}_${row.index}`}
              >
                {position === 'left'
                  ? renderTableCells(row.getLeftVisibleCells())
                  : position === 'center'
                    ? renderTableCells(row.getCenterVisibleCells())
                    : position === 'right'
                      ? renderTableCells(row.getRightVisibleCells())
                      : renderTableCells(row.getVisibleCells())}
              </StyledTableBodyRow>
            )
        )}
      </TableBody>
      {totalRow && (
        <TableFooter
          sx={{ position: 'sticky', bottom: 0, backgroundColor: '#CFD4E0' }}
        >
          <StyledTableFooterRow key={totalRow.id}>
            {position === 'left'
              ? renderTableCells(totalRow.getLeftVisibleCells(), true)
              : position === 'center'
                ? renderTableCells(totalRow.getCenterVisibleCells(), true)
                : position === 'right'
                  ? renderTableCells(totalRow.getRightVisibleCells(), true)
                  : renderTableCells(totalRow.getVisibleCells(), true)}
          </StyledTableFooterRow>
        </TableFooter>
      )}
    </StyledTable>
  )
}
