import { enUS } from '@common/constants/messages'
import { Loader } from '@common/components/Loader/Loader'
import { NoData } from '@common/components/NoData/NoData'
import {
  ITimeline,
  TimelineControlled,
} from '@common/components/Timeline/TimelineControlled'
import { moveMainMarker } from '@common/components/Timeline/utils/sliding'
import { applyStyle } from '@common/utils/styling'
import { toMilliseconds, toSeconds } from '@common/utils/time'
import dayjs from 'dayjs'

import {
  TimelineItemType,
  Timeline as VisTimeline,
} from 'otto-vis-timeline/standalone'
import { IdType, TimelineOptions } from 'otto-vis-timeline/types'
import { useDriveTrialContext } from 'pages/Details/providers/DriveTrialDataProvider'
import {
  TrackDisplayMode,
  trackHeightMap,
  TrackParameters,
  useTimelineContext,
} from '@modules/timelineViewport'
import { MediaSyncContext } from '@pages/Details/types/providers'
import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { match } from 'ts-pattern'
import { TagPopup } from '../TagPopup'
import { CustomGroupTemplate } from '../../components/CustomGroupTemplate'
import { KpiTimelineDataFetcher } from '../KPITimelineDataFetcher'
import ShowHidden from '../../components/ShowHidden'
import { TIMELINE_OPTIONS_DEFAULT } from '../../constants/timeline'
import { TimelineSettings } from '../TimelineSettings'
import { useTags } from '../../hooks/useTags'
import { TimelineTrackMenu } from '../TimelineTrackMenu/TimelineTrackMenu'
import { TimelineEventData, VideoTimelineProps } from '../../types/timeline'
import {
  animation,
  controlledTimeMarkerId,
  getTimelineDuration,
} from '../../utils/timeline'
import { VideoTimelineDataFetcher } from '../VideoTimelineDataFetcher'
import { useActiveTrial } from '@common/hooks/useActiveTrial'
import './style.scss'
import { createChartElement } from '@modules/timelineViewport/components/Charts'
import { TagPopupContextProvider } from '@modules/timelineViewport/context/TagPopupContextProvider'
import { useTagPopupContext } from '@modules/timelineViewport/hooks/useTagPopupContext'

const SLIDE_OFFSET_PERCENT = 0.95

const removeStartMarker = (t: React.RefObject<ITimeline>) => {
  try {
    t.current?.removeCustomTime('startMarker')
  } catch (e) {
    console.info('e: ', e)
  }
}

export const getTrackWidth = () => {
  const timelineContent = window.document.getElementsByClassName(
    'vis-content'
  )[0] as HTMLAnchorElement

  const timelineWidth = timelineContent.offsetWidth
  if (timelineWidth) {
    return timelineWidth
  }
  return 0
}

function VideoTimelineContainer({
  synchronizer,
  viewportId,
  signsData,
}: VideoTimelineProps) {
  const mediaSyncContext = useContext(MediaSyncContext)
  const {
    redirectData,
    highlightMode,
    modeKey,
    driveTrials,
    getCurrentDriveTrial,
  } = useDriveTrialContext()
  const timelineContext = useTimelineContext()
  const {
    groups,
    selectedGroup,
    items,
    initialItems,
    setTimeline,
    startMarker,
    endMarker,
    trackParameters,
    shouldMoveMarker,
    setShouldMoveMarker,
  } = timelineContext
  const { setAnchorTagPopup, activeTag, setActiveTag } = useTagPopupContext()
  const { addNotation } = useTags(selectedGroup as IdType)
  const driveTrialData = redirectData?.rows
  const [ctxMenuGroup, setCtxMenuGroup] = useState<number>()
  const [isStartTime, setIsStartTime] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState(true)
  const timelineRef = useRef<ITimeline>(null)
  const sliding = useRef(false)
  const { editTag } = useTags(selectedGroup as IdType)
  const isKpi = location.pathname.includes('kpi-details')
  const start = dayjs(0).toDate()
  const deltaDuration = Math.round(mediaSyncContext.totalDuration / (60 * 5)) // missing end time
  const end = dayjs(0)
    .add(mediaSyncContext.totalDuration + deltaDuration, 'second')
    .toDate()
  const PREFERED_ZOOM = 30 * 60
  const cumulativeDuration =
    driveTrials[driveTrials.length - 1].cumulativeDuration
  const zoomEnd =
    driveTrials.length > 1 && cumulativeDuration > PREFERED_ZOOM
      ? toMilliseconds(PREFERED_ZOOM)
      : toMilliseconds(cumulativeDuration + deltaDuration)
  const controlledTime = useMemo(
    () => ({
      datetime: dayjs(0).toDate(),
      id: controlledTimeMarkerId,
    }),
    []
  )
  const [trackMenuEl, setTrackMenuEl] = useState<null | HTMLElement>(null)
  const [jumpItemId, setJumpItemId] = useState<string | number>(-1)
  const [dataReceived, setDataReceived] = useState<boolean>(false)

  const handleDataReceived = () => {
    setDataReceived(true)
  }

  const createChart = (trackParameters: TrackParameters, key: string) => {
    const trackData = trackParameters[+key]
    const trackHeight = trackHeightMap[trackData.height || 'small']
    const trackWidth = getTrackWidth()
    const lineColor = 'green'
    const id = `graph-${key}`
    const display = trackData.displayMode
    const window = timelineRef.current?.getWindow() || {
      start,
      end: end,
    }
    return {
      start: window.start.getTime(),
      end: window.end.getTime(),
      content: createChartElement(
        window,
        trackData.chartData!,
        lineColor,
        trackHeight,
        trackWidth,
        id,
        display || TrackDisplayMode.Lines
      ) as unknown as HTMLElement,
      id: `${key}-chart`,
      className: 'chart',
      selectable: false,
      group: key,
      title: '',
      type: 'background' as TimelineItemType,
      isItem: false,
    }
  }

  const updateCharts = (trackParameters: TrackParameters | undefined) => {
    if (trackParameters) {
      const graphKeys = Object.keys(trackParameters)
      graphKeys.forEach((key) => {
        if (trackParameters[+key].chartData) {
          const chart = createChart(trackParameters, key)
          timelineContext.items.update(chart)
          timelineContext.initialItems.update(chart)
          timelineContext.originalItems.update(chart)
        }
      })
    }
  }
  useEffect(() => {
    const timeline = timelineRef.current
    if (timeline) {
      const updateOnResize = () => {
        setTimeout(() => updateCharts(trackParameters), 100)
      }
      timeline.on('rangechange', () => updateCharts(trackParameters))
      window.addEventListener('resize', updateOnResize)
      return () => {
        timeline.off('rangechange', () => updateCharts(trackParameters))
        window.removeEventListener('resize', updateOnResize)
      }
    }
  })

  useEffect(() => {
    if (trackParameters) {
      const trackKeys = Object.keys(trackParameters)
      trackKeys.forEach((key) => {
        const trackData = trackParameters[+key]
        const display = trackData.displayMode
        if (display === 'lines' || display === 'bars') {
          const chart = createChart(trackParameters, key)
          timelineContext.items.remove(trackData.items!)
          timelineContext.items.update(chart)
          timelineContext.initialItems.remove(trackData.items!)
          timelineContext.initialItems.update(chart)
        } else if (trackData.items) {
          timelineContext.items.remove(`${key}-chart`)
          timelineContext.items.update(trackData.items!)
          timelineContext.initialItems.remove(`${key}-chart`)
          timelineContext.initialItems.update(trackData.items!)
        }
      })
    }
  }, [trackParameters])

  const handleTimelineMenu = (
    event: React.MouseEvent<HTMLButtonElement>,
    group: IdType
  ) => {
    setCtxMenuGroup(group as number)
    setTrackMenuEl(event.currentTarget)
  }
  const handleCloseTrackMenu = () => {
    setTrackMenuEl(null)
  }

  const handleKey = (event: KeyboardEvent) => {
    timelineRef.current?.setOptions({
      moveable: event.ctrlKey,
    })
  }

  const handleHairColor = (color: string) => {
    const currentTimeBar = document.querySelector('.vis-custom-time')
    if (currentTimeBar instanceof HTMLElement) {
      currentTimeBar.style.backgroundColor = color
    }
  }

  const handleEscKey = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      removeStartMarker(timelineRef)
      timelineContext.setStartMarker!(null)
      setIsStartTime(false)
      handleHairColor('yellow')
    }
  }

  useEffect(() => {
    if (jumpItemId !== -1) {
      mediaSyncContext.isSeeking = true

      const itemsCollection =
        highlightMode.id !== -1 ? highlightMode?.items : items
      const clickedItems = itemsCollection.get({
        filter: (item) => item.id === jumpItemId,
      })
      if (!clickedItems.length) return

      const clickedItem = clickedItems[0]
      const date = dayjs(clickedItem.start).valueOf()
      mediaSyncContext.timingObj?.update({
        position: toSeconds(date + 1),
      })

      if (timelineRef.current) {
        const visibleTime = timelineRef.current.getWindow()
        const windowStart = dayjs(visibleTime.start)

        if (dayjs(clickedItem.start) < windowStart) {
          timelineRef.current.moveTo(
            dayjs(toMilliseconds(mediaSyncContext.timingObj?.pos)).toDate(),
            animation
          )
        }
      }
    }

    setJumpItemId(-1)
  }, [jumpItemId])

  useEffect(() => {
    const activeTrack = document.getElementById(
      `group-template-${highlightMode.id}`
    )
    const otherTracks = document.querySelectorAll('[id*="group-template-"]')

    activeTrack?.classList.add('active-track')
    otherTracks.forEach((x) => {
      if (!x.id.includes(`group-template-${highlightMode.id}`)) {
        x.classList.remove('active-track')
      }
    })

    const options: TimelineOptions = {
      end:
        highlightMode.id !== -1
          ? getTimelineDuration(highlightMode.items.map((t) => t))
          : end,
      max:
        highlightMode.id !== -1
          ? getTimelineDuration(highlightMode.items.map((t) => t))
          : end,
    }

    const items = highlightMode.id !== -1 ? highlightMode.items : initialItems

    timelineRef.current?.setOptions(options)
    timelineRef.current?.setItems(items)

    if (highlightMode.id !== -1) {
      applyStyle()
    }

    const observer = new MutationObserver(() => {
      applyStyle()
    })

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    })

    return () => observer.disconnect()
  }, [highlightMode])

  useEffect(() => {
    if (selectedGroup === undefined && isStartTime) {
      removeStartMarker(timelineRef)
      timelineContext.setStartMarker!(null)
      setIsStartTime(false)
      handleHairColor('yellow')
    }
  }, [selectedGroup])

  useEffect(() => {
    window.addEventListener('keydown', handleKey)
    window.addEventListener('keyup', handleKey)

    return () => {
      window.removeEventListener('keydown', handleKey)
      window.removeEventListener('keyup', handleKey)
    }
  }, [])

  useEffect(() => {
    timelineRef.current?.setWindow(start, zoomEnd)
  }, [timelineRef.current, highlightMode.id])

  useEffect(() => {
    if (isStartTime) {
      window.addEventListener('keydown', handleEscKey)
    }

    return () => {
      window.removeEventListener('keydown', handleEscKey)
    }
  }, [isStartTime])

  const handleTimeChange = (time: number) => {
    const currentDriveTrial = getCurrentDriveTrial(time)
    if (!currentDriveTrial) return
    const currentTimeDate =
      currentDriveTrial.startDate - currentDriveTrial.previousDuration + time

    moveMainMarker(
      currentTimeDate,
      time,
      controlledTime,
      timelineRef.current as VisTimeline,
      sliding,
      SLIDE_OFFSET_PERCENT,
      mediaSyncContext.isPlaying,
      shouldMoveMarker,
      setShouldMoveMarker
    )
  }

  useActiveTrial(handleTimeChange)

  useEffect(() => {
    setIsLoading(false)

    if (
      startMarker &&
      !endMarker &&
      selectedGroup !== undefined &&
      selectedGroup !== null
    ) {
      if (isStartTime) {
        removeStartMarker(timelineRef)
      }
      timelineRef.current?.addCustomTime(new Date(startMarker), 'startMarker')
      setIsStartTime(true)
      handleHairColor('green')
    }
    if (endMarker!) {
      addNotation()
      removeStartMarker(timelineRef)
      setIsStartTime(false)
      handleHairColor('yellow')
    }
  }, [timelineContext, controlledTime])

  const updateTime = (date: Date) => {
    if (mediaSyncContext.timingObj !== undefined) {
      mediaSyncContext.isSeeking = true

      mediaSyncContext.timingObj.update({
        position: toSeconds(dayjs(date).valueOf()),
      })
    }
  }

  if (isLoading) {
    return <Loader text={enUS.LOADING_TIMELINE} center={true} />
  }

  if (!driveTrials || (!isKpi && !driveTrialData)) {
    return <NoData languageCode='enUS' />
  }

  return (
    <div
      className={`timeline-track-menu${
        dataReceived ? '-enabled' : '-disabled'
      }`}
      style={{ width: '100%', height: '100%' }}
      onContextMenu={(e) => e.preventDefault()}
    >
      <TimelineControlled
        ref={timelineRef}
        animate
        initialGroups={groups}
        initialItems={
          highlightMode.id !== -1 ? highlightMode.items : timelineContext.items
        }
        options={{
          ...TIMELINE_OPTIONS_DEFAULT,
          min: start,
          max: end,
          start,
          end,
          zoomMin: 2300,
          zoomMax: zoomEnd,
          groupTemplate: (group, element) =>
            CustomGroupTemplate({
              group,
              element,
              handleTimelineMenu,
              viewportId,
              timelineContext,
            }),
        }}
        controlledTime={controlledTime}
        slideOffsetPercent={SLIDE_OFFSET_PERCENT}
        timechangeHandler={(props) => {
          if (!mediaSyncContext.timingObj) {
            return
          }

          updateTime(props.time)
        }}
        clickHandler={(props: TimelineEventData) => {
          match(props.what)
            .with('item', () => setJumpItemId(props.item))
            .with('group-label', () => {})
            .otherwise(() => {
              if (!props.event.ctrlKey) {
                updateTime(props.time)
              }
            })
        }}
        contextmenuHandler={(data: TimelineEventData) => {
          if (data.item && data.item.toString().includes('tag')) {
            setAnchorTagPopup(data.event.srcElement)
            setActiveTag(+data.item.split('-')[1])
          }
        }}
        onTimelineInit={(timeline) => setTimeline(timeline)}
      />

      <TimelineTrackMenu
        anchorEl={trackMenuEl}
        handleClose={handleCloseTrackMenu}
        groupId={ctxMenuGroup!}
      />
      {activeTag !== undefined && <TagPopup onEdit={editTag} />}
      {isKpi ? (
        <KpiTimelineDataFetcher
          synchronizer={synchronizer}
          viewportId={viewportId}
          onDataReceived={handleDataReceived}
        />
      ) : (
        <VideoTimelineDataFetcher
          synchronizer={synchronizer}
          viewportId={viewportId}
          signsData={signsData}
          onDataReceived={handleDataReceived}
        />
      )}
      <TimelineSettings key={modeKey} />
      <ShowHidden />
      <div
        id='tooltip'
        style={{
          position: 'absolute',
          visibility: 'hidden',
          padding: '10px',
          backgroundColor: 'white',
          border: '1px solid black',
          borderRadius: '5px',
          pointerEvents: 'none',
        }}
      />
    </div>
  )
}

export function VideoTimeline(props: VideoTimelineProps) {
  return (
    <TagPopupContextProvider>
      <VideoTimelineContainer {...props} />
    </TagPopupContextProvider>
  )
}
