import { PlayControlsRef } from '../containers/PlayControls'
import {
  createContext,
  PropsWithChildren,
  useRef,
  RefObject,
  MutableRefObject,
} from 'react'

export interface HandleMouseDownChangeProps {
  setIsMouseDown: (value: React.SetStateAction<boolean>) => void
  isMouseDown: boolean
}

export interface TransportContextType {
  activeTrackRef: RefObject<HTMLDivElement>
  durationRef: RefObject<HTMLSpanElement>
  playRef: RefObject<PlayControlsRef>
  frameRef: RefObject<HTMLInputElement>
  sliderRef: MutableRefObject<HTMLInputElement | null>
  handleMouseDownRef: RefObject<HandleMouseDownChangeProps>
  playbackRate: MutableRefObject<number>
}

export const TransportContext = createContext<TransportContextType>(
  {} as TransportContextType
)

export function TransportContextProvider({ children }: PropsWithChildren) {
  // refs
  const playbackRate = useRef<number>(1)
  const activeTrackRef = useRef<HTMLDivElement>(null)
  const durationRef = useRef<HTMLSpanElement>(null)
  const playRef = useRef<PlayControlsRef>(null)
  const frameRef = useRef<HTMLInputElement>(null)
  const sliderRef = useRef<HTMLInputElement>(null)
  const handleMouseDownRef = useRef<HandleMouseDownChangeProps>({
    isMouseDown: false,
    setIsMouseDown: () => {},
  })

  const value = {
    activeTrackRef,
    durationRef,
    playRef,
    frameRef,
    sliderRef,
    handleMouseDownRef,
    playbackRate,
  }

  return (
    <TransportContext.Provider value={value}>
      {children}
    </TransportContext.Provider>
  )
}
