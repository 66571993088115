import { ViewportMenuObject } from '@api/clientViewportMenu'
import { usePlyJsonQuery } from '@api/plyJson'
import { GridOption } from '@common/components/GridSelectors/GridSelectors'
import MediaWrapper from '@common/components/MediaWrapper/MediaWrapper'
import { NoData } from '@common/components/NoData/NoData'
import { Viewport } from '@common/components/Viewport/Viewport'
import { useDetectZoomLevel } from '@common/hooks/useDetectZoomLevel'
import { ISynchronizer } from '@common/services/synchronizer/synchronizer'
import { isTimelineDisabled } from '@common/utils/viewports'
import { MapView } from '@modules/mapViewport/containers/Map'
import { MapContextProvider } from '@modules/mapViewport/context/MapContext'
import { VideoTimeline } from '@modules/timelineViewport'
import { ObjectColor, VideoWithCanvas } from '@modules/videoViewport'
import { ILayerPanelItem } from '@pages/Details/types/layersPanel'
import { ViewportContent } from '@pages/Details/types/viewportContent'
import { View3D } from '@modules/3dViewport/View3D'
import { TopViewMap } from '@modules/topViewMapViewport'
import { useDriveTrialContext } from 'pages/Details/providers/DriveTrialDataProvider'
import { MouseEvent, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectViewportLayout } from 'store/details/viewport/selectors'
import { viewportContentChange } from 'store/details/viewport/viewportSlice'
import { viewportChange } from 'store/details/viewportData/viewportDataSlice'
import { match } from 'ts-pattern'
import { ViewportToolbar } from '../ViewportToolbar'

interface DetailViewportProps {
  id: number
  colors: ObjectColor[]
  layerPanelData: ILayerPanelItem[]
  menuOptions: ViewportMenuObject[]
  grid: GridOption
  synchronizer?: ISynchronizer
  fullscreenId: number | null
  setFullscreenId: React.Dispatch<React.SetStateAction<number | null>>
}

export function DetailViewport({
  id,
  colors,
  layerPanelData,
  menuOptions,
  grid,
  synchronizer,
  fullscreenId,
  setFullscreenId,
}: DetailViewportProps) {
  const { modeKey } = useDriveTrialContext()
  const [focusMap, setFocusMap] = useState(true)
  const viewportData = useSelector(selectViewportLayout(id))
  const dispatch = useDispatch()
  const { data: signsData } = usePlyJsonQuery()

  const isFullscreen = fullscreenId === id

  window.addEventListener('beforeunload', function () {
    localStorage.removeItem('jiraURL')
  })

  const getTitle = useCallback(
    () => menuOptions.find((option) => option.id === viewportData)?.name ?? '',
    [viewportData, menuOptions]
  )

  useDetectZoomLevel(0.75, 1.5)

  const changeViewport = (viewportId: number, content: ViewportContent) => {
    if (isTimelineDisabled() && content === ViewportContent.TIMELINE) return

    dispatch(
      viewportContentChange({
        id: viewportId,
        content,
      })
    )

    dispatch(
      viewportChange({
        id: viewportId,
        content,
      })
    )
  }

  const pickContent = (content: ViewportContent) => {
    changeViewport(id, content)
  }

  const goFullscreen = (e: MouseEvent<HTMLButtonElement>) => {
    setFullscreenId(id)
    e.currentTarget.blur()
  }

  const exitFullscreen = (e: MouseEvent<HTMLButtonElement>) => {
    setFullscreenId(null)
    e.currentTarget.blur()
  }

  useEffect(() => {
    !viewportData && synchronizer?.updateStatus(id, false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewportData])

  const renderVideo = useCallback(
    (videoSide: ViewportContent) => {
      return (
        <VideoWithCanvas
          key={modeKey}
          playerId={id.toString()}
          colors={colors}
          isFullscreen={isFullscreen}
          viewportId={id}
          title={getTitle()}
          synchronizer={synchronizer}
          videoSide={videoSide}
        />
      )
    },
    [colors, getTitle, id, isFullscreen, modeKey, synchronizer]
  )

  return (
    <Viewport
      isFullscreen={isFullscreen}
      isViewportStyled
      viewportContentTitle
      title={getTitle()}
    >
      <MapContextProvider>
        {match(viewportData)
          .with(ViewportContent.FRONT_CAMERA, () =>
            renderVideo(ViewportContent.FRONT_CAMERA)
          )
          .with(ViewportContent.MAP, () => (
            <MapView
              isFullscreen={isFullscreen}
              focusMap={focusMap}
              synchronizer={synchronizer}
              viewportId={id}
            />
          ))
          .with(ViewportContent.TIMELINE, () => (
            <VideoTimeline
              synchronizer={synchronizer}
              viewportId={id}
              signsData={signsData}
            />
          ))
          .with(ViewportContent['3D_VIEW'], () => (
            <MediaWrapper>
              <View3D
                viewportId={id}
                isFullscreen={isFullscreen}
                synchronizer={synchronizer}
                grid={grid}
                signsData={signsData}
              />
            </MediaWrapper>
          ))
          .with(ViewportContent.LEFT_CAMERA, () =>
            renderVideo(ViewportContent.LEFT_CAMERA)
          )
          .with(ViewportContent.REAR_CAMERA, () =>
            renderVideo(ViewportContent.REAR_CAMERA)
          )
          .with(ViewportContent.RIGHT_CAMERA, () =>
            renderVideo(ViewportContent.RIGHT_CAMERA)
          )
          .with(ViewportContent.TOP_VIEW_MAP, () => (
            <TopViewMap viewportId={id} synchronizer={synchronizer} />
          ))
          .otherwise(() => (
            <NoData languageCode='enUS' />
          ))}

        <ViewportToolbar
          direction='column'
          focusMap={focusMap}
          setFocusMap={setFocusMap}
          setContent={pickContent}
          currentContent={viewportData}
          layerPanelData={layerPanelData}
          goFullscreen={goFullscreen}
          exitFullScreen={exitFullscreen}
          isFullscreen={isFullscreen}
          viewportId={id}
          colors={colors}
          menuOptions={menuOptions}
        />
      </MapContextProvider>
    </Viewport>
  )
}
