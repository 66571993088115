import { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { IconButton, Stack } from '@mui/material'
import dayjs, { Dayjs } from 'dayjs'
import DatePicker from 'react-datepicker'
import { DatePickerCustomInput } from '../DatePickerCustomInput/DatePickerCustomInput'
import { DateRangeData } from '../types'
import './styles.scss'

interface IProps {
  placeholder?: string
  dateFormat?: string
  onChange?: (val: DateRangeData<Dayjs>) => void
  dateRangeValues: DateRangeData<Dayjs>
}

export const ReactDateRange = ({
  placeholder,
  dateFormat,
  dateRangeValues,
  onChange,
}: IProps) => {
  const [startDate, setStartDate] = useState<Date | null>(
    dayjs.isDayjs(dateRangeValues[0]) ? dateRangeValues[0].toDate() : null
  )
  const [endDate, setEndDate] = useState<Date | null>(
    dayjs.isDayjs(dateRangeValues[1]) ? dateRangeValues[1].toDate() : null
  )

  useEffect(() => {
    if (onChange) {
      onChange([dayjs(startDate), dayjs(endDate)])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate])

  useEffect(() => {
    if (dateRangeValues[0] === null && dateRangeValues[1] === null) {
      setStartDate(null)
      setEndDate(null)
    }
  }, [dateRangeValues])

  const handleClear = () => {
    setStartDate(null)
    setEndDate(null)
  }

  return (
    <Stack direction='column' spacing={1} position='relative'>
      <DatePicker
        selectsStart
        autoComplete='off'
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        placeholderText={placeholder}
        dateFormat={dateFormat}
        customInput={<DatePickerCustomInput />}
        startDate={startDate || undefined}
        endDate={endDate || undefined}
        maxDate={endDate || undefined}
      />
      <DatePicker
        selectsEnd
        autoComplete='off'
        selected={endDate}
        onChange={(date) => setEndDate(date)}
        placeholderText={placeholder}
        dateFormat={dateFormat}
        customInput={<DatePickerCustomInput />}
        startDate={startDate || undefined}
        endDate={endDate || undefined}
        minDate={startDate || undefined}
      />
      {(startDate !== null || endDate !== null) && (
        <IconButton
          sx={{ position: 'absolute', top: -44, right: 0 }}
          onClick={handleClear}
        >
          <CloseIcon fontSize='small' />
        </IconButton>
      )}
    </Stack>
  )
}
