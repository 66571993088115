import {
  useCreateTagMutation,
  useEditTagMutation,
  useRemoveTagMutation,
} from '@api/index'
import { enUS } from '@common/constants/messages'
import { toMilliseconds } from '@common/utils/time'
import { useDriveTrialContext } from '@pages/Details/providers/DriveTrialDataProvider'
import { HighlightMode, timelineTagId, useTimelineContext } from '..'
import { MediaSyncContext } from '@pages/Details/types/providers'
import { useSnackbar } from 'notistack'
import { IdType } from 'otto-vis-timeline/types'
import { useCallback, useContext } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { JiraIssueType, jiraTeamIds, JiraTeam } from '@modules/timelineViewport'
import {
  calculateOriginalTime,
  isNotationValid,
} from '@modules/timelineViewport/utils/timeline'
import { addNotationToGroup, addTagToGroup } from '../utils/tags'
import { useTagPopupContext } from './useTagPopupContext'

export const useTags = (selectedGroup: IdType) => {
  const { pathname } = useLocation()
  const { reportType } = useParams()
  const isKPI = pathname.includes('kpi')
  const timelineCtx = useTimelineContext()

  const { timingObj } = useContext(MediaSyncContext)
  const { getCurrentDriveTrial, highlightMode, setHighlightMode, driveTrials } =
    useDriveTrialContext()
  const { setActiveTag, setAnchorTagPopup } = useTagPopupContext()
  const { removeTagMutation } = useRemoveTagMutation()
  const { editTagMutation } = useEditTagMutation()
  const { createTagMutation } = useCreateTagMutation()
  const { enqueueSnackbar } = useSnackbar()

  const removeTag = async (
    activeTag: number | null,
    highlightMode: HighlightMode
  ) => {
    if (!activeTag) {
      return
    }

    if (!timingObj) {
      return
    }

    try {
      await removeTagMutation(activeTag)

      const allItems = timelineCtx.items.get({ returnType: 'Array' })

      const currentItem = allItems.filter(
        (item) => item.id === `tag-${activeTag}`
      )
      const currentItemGroup = currentItem[0].group
      const currentItemStart = currentItem[0].start
      timelineCtx.items.forEach((item) => {
        if (item.id.toString().includes(`tag-${activeTag}`)) {
          timelineCtx.items.remove(item.id)
          timelineCtx.initialItems.remove(item.id)
          timelineCtx.originalItems.remove(item.id)
        }
      })
      if (highlightMode.id !== -1) {
        highlightMode.items.forEach((item) => {
          if (item.id.toString().includes(`tag-${activeTag}`)) {
            highlightMode.items.remove(item.id)
          }
        })
      }
      const tagsCopy = { ...timelineCtx.tags }

      if (Object.keys(tagsCopy).length > 0) {
        delete tagsCopy[currentItemGroup!][+currentItemStart]
      }
      timelineCtx.setTags(tagsCopy)
    } catch (e) {
      console.error(e)
    }
  }

  const editTag = async (
    activeTag: number | null,
    text: string,
    summary: string,
    issueType?: string,
    team?: string,
    jiraID?: string | undefined
  ) => {
    if (!activeTag) {
      return
    }

    await editTagMutation({
      tagId: activeTag,
      note: text,
      summary: summary,
      issueType: issueType,
      team: jiraTeamIds[team as JiraTeam],
      jiraID: jiraID,
    })

    const tag = timelineCtx.items
      .map((x) => x)
      .find((x) => x.id === `tag-${activeTag}`)

    if (tag) {
      timelineCtx.items.update({
        ...tag,
        title: text,
        summary: summary,
        issueType: issueType as JiraIssueType,
        team: jiraTeamIds[team as JiraTeam],
        jiraID: jiraID,
      })
    }
  }

  const addTag = useCallback(async () => {
    if (selectedGroup === undefined || selectedGroup === null) {
      enqueueSnackbar({
        message: enUS.ERR_NO_ROW_SELECTED,
        variant: 'info',
      })
      return
    }

    const clockTime = timingObj?.pos
    const activeVideo = getCurrentDriveTrial(clockTime)

    if (!activeVideo) {
      return
    }

    const offsetTime = toMilliseconds(activeVideo.previousDuration)

    const currentTime = parseInt(toMilliseconds(clockTime).toFixed())

    const tagsCopy = { ...timelineCtx.tags }

    if (!tagsCopy[selectedGroup]) {
      tagsCopy[selectedGroup] = {}
    }

    if (!tagsCopy[selectedGroup][currentTime]) {
      tagsCopy[selectedGroup][currentTime] = true

      try {
        const group = timelineCtx.groups
          .map((grp) => grp)
          .find((g) => g.id === selectedGroup)!

        const calcTime =
          highlightMode.id === -1
            ? currentTime - offsetTime
            : calculateOriginalTime(currentTime, highlightMode)

        const { data } = await createTagMutation({
          dtid: activeVideo.DTID,
          tag: {
            startTimestamp: Math.round(calcTime),
            description: isKPI
              ? `${reportType}|${group.value.replace('-', '|')}`
              : group.value.replace('-', ''),
          },
        })

        timelineCtx.setTags(tagsCopy)
        addTagToGroup(
          currentTime - offsetTime,
          selectedGroup,
          timelineCtx,
          highlightMode,
          setHighlightMode,
          data,
          activeVideo.DTID,
          activeVideo
        )
      } catch (error) {
        if (error instanceof Error) {
          enqueueSnackbar({
            message: `Error saving the tag on server. ${error.message}`,
            variant: 'error',
          })
        }
      }
    } else {
      enqueueSnackbar({ message: enUS.ERR_ITEM_ON_KEY, variant: 'info' })
    }
  }, [
    selectedGroup,
    timingObj,
    enqueueSnackbar,
    createTagMutation,
    getCurrentDriveTrial,
    timelineCtx,
  ])

  const addNotation = useCallback(async () => {
    if (selectedGroup === undefined || selectedGroup === null) {
      enqueueSnackbar({
        message: enUS.ERR_NO_ROW_SELECTED,
        variant: 'info',
      })
      return
    }

    const clockTime = timingObj?.pos
    const activeVideo = getCurrentDriveTrial(clockTime)

    if (!activeVideo) {
      return
    }

    const offsetTime = toMilliseconds(activeVideo.previousDuration)

    const startTime = timelineCtx.startMarker

    const endTime = timelineCtx.endMarker

    const tagsCopy = { ...timelineCtx.tags }

    if (!tagsCopy[selectedGroup]) {
      tagsCopy[selectedGroup] = {}
    }

    if (!tagsCopy[selectedGroup][startTime!]) {
      tagsCopy[selectedGroup][startTime!] = true

      try {
        const group = timelineCtx.groups
          .map((grp) => grp)
          .find((g) => g.id === selectedGroup)!
        const { data } = await createTagMutation({
          dtid: activeVideo.DTID,
          tag: {
            startTimestamp: startTime! - offsetTime,
            endTimestamp: endTime! - offsetTime,
            description: isKPI
              ? `${reportType}|${group.value.replace('-', '|')}`
              : group.value.replace('-', ''),
          },
        })

        timelineCtx.setTags(tagsCopy)

        addNotationToGroup(
          startTime! - offsetTime,
          endTime! - offsetTime,
          selectedGroup,
          timelineCtx,
          highlightMode,
          data,
          activeVideo.DTID,
          activeVideo,
          editTagMutation
        )

        timelineCtx.setStartMarker!(null)
        timelineCtx.setEndMarker!(null)

        const newTagElement = document.querySelector(
          `.${timelineTagId}-${data.id}`
        ) as HTMLDivElement

        if (newTagElement) {
          setActiveTag(data.id)
          setAnchorTagPopup(newTagElement)
        }
      } catch (error) {
        if (error instanceof Error) {
          enqueueSnackbar({
            message: `Error saving the notation on server. ${error.message}`,
            variant: 'error',
          })
        }
      }
    } else {
      enqueueSnackbar({ message: enUS.ERR_ITEM_ON_KEY, variant: 'info' })
    }
  }, [
    selectedGroup,
    timingObj,
    enqueueSnackbar,
    createTagMutation,
    getCurrentDriveTrial,
    timelineCtx,
  ])

  const markIn = useCallback(async () => {
    if (!timelineCtx.setStartMarker || !timingObj) return

    if (
      timelineCtx.selectedGroup === undefined ||
      timelineCtx.selectedGroup === null
    ) {
      enqueueSnackbar({
        message: enUS.ERR_NO_ROW_SELECTED,
        variant: 'info',
      })
      return
    }

    if (timelineCtx.hidden.includes(timelineCtx.selectedGroup!)) {
      enqueueSnackbar({
        message: enUS.TAGS_HIDDEN,
        variant: 'info',
      })
      return
    }

    timelineCtx.setStartMarker(Math.round(toMilliseconds(timingObj.pos)))
  }, [timingObj, timelineCtx])

  const markOut = useCallback(async () => {
    if (!timelineCtx.setEndMarker || !timingObj) return

    if (
      timelineCtx.selectedGroup === undefined ||
      timelineCtx.selectedGroup === null
    ) {
      enqueueSnackbar({
        message: enUS.ERR_NO_ROW_SELECTED,
        variant: 'info',
      })
      return
    }

    if (!timelineCtx.startMarker) {
      enqueueSnackbar({
        message: enUS.NO_START_TIME,
        variant: 'info',
      })
      return
    }

    if (timelineCtx.hidden.includes(timelineCtx.selectedGroup!)) {
      enqueueSnackbar({
        message: enUS.TAGS_HIDDEN,
        variant: 'info',
      })
      return
    }
    const end = Math.round(toMilliseconds(timingObj.pos))
    if (
      !isNotationValid(timelineCtx.startMarker, end, driveTrials, highlightMode)
    ) {
      enqueueSnackbar({
        message:
          highlightMode.id === -1
            ? enUS.NOTATION_END_TIME_CONSTRAINT
            : enUS.NOTATION_END_TIME_CONSTRAINT_HIGHLIGHT_MODE,
        variant: 'info',
      })
      return
    }
    timelineCtx.setEndMarker(end)
  }, [timingObj, timelineCtx])

  return { addTag, addNotation, removeTag, editTag, markIn, markOut }
}
