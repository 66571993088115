import { getStorageAdminLevelID } from '@common/utils/storage'
import { columnHelper } from '../utils'
import { PUBLISH_ADMIN } from '@api/table/adminLevel'
import { Checkbox } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { useReportContext } from './useReportContext'
import { useAuthContext } from '@modules/auth'

export function useSelectColumn() {
  const { pathname } = useLocation()
  const { adminLevelID } = useAuthContext()
  const isKPI = pathname.includes('kpi')
  const { selectedRowIds, setSelectedRowIds } = useReportContext()

  return columnHelper.group({
    id: 'select',
    cell: ({ row }) => {
      const dtid =
        getStorageAdminLevelID() === PUBLISH_ADMIN && !isKPI
          ? row.original['100001']
          : row.original['100000']

      return (
        <div
          data-testid={`checkbox-${dtid}`}
          style={{ padding: 0, paddingLeft: `${row.depth * 1.5}rem` }}
          className='checkbox-container'
        >
          {dtid === 'Total' ||
            (row.depth < 2 && row.id !== 'Total' && (
              <Checkbox
                checked={row.getIsSelected()}
                disabled={!row.getCanSelect()}
                onChange={(e) => {
                  if (row.getIsSelected()) {
                    // eslint-disable-next-line no-extra-semi
                    ;[...selectedRowIds].forEach((x) => {
                      if (x.DTID === dtid) {
                        selectedRowIds.delete(x)
                      }
                    })
                  } else {
                    selectedRowIds.add(
                      adminLevelID === PUBLISH_ADMIN && !isKPI
                        ? {
                            published: row.original['100000'],
                            DTID: row.original['100001'],
                            parentDTID: row.original['100002'],
                            version: row.original['100004'],
                          }
                        : {
                            DTID: row.original['100000'],
                            parentDTID: row.original['100001'],
                            version: row.original['100003'],
                          }
                    )
                  }

                  setSelectedRowIds(new Set([...selectedRowIds]))

                  row.getToggleSelectedHandler()(e)
                }}
                size='small'
                sx={{
                  cursor: 'pointer',
                  padding: 0,
                  visibility: row.getIsSelected() ? 'visible' : '',
                  '&.Mui-checked': {
                    color: '#102D92',
                  },
                }}
                className='select-checkbox'
              />
            ))}
        </div>
      )
    },
    enableSorting: false,
    enablePinning: false,
  })
}
