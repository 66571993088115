import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import ChevronRightTwoToneIcon from '@mui/icons-material/ChevronRightTwoTone'
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone'
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox'
import CheckboxTree, { OnCheckNode, OnExpandNode } from 'react-checkbox-tree'
import './style.scss'
import { ReportType, useReportContext } from '@modules/reportTable'
import { useCallback, useMemo } from 'react'

const treeIcons = {
  check: (
    <CheckBoxIcon
      sx={{ color: '#e5e5e5' }}
      className='rct-icon rct-icon-check'
    />
  ),
  uncheck: (
    <CheckBoxOutlineBlankIcon
      sx={{ color: '#e5e5e5' }}
      className='rct-icon rct-icon-uncheck'
    />
  ),
  halfCheck: (
    <IndeterminateCheckBoxIcon
      sx={{ color: '#e5e5e5' }}
      className='rct-icon rct-icon-half-check'
    />
  ),
  expandClose: (
    <ChevronRightTwoToneIcon
      sx={{ color: '#e5e5e5', opacity: 'unset' }}
      className='rct-icon rct-icon-expand-close'
    />
  ),
  expandOpen: (
    <ExpandMoreTwoToneIcon
      sx={{ color: '#e5e5e5' }}
      className='rct-icon rct-icon-expand-open'
    />
  ),
  parentClose: (
    <ExpandMoreTwoToneIcon
      style={{ display: 'none' }}
      className='rct-icon rct-icon-parent-close'
    />
  ),
  parentOpen: (
    <ExpandMoreTwoToneIcon
      style={{ display: 'none' }}
      className='rct-icon rct-icon-parent-open'
    />
  ),
  leaf: (
    <ExpandMoreTwoToneIcon
      style={{ display: 'none' }}
      className='rct-icon rct-icon-leaf-close'
    />
  ),
}

export interface CheckboxNodeData extends OnCheckNode, OnExpandNode {}

interface CheckboxTreeBaseProps {
  expandedNodes: string[]
  checkboxes: CheckboxNodeData[] | undefined
  onCheck: (node: OnCheckNode) => void
  onExpand: (expandedNodes: string[]) => void
  reportType: ReportType
}

export function CheckboxTreeBase({
  expandedNodes,
  checkboxes,
  onCheck,
  onExpand,
  reportType,
}: CheckboxTreeBaseProps) {
  const { preserveState } = useReportContext()

  const getCheckedNodeLabels = useCallback(
    (data: CheckboxNodeData[]) => {
      return data.reduce<string[]>((acc, node) => {
        if (preserveState[reportType].columnVisibility[node.value] !== false) {
          acc.push(node.value)
        }

        if (node.children && node.children.length > 0) {
          acc.push(...getCheckedNodeLabels(node.children as CheckboxNodeData[]))
        }

        return acc
      }, [])
    },
    [preserveState, reportType]
  )

  const checkedNodeLabels = useMemo(
    () => getCheckedNodeLabels(checkboxes!),
    [checkboxes, getCheckedNodeLabels]
  )

  return checkboxes ? (
    <CheckboxTree
      nodes={checkboxes}
      checked={checkedNodeLabels}
      expanded={expandedNodes}
      onCheck={(_, node) => onCheck(node)}
      onExpand={(expanded) => onExpand(expanded)}
      icons={treeIcons}
    />
  ) : null
}
