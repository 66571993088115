import { useContext } from 'react'
import {
  TagPopupContext,
  TagPopupContextType,
} from '../context/TagPopupContextProvider'

export function useTagPopupContext(): TagPopupContextType {
  const context = useContext(TagPopupContext)

  if (!context) {
    throw new Error(
      'useTagPopupContext must be used within a TagPopupContextProvider'
    )
  }

  return context
}
