import { useMapQuery } from '@api/map'
import { useActiveTrial } from '@common/hooks/useActiveTrial'
import { useDriveTrialContext } from '@pages/Details/providers/DriveTrialDataProvider'
import { MediaSyncContext } from '@pages/Details/types/providers'
import { MapMouseEvent } from 'mapbox-gl'
import { useCallback, useContext } from 'react'
import { filterIndexes, getNearestPoint } from '../utils/map'
import { useMapContext } from './useMapContext'

export function useHandleMapClick() {
  const mediaSyncContext = useContext(MediaSyncContext)

  const { highlightMode } = useDriveTrialContext()
  const { activeTrial } = useActiveTrial()
  const { gpsHighMap, gpsCoordinates, gpsHighMapData } = useMapContext()
  const { data } = useMapQuery()

  const getPosition = useCallback(
    (indexes: number[]) => {
      if (indexes.length === 1) return indexes[0]
      const gpsData = indexes.map((x) => ({ data: data?.map[x], index: x }))
      const selectedDT = gpsData.find((x) => x.data?.dtid === activeTrial.DTID)
      return !selectedDT ? indexes[0] : selectedDT?.index
    },
    [activeTrial.DTID, data?.map]
  )

  const getHighPosition = useCallback(
    (indexes: number[]) => {
      if (indexes.length === 1) return indexes[0]
      const gpsData = indexes.map((x) => ({
        data: gpsHighMapData[x],
        index: x,
      }))
      const selectedDT = gpsData.find((x) => x.data?.dtid === activeTrial.DTID)
      return !selectedDT ? indexes[0] : selectedDT?.index
    },
    [activeTrial.DTID, gpsHighMapData]
  )

  return useCallback(
    (e: MapMouseEvent) => {
      const [nearestLongitude, nearestLatitude] = getNearestPoint(
        e,
        gpsCoordinates,
        Object.values(gpsHighMap)
      )
      if (nearestLatitude === null && nearestLongitude === null) return

      mediaSyncContext.isSeeking = true

      let gpsTime = 0
      if (highlightMode.id === -1) {
        const indexes = filterIndexes(
          gpsCoordinates,
          (point) =>
            point[0] === nearestLongitude && point[1] === nearestLatitude
        )
        if (indexes.length > 0) {
          gpsTime = getPosition(indexes)
        }
      } else {
        const indexes = filterIndexes(
          Object.entries(gpsHighMap),
          (x) =>
            x[1] && x[1][0] === nearestLongitude && x[1][1] === nearestLatitude
        )
        if (indexes.length > 0) {
          gpsTime = getHighPosition(indexes)
        }
      }

      mediaSyncContext.timingObj?.update({
        position: +gpsTime,
        velocity: 0,
      })
    },
    [
      getHighPosition,
      getPosition,
      gpsCoordinates,
      gpsHighMap,
      highlightMode.id,
      mediaSyncContext,
    ]
  )
}
