import { PROJECT_IDS } from '@common/constants/projects'
import { getProjectID } from '@common/utils/storage'
import { getCanvasLayers, ObjectColor } from '@modules/videoViewport'
import { DEFAULT_LAYERS_DATA } from '../constants/layers'
import { ILayerPanelItem } from '../types/layersPanel'
import { ReportType } from '@modules/reportTable'

export const buildLayoutData = (colors: ObjectColor[]): ILayerPanelItem[] => {
  const freshData = structuredClone(DEFAULT_LAYERS_DATA) as ILayerPanelItem[]

  return freshData.map((x, i) => {
    const panelName = i === 0 ? 'Ottometric' : 'Mobileye'
    return {
      ...x,
      categories: x.categories.map((y) => ({
        ...y,
        options: y.options.map((z) => {
          const optionColor = colors.find(
            (c) => c.source === panelName && c.name === z.name
          )
          return {
            ...z,
            color: optionColor?.layerColor ?? '',
          }
        }),
      })),
    }
  })
}

export const isDisabledOption = (
  name: string,
  color: string,
  project: ReportType
) => {
  const layers = getCanvasLayers(project)
  const projectID = +(getProjectID() || 0)

  if (
    projectID === PROJECT_IDS.VOLVO &&
    process.env.REACT_APP_ENV !== 'development' &&
    process.env.REACT_APP_ENV !== 'local' &&
    !(
      name.replaceAll(' ', '') === 'VEHICLELAYERS' ||
      name.replaceAll(' ', '') === 'OTTOLAYERS' ||
      name.replaceAll(' ', '') === 'Sign' ||
      name.replaceAll(' ', '') === 'EgoClass&Color' ||
      name.replaceAll(' ', '') === 'EgoLane'
    )
  ) {
    return true
  }

  if (layers.includes(name.replaceAll(' ', '')) && color) {
    return false
  }

  return true
}
