export type JiraTeam = 'CV' | 'AN' | 'FE' | 'DevOps' | 'QA' | 'ANO'

export const jiraTeamIds = {
  CV: '10020',
  AN: '10021',
  FE: '10022',
  DevOps: '10027',
  QA: '10023',
  ANO: '10064',
}

export type JiraIssueType = 'Bug' | 'Task'

export type JiraStatus =
  | 'To Do'
  | 'Reopened'
  | 'In Progress'
  | 'Done'
  | 'Closed'
  | 'On Hold'
  | 'QA'
  | 'Ready 4 QA'
  | 'Reopened'
  | 'Review'

export interface JiraIssueStatusMap {
  [issueId: string]: string
}
