import { useCallback } from 'react'
import { useMapContext } from './useMapContext'

const initialZoom = 16

export function useHandleRecenter() {
  const { setFollowMode, setShowFollowButton, markerRef, mapRef } =
    useMapContext()

  return useCallback(() => {
    if (markerRef.current && mapRef.current) {
      const position = markerRef.current.getPosition()

      if (!position) {
        return
      }

      setFollowMode(false)
      setShowFollowButton(false)
      mapRef.current.stop()

      const handleMoveEnd = () => {
        setFollowMode(true)
        mapRef.current?.off('moveend', handleMoveEnd)
      }

      mapRef.current?.on('moveend', handleMoveEnd)

      mapRef.current.flyTo({
        center: [position[0], position[1]],
        zoom: initialZoom,
        bearing: 0,
        essential: true,
      })
    }
  }, [mapRef, markerRef, setFollowMode, setShowFollowButton])
}
