import { useDriveTrialContext } from '@pages/Details/providers/DriveTrialDataProvider'
import { MediaSyncContext } from '@pages/Details/types/providers'
import { useCallback, useContext } from 'react'
import { GpsPoint } from '../types/map'
import { useMapContext } from './useMapContext'

export function useGetCurrentCoordinate(): () => GpsPoint {
  const mediaSyncContext = useContext(MediaSyncContext)
  const { highlightMode } = useDriveTrialContext()
  const { mapRef, gpsCoordinates, gpsHighMap, markerRef } = useMapContext()

  return useCallback(() => {
    if (!mediaSyncContext) {
      return [0, 0, 0, 0]
    }

    const t = Math.floor(mediaSyncContext.timingObj!.pos as number)

    if (highlightMode.id !== -1) {
      const cords = gpsHighMap[Math.floor(t)]
      if (cords && markerRef.current && mapRef.current) {
        return [cords[0], cords[1], cords[2], cords[3]]
      }
      return [0, 0, 0, 0]
    }

    const lastElement =
      gpsCoordinates.length > 0
        ? gpsCoordinates[gpsCoordinates.length - 1]
        : [0, 0, 0, 0]

    const currentGPSCoordinate = gpsCoordinates[t]
    const curLongitude = currentGPSCoordinate
      ? currentGPSCoordinate[0]
      : lastElement[0]
    const curLatitude = currentGPSCoordinate
      ? currentGPSCoordinate[1]
      : lastElement[1]
    const curSpeed = currentGPSCoordinate
      ? currentGPSCoordinate[2]
      : lastElement[2]
    const curYaw = currentGPSCoordinate
      ? currentGPSCoordinate[3]
      : lastElement[3]

    return [curLongitude, curLatitude, curSpeed, curYaw]
  }, [
    gpsCoordinates,
    gpsHighMap,
    highlightMode.id,
    mapRef,
    markerRef,
    mediaSyncContext,
  ])
}
