import { MapMouseEvent } from 'mapbox-gl'
import { useCallback } from 'react'
import { useMapContext } from './useMapContext'

export function useHandleMouseOver() {
  const { mapRef, markerRef } = useMapContext()

  return useCallback(
    (e: MapMouseEvent) => {
      const threshold = 10
      const markerPosition = markerRef?.current?.getPosition()
      const map = mapRef?.current?.getMap()

      if (!markerPosition || !map) return

      const markerPixel = map.project([markerPosition[0], markerPosition[1]])
      const mousePixel = [e.point.x, e.point.y]

      const distance = Math.sqrt(
        Math.pow(markerPixel.x - mousePixel[0], 2) +
          Math.pow(markerPixel.y - mousePixel[1], 2)
      )

      return distance < threshold
    },
    [mapRef, markerRef]
  )
}
