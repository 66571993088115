interface ColorSchema {
  [key: string]: string
}

const OttometricLogoColorSchema: ColorSchema = {
  road: '#002e97',
  sky: '#2775df',
  middle_line: '#FFFFFF',
  left_line: '#FFFFFF',
  right_line: '#FFFFFF',
  top_line: '#FFFFFF',
  bottom_line: '#FFFFFF',
  frame: '#FFFFFF',
  o1: '#FFFFFF',
  t1: '#FFFFFF',
  t2: '#FFFFFF',
  o2: '#FFFFFF',
  m: '#FFFFFF',
  e: '#FFFFFF',
  t3: '#FFFFFF',
  r: '#FFFFFF',
  i: '#FFFFFF',
  c: '#FFFFFF',
}

export const Logo = () => {
  return (
    <svg version='1.1' id='Layer_1' viewBox='80 300 1700 400'>
      <ellipse
        style={{ fill: OttometricLogoColorSchema.road }}
        cx='410.36'
        cy='499.606'
        rx='253.5'
        ry='122'
      />

      <g transform='matrix(1, 0, 0, 1, 0.739764, 35.755981)'>
        <path
          style={{ fill: OttometricLogoColorSchema.o1 }}
          d='M827.64,494.44c0,26.28-2.14,35.84-10.68,46.19c-7.21,8.76-18.69,14.07-30.96,14.07 c-12.55,0-24.02-5.31-31.24-14.07c-8.54-10.35-10.67-20.17-10.67-46.19v-61.86c0-26.02,2.13-35.84,10.67-46.2 c7.21-8.75,18.69-14.07,31.24-14.07c12.28,0,24.02,5.31,30.96,14.07c8.54,10.36,10.68,19.91,10.68,46.2V494.44z M770.78,491.52 c0,16.73,0.53,24.69,1.86,28.41c2.14,6.37,6.95,10.09,13.35,10.09c6.67,0,11.74-4.25,13.61-11.68c0.8-3.45,1.34-11.95,1.34-26.81 V435.5c0-17.26-0.53-24.69-1.87-28.41c-2.13-6.37-6.94-10.09-13.08-10.09c-6.94,0-12.01,4.25-13.88,11.68 c-0.8,3.45-1.33,12.47-1.33,26.81V491.52z'
        />

        <path
          style={{ fill: OttometricLogoColorSchema.t1 }}
          d='M901.01,552.05h-26.69V399.66h-28.29v-24.69h83.82v24.69h-28.83V552.05z'
        />
        <path
          style={{ fill: OttometricLogoColorSchema.t2 }}
          d='M1000.02,552.05h-26.69V399.66h-28.29v-24.69h83.82v24.69h-28.83V552.05z'
        />

        <path
          style={{ fill: OttometricLogoColorSchema.o2 }}
          d='M1130.8,494.44c0,26.28-2.14,35.84-10.68,46.19c-7.21,8.76-18.69,14.07-30.96,14.07 c-12.55,0-24.02-5.31-31.24-14.07c-8.54-10.35-10.67-20.17-10.67-46.19v-61.86c0-26.02,2.13-35.84,10.67-46.2 c7.21-8.75,18.69-14.07,31.24-14.07c12.28,0,24.02,5.31,30.96,14.07c8.54,10.36,10.68,19.91,10.68,46.2V494.44z M1073.94,491.52 c0,16.73,0.53,24.69,1.86,28.41c2.14,6.37,6.95,10.09,13.35,10.09c6.67,0,11.74-4.25,13.61-11.68c0.8-3.45,1.34-11.95,1.34-26.81 V435.5c0-17.26-0.53-24.69-1.87-28.41c-2.13-6.37-6.94-10.09-13.08-10.09c-6.94,0-12.02,4.25-13.89,11.68 c-0.8,3.45-1.33,12.47-1.33,26.81V491.52z'
        />

        <path
          style={{ fill: OttometricLogoColorSchema.m }}
          d='M1245.82,552.05V429.66l-25.63,122.39h-12.01l-26.96-122.39v122.39h-24.82V374.97h35.76l22.16,107.78 l20.28-107.78h36.04v177.08H1245.82z'
        />

        <path
          style={{ fill: OttometricLogoColorSchema.e }}
          d='M1369.39,399.66h-45.92v48.32h33.11v24.69h-33.11v54.69h45.92v24.69h-72.6V374.97h72.6V399.66z'
        />
        <path
          style={{ fill: OttometricLogoColorSchema.t3 }}
          d='M1442.77,552.05h-26.69V399.66h-28.29v-24.69h83.82v24.69h-28.83V552.05z'
        />
        <path
          style={{ fill: OttometricLogoColorSchema.r }}
          d='M1518.55,552.05h-26.69V374.97h37.37c30.97,0,44.58,15.93,44.58,51.24c0,22.83-4.8,35.04-17.89,44.6 l20.56,81.23h-28.3l-16.82-74.07h-12.82V552.05z M1527.09,453.29c9.08,0,12.55-1.33,15.49-5.58c2.66-4.24,4.53-12.21,4.53-21.24 c0-9.56-1.87-18.05-5.34-22.04c-2.67-3.71-6.14-4.78-14.69-4.78h-8.54v53.63H1527.09z'
        />

        <path
          style={{ fill: OttometricLogoColorSchema.i }}
          d='M1626.1,552.05h-26.69V374.97h26.69V552.05z'
        />
        <path
          style={{ fill: OttometricLogoColorSchema.c }}
          d='M1708.04,433.64c0-10.36-0.27-18.32-0.53-22.56c-1.07-8.5-6.67-14.07-14.41-14.07 c-6.94,0-11.75,4.25-13.62,11.68c-0.8,3.45-1.33,11.94-1.33,26.81v56.28c0,16.99,0.53,24.42,1.86,28.14 c2.14,6.37,6.95,10.09,13.08,10.09c7.74,0,13.34-5.85,14.15-14.87c0.53-4.78,0.8-17.79,0.8-25.75H1735v10.09 c0,18.32-1.07,25.22-6.14,34.51c-6.68,13.01-19.76,20.71-35.5,20.71c-12.55,0-24.02-5.05-31.24-14.07 c-8.81-10.61-10.67-19.38-10.67-46.99v-60c0-27.88,1.86-36.64,10.67-47.26c7.21-9.02,18.69-14.07,31.24-14.07 c15.48,0,28.83,7.17,35.5,19.38c4.8,8.76,6.14,15.13,6.14,32.39v9.56H1708.04z'
        />
      </g>
      <path
        style={{ fill: OttometricLogoColorSchema.sky }}
        d='M 159.14 479.696 C 158.14 482.516 157.33 485.456 156.75 488.436 C 156.03 492.146 155.66 495.796 155.66 499.286 C 155.66 525.506 174.29 546.416 189.91 559.346 C 190.07 559.476 190.26 559.606 190.42 559.746 L 353.21 380.546 C 353.21 380.546 353.21 380.546 353.2 380.546 C 344.7 381.506 336.35 382.696 328.13 384.076 C 326.69 384.316 325.25 384.576 323.82 384.836 C 321.8 385.196 319.81 385.566 317.81 385.956 C 236.55 401.646 174.2 437.436 159.14 479.696'
      />

      <path
        style={{ fill: OttometricLogoColorSchema.sky }}
        d='M 661.72 479.696 C 662.72 482.516 663.53 485.456 664.11 488.436 C 664.83 492.146 665.2 495.796 665.2 499.286 C 665.2 525.506 646.57 546.416 630.95 559.346 C 630.79 559.476 630.6 559.606 630.44 559.746 L 467.66 380.536 C 467.66 380.536 467.66 380.536 467.67 380.536 C 476.17 381.496 484.52 382.686 492.74 384.066 C 494.18 384.306 495.62 384.566 497.05 384.826 C 499.07 385.186 501.06 385.556 503.06 385.946 C 584.31 401.646 646.66 437.436 661.72 479.696'
      />

      <polygon
        style={{ fill: OttometricLogoColorSchema.middle_line }}
        points='439.44 524.406 426.93 435.226 393.93 435.226 381.4 524.606'
      />
      <path
        style={{ fill: OttometricLogoColorSchema.middle_line }}
        d='M 363.17 365.536 C 364.51 365.416 365.83 365.276 367.17 365.166 C 365.72 365.276 364.41 365.406 363.17 365.536'
      />
      <path
        style={{ fill: OttometricLogoColorSchema.middle_line }}
        d='M 453.7 365.156 C 455.05 365.266 456.36 365.416 457.7 365.526 C 456.45 365.406 455.14 365.276 453.7 365.156'
      />
      <path
        style={{ fill: OttometricLogoColorSchema.sky }}
        d='M 639.83 570.066 C 629.94 578.246 618.38 585.846 605.41 592.766 C 593.24 599.266 579.83 605.156 565.34 610.336 C 533.01 621.896 495.4 629.936 454.71 633.356 C 440.3 634.566 425.51 635.196 410.43 635.196 C 395.35 635.196 380.56 634.566 366.15 633.356 C 325.45 629.936 287.85 621.906 255.52 610.336 C 241.03 605.156 227.62 599.266 215.45 592.766 C 202.48 585.846 190.91 578.246 181.03 570.066 C 156.11 549.446 141.74 525.216 141.74 499.286 C 141.74 494.726 142.22 490.216 143.09 485.766 C 143.8 482.146 144.77 478.556 146.03 475.026 C 166.13 418.636 255.01 374.346 367.17 365.156 C 370.49 364.886 373.84 364.646 377.21 364.436 C 380.08 364.256 382.97 364.116 385.87 363.976 C 393.97 363.596 402.15 363.376 410.44 363.376 C 418.73 363.376 426.92 363.596 435.02 363.976 C 437.91 364.106 440.81 364.256 443.68 364.436 C 447.04 364.646 450.39 364.886 453.72 365.156 C 565.84 374.336 654.71 418.606 674.84 474.966 C 676.11 478.526 677.09 482.136 677.8 485.786 C 678.67 490.226 679.15 494.726 679.15 499.286 C 679.12 525.216 664.75 549.446 639.83 570.066 M 609.85 384.556 C 556.23 357.436 485.41 342.506 410.43 342.506 C 335.45 342.506 264.63 357.436 211.01 384.556 C 152.88 413.956 120.86 454.706 120.86 499.286 C 120.86 543.866 152.88 584.606 211.01 614.016 C 264.62 641.136 335.44 656.066 410.43 656.066 C 485.41 656.066 556.23 641.136 609.85 614.016 C 667.99 584.616 700 543.866 700 499.286 C 700 454.706 667.99 413.956 609.85 384.556'
      />
      <path
        style={{ fill: OttometricLogoColorSchema.left_line }}
        d='M 262.12 599.096 L 378.28 377.096 C 378.21 377.106 378.15 377.106 378.09 377.106 C 374.81 377.316 371.55 377.546 368.31 377.816 C 367.79 377.856 367.28 377.926 366.76 377.976 L 223.58 582.326 C 234.8 588.276 247.09 593.706 260.2 598.446 C 260.83 598.666 261.49 598.866 262.12 599.096'
      />
      <polygon
        style={{ fill: OttometricLogoColorSchema.top_line }}
        points='401.65 380.166 399.55 395.146 421.29 394.996 419.21 380.166'
      />
      <path
        style={{ fill: OttometricLogoColorSchema.bottom_line }}
        d='M 372.16 590.456 L 368.08 619.526 C 382 620.676 396.24 621.256 410.43 621.256 C 424.62 621.256 438.86 620.666 452.77 619.526 L 448.7 590.456 L 372.16 590.456 Z'
      />
      <path
        style={{ fill: OttometricLogoColorSchema.right_line }}
        d='M 442.78 377.106 C 442.72 377.106 442.65 377.096 442.59 377.096 L 558.75 599.096 C 559.38 598.876 560.03 598.676 560.66 598.446 C 573.77 593.706 586.06 588.276 597.28 582.326 L 454.11 377.966 C 453.59 377.926 453.08 377.856 452.56 377.806 C 449.32 377.546 446.06 377.316 442.78 377.106'
      />

      <path
        style={{ fill: OttometricLogoColorSchema.frame }}
        d='M 622.96 560.276 C 613.78 567.346 603.03 573.916 590.98 579.896 C 579.67 585.506 567.22 590.606 553.76 595.076 C 523.72 605.066 488.79 612.016 450.99 614.966 C 437.61 616.016 423.87 616.556 409.85 616.556 C 395.84 616.556 382.1 616.016 368.72 614.966 C 330.92 612.016 295.99 605.066 265.95 595.076 C 252.49 590.596 240.04 585.506 228.73 579.896 C 216.68 573.916 205.94 567.346 196.75 560.276 C 173.6 542.456 160.25 521.516 160.25 499.106 C 160.25 495.166 160.7 491.266 161.5 487.426 C 162.16 484.296 163.06 481.196 164.23 478.146 C 182.9 429.406 265.47 391.136 369.66 383.196 C 372.75 382.956 375.86 382.756 378.98 382.576 C 381.64 382.416 384.33 382.296 387.02 382.176 C 394.54 381.846 402.15 381.656 409.85 381.656 C 417.55 381.656 425.16 381.846 432.68 382.176 C 435.37 382.296 438.06 382.416 440.72 382.576 C 443.84 382.756 446.95 382.966 450.04 383.196 C 554.2 391.136 636.75 429.386 655.45 478.106 C 656.63 481.186 657.54 484.306 658.2 487.456 C 659.01 491.296 659.45 495.186 659.45 499.116 C 659.46 521.516 646.11 542.466 622.96 560.276 M 595.11 399.946 C 545.3 376.516 479.51 363.606 409.86 363.606 C 340.21 363.606 274.42 376.516 224.61 399.946 C 170.6 425.366 140.86 460.576 140.86 499.106 C 140.86 537.636 170.6 572.846 224.61 598.256 C 274.41 621.696 340.2 634.606 409.86 634.606 C 479.52 634.606 545.3 621.696 595.11 598.266 C 649.12 572.846 678.86 537.636 678.86 499.106 C 678.86 460.576 649.12 425.366 595.11 399.946'
      />
    </svg>
  )
}
