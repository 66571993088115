import { useCallback } from 'react'
import { useMapContext } from './useMapContext'

export function useHandleMapInteractions() {
  const { setShowFollowButton, setFollowMode } = useMapContext()

  return useCallback(
    (event: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      originalEvent: any
      type: string
    }) => {
      if (!event || !event.originalEvent) return

      const originalEvent = event.originalEvent

      if (
        (event.type === 'zoomstart' && originalEvent instanceof WheelEvent) ||
        (event.type === 'dragstart' && originalEvent instanceof MouseEvent) ||
        (event.type === 'rotatestart' && originalEvent instanceof MouseEvent)
      ) {
        setShowFollowButton(true)
        setFollowMode(false)
      }
    },
    [setFollowMode, setShowFollowButton]
  )
}
