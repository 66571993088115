import {
  forwardRef,
  ReactElement,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import {
  ClickAwayListener,
  Grow,
  Menu,
  MenuList,
  Paper,
  PopoverOrigin,
  PopoverPosition,
  Popper,
} from '@mui/material'
import { Property } from 'csstype'
import { MousePosition } from '../ViewportMenu/types'

export type ArrowPosition = 'left' | 'right'

interface IProps {
  backgroundColor?: Property.BackgroundColor
  children: ReactElement | ReactElement[]
  arrowPosition?: ArrowPosition
  isList?: boolean
  closeHandler: () => void
  mousePosition?: PopoverPosition
  maxHeight?: string
}

export const PopupMenu = forwardRef(function PopupMenyu(props: IProps, ref) {
  const menuRef = useRef<HTMLDivElement>(null)
  const [anchor, setAnchor] = useState<HTMLElement | undefined>(undefined)
  const isOpen = anchor !== undefined
  const mousePosition = useRef<MousePosition | undefined>(undefined)

  useImperativeHandle(ref, () => ({
    spawn: (target: HTMLElement | undefined, mp: MousePosition) => {
      setAnchor(target)
      mousePosition.current = target === undefined ? undefined : mp
    },
  }))

  const style = {
    backgroundColor: props.backgroundColor,
    mt: 1.5,
    overflow: props.maxHeight ? 'auto' : 'visible',
    maxHeight: props.maxHeight ?? '',
    filter: 'drop-shadow(0px 2px 5px rgba(0,0,0,1))',
    ...(props.arrowPosition && {
      '&:before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: 0,
        [props.arrowPosition]: 20,
        width: 10,
        height: 10,
        bgcolor: props.backgroundColor,
        transform: 'translateY(-50%) rotate(45deg)',
        zIndex: 0,
      },
    }),
    scrollbarColor: '#e5e5e5',
    '&::-webkit-scrollbar': { width: '8px' },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#e5e5e533',
      borderRadius: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#e5e5e5',
      borderRadius: '8px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#fff',
    },
  }

  const position = (): ArrowPosition => {
    const rect = menuRef.current?.getBoundingClientRect()

    if (!rect) {
      return props.arrowPosition ?? 'right'
    }

    const width = window.innerWidth
    return width - (rect.x + rect.width) > 0 ? 'right' : 'left'
  }

  const origin: PopoverOrigin | undefined = props.arrowPosition && {
    horizontal: props.arrowPosition,
    vertical: 'top',
  }

  const anchorPosition: PopoverOrigin = props.arrowPosition
    ? {
        horizontal: props.arrowPosition,
        vertical: 'bottom',
      }
    : {
        horizontal: 'right',
        vertical: 'top',
      }

  return !props.isList ? (
    <Menu
      PaperProps={{
        elevation: 0,
        sx: style,
      }}
      transformOrigin={origin}
      anchorOrigin={anchorPosition}
      open={isOpen}
      anchorEl={anchor}
      onClose={props.closeHandler}
      onClick={props.closeHandler}
      ref={menuRef}
      anchorReference={mousePosition.current ? 'anchorPosition' : 'anchorEl'}
      anchorPosition={
        mousePosition.current
          ? {
              left: mousePosition.current.mouseX,
              top: mousePosition.current.mouseY,
            }
          : undefined
      }
    >
      {props.children}
    </Menu>
  ) : (
    <Popper
      open={isOpen}
      anchorEl={anchor}
      role={undefined}
      transition
      style={{
        zIndex: 101,
      }}
      placement={
        props.arrowPosition
          ? `bottom-${position() === 'left' ? 'start' : 'end'}`
          : `${position()}-start`
      }
      modifiers={[
        {
          name: 'preventOverflow',
          enabled: true,
          options: {
            rootBoundary: 'viewport',
          },
        },
      ]}
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <Paper sx={style}>
            <ClickAwayListener onClickAway={props.closeHandler}>
              <MenuList autoFocusItem={isOpen}>{props.children}</MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  )
})
