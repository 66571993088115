import {
  useHandleFocus,
  useTransportContext,
  useUpdateTime,
} from '@modules/transportControls'
import { useDriveTrialContext } from '@pages/Details/providers/DriveTrialDataProvider'
import './styles.scss'

export function FrameView() {
  const updateTime = useUpdateTime()
  const handleFocus = useHandleFocus()

  const { driveTrials, highlightMode } = useDriveTrialContext()
  const { playRef, frameRef } = useTransportContext()

  return (
    <div className='frame-wrapper'>
      <span>Frame: </span>
      <input
        className='frame-input'
        disabled={highlightMode.id !== -1 || driveTrials.length > 1}
        type='number'
        ref={frameRef}
        onFocus={handleFocus}
        onKeyUp={(e) => {
          if (e.key === 'Enter') {
            updateTime(e)
          }
        }}
        onBlur={(e) => {
          updateTime(e)
          playRef.current?.setFrameInputFocused(false)
        }}
      />
    </div>
  )
}
