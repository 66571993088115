import { useCallback, useContext } from 'react'
import { useTransportContext } from './useTransportContext'
import { MediaSyncContext } from '@pages/Details/types/providers'

export function useHandleFocus() {
  const mediaSyncContext = useContext(MediaSyncContext)

  const { playRef } = useTransportContext()

  return useCallback(() => {
    playRef.current?.setFrameInputFocused(true)
    mediaSyncContext.timingObj?.update({
      velocity: 0,
    })
    mediaSyncContext.isPlaying = false
    playRef.current?.setPlaying(false)
  }, [mediaSyncContext, playRef])
}
