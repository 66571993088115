import { useActiveTrial } from '@common/hooks/useActiveTrial'
import { ISynchronizer } from '@common/services/synchronizer/synchronizer'
import MCorp from '@common/services/timing/mcorp'
import { NoData } from '@common/components/NoData/NoData'
import { toggleClasses } from '@common/utils/styling'
import { MCorpApi } from '@modules/videoViewport/types/MCorpApi'
import { Box } from '@mui/material'
import {
  DriveTrial,
  LoadingContext,
  MediaSyncContext,
} from '@pages/Details/types/providers'
import {
  SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useIdleTimerContext } from 'react-idle-timer'
import './styles.scss'
import { ViewportContent } from '@pages/Details/types/viewportContent'

const fullScreenOff = 'video-init'
const fullScreenOn = 'video-fsc-horizontal-stretch'

const mediaSyncOptions: {
  activeTrial: DriveTrial | null
} = {
  activeTrial: null,
}

interface VideoPlayerReactProps {
  playerId: string
  isFullscreen: boolean
  viewportId: number
  synchronizer?: ISynchronizer
  setCanvasSize: React.Dispatch<
    React.SetStateAction<{ width: number; height: number }>
  >
  videoId: number
  url?: string
  videoSide: ViewportContent
}

export const VideoPlayerReact = ({
  playerId,
  isFullscreen,
  synchronizer,
  setCanvasSize,
  viewportId,
  videoId,
  url,
  videoSide,
}: VideoPlayerReactProps) => {
  const [prevVideoSide, setPrevVideoSide] = useState(videoSide)
  const { isVideoLoaded, setIsVideoLoaded, setIsBuffering } =
    useContext(LoadingContext)
  const [hasFailed, setHasFailed] = useState(false)
  const mediaSync = useContext(MediaSyncContext)
  const msyncRef = useRef<MCorpApi | null>(null)
  const videoRef = useRef<HTMLVideoElement>(null)
  const { reset } = useIdleTimerContext()
  const { activeTrial } = useActiveTrial()

  const handleCanvasSize = useCallback(() => {
    if (!videoRef.current) return

    setCanvasSize({
      width: videoRef.current.clientWidth,
      height: videoRef.current.clientHeight,
    })
  }, [setCanvasSize])

  const handleFullScreenToggle = useCallback(() => {
    if (!videoRef.current) return

    const videoElement = videoRef.current
    videoElement.style.all = 'revert'

    toggleClasses(
      videoElement,
      isFullscreen ? [fullScreenOff] : [fullScreenOn],
      isFullscreen ? [fullScreenOn] : [fullScreenOff]
    )

    handleCanvasSize()
  }, [handleCanvasSize, isFullscreen])

  const initializeMediaSync = useCallback(() => {
    if (!videoRef.current || msyncRef.current) return

    msyncRef.current = MCorp.mediaSync(
      videoRef.current,
      mediaSync.delay,
      mediaSyncOptions
    )
  }, [mediaSync])

  const handleCanPlayThrough = useCallback(() => {
    const videoElement = videoRef.current
    if (!videoElement) return

    const isFullyBuffered = videoElement.readyState === 4
    synchronizer?.updateStatus(viewportId, isFullyBuffered)
    setIsBuffering(!isFullyBuffered)
    mediaSync.isSeeking = !isFullyBuffered

    if (isFullyBuffered) {
      videoElement.style.visibility = 'visible'
      if (mediaSync.timingObj?.vel === 0) {
        const time = mediaSync.timingObj?.pos
        synchronizer?.triggerDraw(time, viewportId, videoId)

        // Fix for https://ottometric.atlassian.net/browse/VOL-323
        // Important to also check prevVideoSide !== videoSide, otherwise it can create infinite loop
        if (
          videoRef.current.currentTime !== time &&
          prevVideoSide !== videoSide
        ) {
          videoRef.current.currentTime = time
          setPrevVideoSide(videoSide)
        }
      }
    }

    initializeMediaSync()
  }, [
    synchronizer,
    viewportId,
    setIsBuffering,
    mediaSync,
    initializeMediaSync,
    videoId,
    prevVideoSide,
    videoSide,
  ])

  const handleError = useCallback(
    (err: SyntheticEvent<HTMLVideoElement>) => {
      console.error(`Error loading video ${playerId}: `, err)
      setHasFailed(true)
      synchronizer?.updateStatus(viewportId, true)
    },
    [playerId, synchronizer, viewportId]
  )

  const handleLoadedData = useCallback(() => {
    if (isVideoLoaded) return

    setIsVideoLoaded(true)
    handleFullScreenToggle()
    handleCanvasSize()
  }, [
    handleCanvasSize,
    handleFullScreenToggle,
    isVideoLoaded,
    setIsVideoLoaded,
  ])

  useEffect(() => {
    const videoElement = videoRef.current

    const resizeObserver = new ResizeObserver(handleCanvasSize)

    if (videoElement) {
      resizeObserver.observe(videoElement)
    }

    return () => resizeObserver.disconnect()
  }, [handleCanvasSize])

  useEffect(() => {
    mediaSyncOptions.activeTrial = activeTrial
  }, [activeTrial])

  useEffect(() => {
    handleFullScreenToggle()
  }, [handleFullScreenToggle])

  if (hasFailed) return <NoData languageCode='enUS' />

  return (
    <Box pr='40px' className='player-container'>
      <video
        ref={videoRef}
        src={url}
        onEmptied={() => {
          if (videoRef.current) {
            videoRef.current.style.visibility = 'hidden'
            setIsBuffering(true)
          }
        }}
        onWaiting={() => {
          synchronizer?.updateStatus(viewportId, false)
          setIsBuffering(true)
        }}
        onStalled={() => {
          synchronizer?.updateStatus(viewportId, false)
          setIsBuffering(true)
        }}
        onPlaying={() => (mediaSync.isSeeking = false)}
        onCanPlayThrough={handleCanPlayThrough}
        onProgress={reset}
        onError={handleError}
        onLoadedData={handleLoadedData}
      />
    </Box>
  )
}
