import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore'

interface SortingIconProps {
  sortDirection: string | false
}

export function SortingIcon({ sortDirection }: SortingIconProps) {
  return (
    {
      asc: <ArrowUpwardIcon sx={{ color: '#141414' }} fontSize='small' />,
      desc: <ArrowDownwardIcon sx={{ color: '#141414' }} fontSize='small' />,
    }[sortDirection as string] ?? (
      <UnfoldMoreIcon sx={{ color: '#b7b7b7' }} fontSize='small' />
    )
  )
}
