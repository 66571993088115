import { useClientViewportMenuQuery } from '@api/index'
import { layerColorsData } from '@common/constants/layerColorsData'
import { Synchronizer } from '@common/services/synchronizer/synchronizer'
import { gridOptions } from '@common/components/GridSelectors/GridSelectors'
import { useContext, useEffect, useRef, useState } from 'react'
import { GridLayout } from './components/GridLayout'
import { Header, ReportNavigationContextProvider } from '@modules/header'
import { TransportControls } from '@modules/transportControls'
import { DetailViewport } from './containers/Viewport'
import { useGrid } from './hooks/useGrid'
import { useJumpToTime } from './hooks/useJumpToTime'
import { usePauseVideoViewports } from './hooks/usePauseVideoViewports'
import DriveTrialDataProvider, {
  useDriveTrialContext,
} from './providers/DriveTrialDataProvider'
import { TimelineContextProviderControlled } from '../../modules/timelineViewport/context/TimelineContextProvider'
import { TopViewProvider } from './providers/TopViewProvider'
import { MediaSyncContext } from './types/providers'
import { buildLayoutData } from './utils/layersPanel'
import './style.scss'
import { TransportContextProvider } from '@modules/transportControls/context/TransportContext'

export const DetailsBase = () => {
  const { modeKey } = useDriveTrialContext()
  const mediaSyncContext = useContext(MediaSyncContext)
  const screenshotRef = useRef<HTMLDivElement>(null)

  const [synchronizer, setSynchronizer] = useState<Synchronizer>()
  const layerPanelData = buildLayoutData(layerColorsData)
  const { data: menuOptions = [] } = useClientViewportMenuQuery()
  const [fullscreenId, setFullscreenId] = useState<number | null>(null)
  const { grid, changeGrid } = useGrid()

  usePauseVideoViewports(synchronizer)
  useJumpToTime()

  useEffect(() => {
    setSynchronizer(new Synchronizer(grid.value, mediaSyncContext, 0))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div ref={screenshotRef} className='dp-container'>
      <ReportNavigationContextProvider>
        <Header
          gridChangeHandler={changeGrid}
          fullscreen={fullscreenId !== null}
        />
      </ReportNavigationContextProvider>
      <TimelineContextProviderControlled>
        <TransportContextProvider>
          <TopViewProvider key={grid.name}>
            <GridLayout option={grid}>
              {gridOptions.map(({ name }, index) => (
                <DetailViewport
                  key={name}
                  colors={layerColorsData}
                  layerPanelData={layerPanelData}
                  id={index + 1}
                  menuOptions={menuOptions}
                  grid={grid}
                  synchronizer={synchronizer}
                  fullscreenId={fullscreenId}
                  setFullscreenId={setFullscreenId}
                />
              ))}
            </GridLayout>
            <TransportControls key={modeKey} synchronizer={synchronizer} />
          </TopViewProvider>
        </TransportContextProvider>
      </TimelineContextProviderControlled>
    </div>
  )
}

export const Details = () => (
  <DriveTrialDataProvider>
    <DetailsBase />
  </DriveTrialDataProvider>
)
