import { Field, RuleType } from 'react-querybuilder'
import { match } from 'ts-pattern'
import {
  completenessTypeMap,
  generalTypeMap,
  nameMap,
  namesIncludingUnderscore,
} from '../types/queryBuilder'
import { ReportType } from '@modules/reportTable'

export const sortBetween = (x: RuleType<string, string, any, string>) => {
  const items = x.value.split(',').map((item: string) => item.trim())

  const num1 = parseFloat(items[0])
  const num2 = parseFloat(items[1])

  const areBothNumbers = !isNaN(num1) && !isNaN(num2)

  const sortedItems = areBothNumbers
    ? [num1, num2].sort((a, b) => a - b)
    : items.sort()

  return sortedItems.join(', ')
}

const getOptionConfig = (val: string, reportType: ReportType) => {
  const map = match(reportType)
    .with('completeness', () => completenessTypeMap)
    .with('quality', () => generalTypeMap)
    .with('characterization', () => generalTypeMap)
    .otherwise(() => generalTypeMap)

  const existingName = map.get(val)
  return existingName ? existingName : {}
}

const validator = (r: RuleType) => !!r.value

export const getDropdownOptions = (
  items: string[],
  reportType: ReportType
): Field[] => {
  const isKPI = ['lanes', 'signs', 'lights', 'ttd'].includes(reportType)
  const duplicatePrefixes = getDuplicatePrefixes(items)

  return items.map((item) => {
    const parts = item.split('|')
    const name = isKPI ? parts[0] : getOptionName(parts[0], duplicatePrefixes)
    const value = parts[1]
    return {
      name: getOptionValue(value),
      label: name.toLocaleUpperCase(),
      placeholder: `Enter ${name.toLowerCase()}`,
      validator,
      ...getOptionConfig(name, reportType),
    }
  })
}

export const getDuplicatePrefixes = (items: string[]) => {
  const allPrefixes = items.map((item) => item.split('|')[0].split('_')[0])
  const prefixMap = allPrefixes.reduce(
    (result: { [key: string]: number }, value) => ({
      ...result,
      [value]: result[value] + 1,
    }),
    {}
  )

  return Object.keys(prefixMap).filter((a) => prefixMap[a] > 1)
}

export const getOptionName = (val: string, duplicatePrefixes: string[]) => {
  const hasUnderscore = val.includes('_')

  if (!hasUnderscore || namesIncludingUnderscore.includes(val)) return val

  const isNonUnique = duplicatePrefixes.some((x) =>
    val.toLocaleLowerCase().includes(x.toLocaleLowerCase())
  )

  return isNonUnique
    ? val.replace('_', ' - ')
    : val.split('_').slice(1).join('_')
}

export const getOptionValue = (val: string) => {
  const mapValue = nameMap.get(val)
  return mapValue ? mapValue : val
}
