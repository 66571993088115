import { useVideoUrlQuery } from '@api/url'
import { createVideoPath } from '@api/urls'
import { useActiveTrial } from '@common/hooks/useActiveTrial'
import { ISynchronizer } from '@common/services/synchronizer/synchronizer'
import { NoData } from '@common/components/NoData/NoData'
import { ObjectColor, VideoViewportLoader } from '@modules/videoViewport'
import { getCameraURL } from '@modules/videoViewport/utils/camera'
import { LoadingProvider } from 'pages/Details/providers/LoadingProvider'
import { ViewportContent } from '@pages/Details/types/viewportContent'
import { useEffect, useState } from 'react'
import { CanvasLayers } from '../CanvasLayers'
import { VideoPlayerReact } from '../VideoPlayerReact'

interface VideoWithCanvasProps {
  playerId: string
  colors: ObjectColor[]
  viewportId: number
  isFullscreen: boolean
  title: string
  synchronizer?: ISynchronizer
  videoSide: ViewportContent
}

export function VideoWithCanvas({
  playerId,
  colors,
  viewportId,
  isFullscreen,
  title,
  synchronizer,
  videoSide,
}: VideoWithCanvasProps) {
  const { activeTrial } = useActiveTrial()
  const [videoName, setVideoName] = useState<string | null>(null)
  const { data: urlData } = useVideoUrlQuery(
    createVideoPath(activeTrial.parentDTID, videoName ?? '')
  )

  const [canvasSize, setCanvasSize] = useState({
    width: 0,
    height: 0,
  })

  useEffect(() => {
    let resolvedVideoName = ''
    try {
      resolvedVideoName = getCameraURL(videoSide)
    } catch (e) {
      if (e instanceof Error) {
        console.error(e.message)
      }
    }

    if (!resolvedVideoName) {
      synchronizer?.updateStatus(viewportId, true)
    }
    setVideoName(resolvedVideoName)
  }, [videoSide, synchronizer, viewportId])

  if (!videoName || !activeTrial) {
    return <NoData languageCode='enUS' viewport={title} />
  }

  return (
    <LoadingProvider title={title}>
      <div
        style={{
          position: 'absolute',
          zIndex: 10,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          whiteSpace: 'nowrap',
        }}
      >
        <VideoViewportLoader />
      </div>

      <VideoPlayerReact
        url={urlData?.url}
        videoId={activeTrial!.id}
        synchronizer={synchronizer}
        isFullscreen={isFullscreen}
        setCanvasSize={setCanvasSize}
        playerId={playerId}
        viewportId={viewportId}
        videoSide={videoSide}
      />
      {!title.includes('Basler') && (
        <CanvasLayers
          isFullscreen={isFullscreen}
          canvasSize={canvasSize}
          colors={colors}
          DTID={activeTrial.DTID}
          parentDTID={activeTrial.parentDTID!}
          viewportId={viewportId}
          synchronizer={synchronizer}
          playerId={playerId}
          videoId={activeTrial!.id}
        />
      )}
    </LoadingProvider>
  )
}
