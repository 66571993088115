import { useKpiFiltersQuery } from '@api/index'
import AccountTreeIcon from '@mui/icons-material/AccountTree'
import FilterListIcon from '@mui/icons-material/FilterList'
import FlashOnIcon from '@mui/icons-material/FlashOn'
import { Button, Typography } from '@mui/material'
import { camelCase, capitalize } from 'lodash'
import { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { match } from 'ts-pattern'
import { isDevelopmentOrLocal } from '@common/constants/env'
import { enUS_SIDEBAR_TOOLBOX } from '@common/constants/messages'
import { ReportTypes, TableRouteParams } from '@common/constants/paths'
import { DrawerMenu } from '@common/components/DrawerMenu/DrawerMenu'
import { Toolbar } from '@common/components/Toolbar/Toolbar'
import { ProjectName } from 'models/project'
import { HideShowPicker } from '@modules/sidebarToolbox/components/HideShowPicker'
import { ReportsSelector } from '@modules/sidebarToolbox/components/ReportsSelector'
import './style.scss'
import {
  IconDefinition,
  TabsRoutes,
  ToolbarItem,
  ToolboxProps,
} from '@modules/sidebarToolbox/types/sidebar'
import { createFilterPayload } from '@modules/sidebarToolbox/utils/filters'
import {
  iconStyles,
  iconStylesDisabled,
} from '@modules/sidebarToolbox/constants/styles'
import {
  prepareKpiFilters,
  formatQuerySql,
  createKpiFilterPayload,
} from '@modules/sidebarToolbox/utils/kpis'
import { KpiFilters } from '../KpiFilters'
import { SideFilters } from '../SideFilters'
import { QueryBuilderSelector } from '../QueryBuilderSelector'
import { useQueryParams } from '@common/hooks/useQueryParams'
import { useAuthContext, useLogout } from '@modules/auth'
import { REASON_ENUM } from '@api/logout'
import { useProject } from '@api/table/projects'
import { RuleGroupType } from 'react-querybuilder'
import { KpiSummaryState } from '@modules/sidebarToolbox/types/kpiFilters'
import { FilterObject } from 'models/table'
import { sortBetween } from '@modules/sidebarToolbox/utils/queryBuilder'
import {
  DashboardParametersType,
  ExternalLinkParametersType,
  FiltersState,
  INIT_DATA_TABLE_COLUMN_VISIBILITY,
  INIT_DATA_TABLE_COLUMN_VISIBILITY_FOR_PUBLISH_ADMIN,
  INIT_KPI_LANES_COLUMN_VISIBILITY,
  INIT_KPI_LIGHTS_COLUMN_VISIBILITY,
  INIT_KPI_SIGNS_COLUMN_VISIBILITY,
  ReportType,
  useReportContext,
} from '@modules/reportTable'
import { getClientID, getProjectID } from '@common/utils/storage'
import { PUBLISH_ADMIN } from '@api/table/adminLevel'

const availableTabs: TabsRoutes = {
  'Data Completeness': ReportTypes.COMPLETENESS,
  'Data Quality': ReportTypes.QUALITY,
  Characterization: ReportTypes.CHARACTERIZATION,
}

const tabRouteMap: TabsRoutes = {
  [ReportTypes.COMPLETENESS]: 'Data Completeness',
  [ReportTypes.QUALITY]: 'Data Quality',
  [ReportTypes.CHARACTERIZATION]: 'Characterization',
}

if (isDevelopmentOrLocal) {
  availableTabs.Characterization = ReportTypes.CHARACTERIZATION
  availableTabs['Pipeline Status'] = ReportTypes.PIPELINE_STATUS
  tabRouteMap[ReportTypes.CHARACTERIZATION] = 'Characterizations'
}

export const SidebarToolbox = ({
  anchor = 'left',
  variant = 'temporary',
  onDrawerToggle,
}: ToolboxProps) => {
  const { adminLevelID } = useAuthContext()
  const queryParams = useQueryParams()
  const logout = useLogout(REASON_ENUM.PROJECT_NOT_AUTHORIZED)
  const { data: projectData } = useProject()
  const { pathname } = useLocation()
  const { reportType } = useParams<TableRouteParams>()
  const {
    preserveState,
    setPreserveState,
    setHilVersion,
    projectID: program,
  } = useReportContext()
  const [openedTool, setOpenedTool] = useState<ToolbarItem>('')
  const isKPI = pathname.includes('kpi')
  const isEnabled =
    pathname.includes('dashboards') || pathname.includes('spr-gallery')
  const { data = [] } = useKpiFiltersQuery(isKPI)
  const isFromExternal = queryParams.get('external')
  const projectID = queryParams.get('projectID')
  const hasProject =
    projectID &&
    projectData &&
    projectData.some((project) => project.projectID === +projectID)
  const [externalLinkParameters, setExternalLinkParameters] =
    useState<ExternalLinkParametersType>()
  const [initialQuery, setInitialQuery] = useState<RuleGroupType>()
  const [dashboardFilterObject, setDashboardFilterObject] =
    useState<DashboardParametersType>()

  const handleExternalActions = () => {
    const sourceURL = window.location.href
    window.localStorage.removeItem('externalURL')
    const url = new URL(sourceURL)
    url.searchParams.delete('external')
    const newURL = url.toString()
    window.history.replaceState(null, '', newURL)
  }
  useEffect(() => {
    if (hasProject === false) {
      if (isFromExternal) {
        handleExternalActions()
      }
      logout()
    }
    if (hasProject === true) {
      if (isFromExternal) {
        window.localStorage.setItem('projectID', projectID)
        handleExternalActions()
      }
    }
  }, [hasProject])

  useEffect(() => {
    if (!dashboardFilterObject) return
    const { weather, roadType, timeOfDay } = dashboardFilterObject

    Object.keys(dashboardFilterObject).forEach((key) => {
      if (
        dashboardFilterObject[key as keyof DashboardParametersType] === 'Total'
      ) {
        dashboardFilterObject[key as keyof DashboardParametersType] = ''
      }
    })

    const filterState = {
      ...preserveState[reportType!].filterState,
      environmentFilters: dashboardFilterObject,
    } as FiltersState

    const filterPayload = createFilterPayload(filterState, reportType!, 1)

    if (roadType && weather && timeOfDay) {
      setPreserveState({
        ...preserveState,
        [reportType!]: {
          ...preserveState[reportType!],
          filterToSql: filterPayload.filters!,
          searchType: 'filter',
          filterState,
        },
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboardFilterObject])

  useEffect(() => {
    if (!externalLinkParameters) return
    const {
      clause,
      endDate,
      projectID,
      roadType,
      speedRange,
      startDate,
      timeOfDay,
      version,
      weatherConditions,
    } = externalLinkParameters
    localStorage.setItem('hil', version)
    setHilVersion(version)
    const kpiFilterData = prepareKpiFilters(data, reportType!)
    const kpiFilterState: KpiSummaryState = {
      filters: {
        projectID: projectID,
        clause: clause,
        startDate: startDate,
        endDate: endDate,
        'Road Type': roadType,
        'Speed Range': speedRange,
        'Time of Day': timeOfDay,
        'Weather Conditions': weatherConditions,
      },
      dateRange: [startDate, endDate],
    }
    const kpiFilterPayload = createKpiFilterPayload(
      kpiFilterData,
      kpiFilterState,
      'Lights',
      'kpiLights' as ReportType
    )
    const kpiFiltersToCamelCase: FilterObject = {}
    Object.entries(kpiFilterPayload.kpiFilters!).forEach(([key, value]) => {
      kpiFiltersToCamelCase[camelCase(key)] = value
    })
    const updateQuery: any = (query: RuleGroupType) => ({
      ...query,
      rules: query.rules.map((x) => {
        if ('rules' in x) return updateQuery(x)
        if (
          'operator' in x &&
          (x.operator === 'between' || x.operator === 'notBetween')
        ) {
          const sorted = sortBetween(x)
          return {
            ...x,
            value: sorted,
          }
        }
        return x
      }),
    })
    const queryRulesModified = updateQuery(initialQuery)
    setPreserveState({
      ...preserveState,
      [reportType!]: {
        ...preserveState[reportType!],
        filterKpiState: kpiFilterState,
        filterKpiToSql: kpiFiltersToCamelCase,
        queryBuilderState: initialQuery,
        searchType: 'sql',
        clause: `WHERE ${formatQuerySql(queryRulesModified)}`,
      },
    })
  }, [externalLinkParameters, data])

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    const jsonDataString = queryParams.get('data')

    const external = queryParams.get('external')
    if (external === 'True') {
      const initialQueryFromUrl = parseQueryFromUrl(window.location.href)
      setInitialQuery(initialQueryFromUrl)
      const decodedData: ExternalLinkParametersType =
        parseUrlToExternalLinkParameters(window.location.href)
      setExternalLinkParameters(decodedData)
    }

    if (jsonDataString) {
      const decodedData: DashboardParametersType = JSON.parse(
        decodeURIComponent(jsonDataString)
      )
      const { weather, roadType, timeOfDay } = decodedData

      if (roadType && weather && timeOfDay) {
        setDashboardFilterObject({ weather, roadType, timeOfDay })
      }
    }

    if (!reportType) setOpenedTool('')
    const kpis = ['lanes', 'signs', 'lights', 'ttd']
    if (openedTool === 'Advanced Search' && kpis.includes(reportType!)) {
      setOpenedTool('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportType])

  const parseQueryFromUrl = (url: string): RuleGroupType => {
    const params = new URLSearchParams(url.split('?')[1])
    const advancedQuery = params.get('advanced_query')
    if (!advancedQuery) {
      return { combinator: 'and', rules: [] }
    }
    const field =
      advancedQuery.split('|')[0] +
      '_' +
      advancedQuery.split('|')[1].split(' ')[0]
    const operator =
      advancedQuery.split('|')[1].split(' ')[1] === 'LIKE'
        ? 'contains'
        : advancedQuery.split('|')[1].split(' ')[1]
    const value = advancedQuery.split('%')[1]
    return {
      combinator: 'and',
      rules: [
        {
          field,
          operator,
          value,
        },
      ],
    }
  }
  const parseUrlToExternalLinkParameters = (
    url: string
  ): ExternalLinkParametersType => {
    const urlParams = new URLSearchParams(new URL(url).search)
    return {
      clause: urlParams.get('advanced_query') || '',
      clientID: getClientID() || '100',
      endDate: urlParams.get('end_date') || '',
      kpi: "['GT', 'TP', 'FP', 'FN', 'Accuracy', 'Recall']",
      limit: 50,
      offset: 0,
      projectID: getProjectID() || '',
      roadType: capitalize(urlParams.get('RoadType') || ''),
      sortColumn: 'DTID',
      sortOrder: 'desc',
      speedRange: urlParams.get('SpeedRange') || '',
      startDate: urlParams.get('start_date') || '',
      timeOfDay: capitalize(urlParams.get('TimeOfDay') || ''),
      version: urlParams.get('hil') || '',
      weatherConditions: capitalize(urlParams.get('WeatherConditions') || ''),
    }
  }

  const toggleDrawer = (tool: ToolbarItem) => {
    setOpenedTool(openedTool === tool ? '' : tool)
  }

  const clearAll = () => {
    const initVisibility = match(reportType)
      .with('ttd', () => INIT_KPI_LANES_COLUMN_VISIBILITY)
      .with('lanes', () => INIT_KPI_LANES_COLUMN_VISIBILITY)
      .with('signs', () => INIT_KPI_SIGNS_COLUMN_VISIBILITY)
      .with('lights', () => INIT_KPI_LIGHTS_COLUMN_VISIBILITY)
      .otherwise(() =>
        adminLevelID === PUBLISH_ADMIN
          ? INIT_DATA_TABLE_COLUMN_VISIBILITY_FOR_PUBLISH_ADMIN
          : INIT_DATA_TABLE_COLUMN_VISIBILITY
      )

    setPreserveState({
      ...preserveState,
      [reportType!]: {
        ...preserveState[reportType!],
        columnVisibility: initVisibility,
      },
    })
  }

  useEffect(() => {
    onDrawerToggle(Boolean(openedTool))
  }, [openedTool, onDrawerToggle])

  useEffect(() => {
    setOpenedTool('')
  }, [reportType, program])

  const toolbarContent: IconDefinition = [
    {
      icon: (
        <AccountTreeIcon
          fontSize='medium'
          sx={isEnabled ? iconStylesDisabled : iconStyles}
        />
      ),
      callback: () => toggleDrawer('Columns'),
      opened: openedTool === 'Columns',
      title: enUS_SIDEBAR_TOOLBOX.columns,
      disabled: isEnabled,
    },
    {
      icon: (
        <FilterListIcon
          fontSize='medium'
          sx={isEnabled ? iconStylesDisabled : iconStyles}
        />
      ),
      callback: () => toggleDrawer('Search'),
      opened: openedTool === 'Search',
      title: enUS_SIDEBAR_TOOLBOX.search,
      disabled: isEnabled,
    },
    {
      icon: (
        <FlashOnIcon
          fontSize='medium'
          sx={isEnabled ? iconStylesDisabled : iconStyles}
        />
      ),
      callback: () => toggleDrawer('Advanced Search'),
      opened: openedTool === 'Advanced Search',
      title: enUS_SIDEBAR_TOOLBOX.advancedSearch,
      disabled: isEnabled,
    },
  ]

  return (
    <>
      <Toolbar side={anchor} content={toolbarContent} />
      <DrawerMenu anchor={anchor} variant={variant} open={Boolean(openedTool)}>
        <div className='drawer-content'>
          <Typography
            variant='h6'
            display='flex'
            alignItems='center'
            justifyContent={'space-between'}
            sx={{
              color: '#e5e5e5',
              padding: '24px 0 24px 8px',
              userSelect: 'none',
            }}
          >
            {openedTool.toUpperCase()}
            {openedTool === 'Columns' && (
              <Button variant='contained' onClick={clearAll}>
                Clear
              </Button>
            )}
          </Typography>
          {match(openedTool)
            .with('Reports', () => <ReportsSelector />)
            .with('Columns', () => <HideShowPicker reportType={reportType!} />)
            .with('Search', () =>
              isKPI ? (
                <KpiFilters
                  project={
                    reportType === 'ttd'
                      ? 'Lanes'
                      : (capitalize(reportType) as ProjectName)
                  }
                  filterData={prepareKpiFilters(
                    data,
                    (reportType === 'ttd' ? 'lanes' : reportType) || ''
                  )}
                  reportType={reportType === 'ttd' ? 'lanes' : reportType!}
                />
              ) : (
                <SideFilters
                  reportType={reportType === 'ttd' ? 'lanes' : reportType!}
                />
              )
            )
            .with('Advanced Search', () => (
              <QueryBuilderSelector
                reportType={reportType === 'ttd' ? 'lanes' : reportType!}
              />
            ))
            .otherwise(() => null)}
        </div>
      </DrawerMenu>
    </>
  )
}
