import { useActiveTrial } from '@common/hooks/useActiveTrial'
import { ISynchronizer } from '@common/services/synchronizer/synchronizer'
import { DisplayElapsedTime } from '@common/components/VideoControls/DisplayElapsedTime/DisplayElapsedTime'
import { getClientID } from '@common/utils/storage'
import { MediaSyncContext } from '@pages/Details/types/providers'
import { useContext } from 'react'
import { Shortcuts } from '@modules/transportControls/components/Shortcuts'
import { ControlsSlider } from '../ControlsSlider'
import { JogWheel } from '@modules/transportControls/components/JogWheel'
import {
  useTransportContext,
  useHandleTimeChange,
  useHandlePlayClick,
  useHandlePlaybackRate,
  useHandleRender,
  useHandleJogMovement,
  PlayControls,
} from '@modules/transportControls'
import './styles.scss'
import { FrameView } from '@modules/transportControls/components/FrameView'
import { TimelineMarkControls } from '@modules/transportControls/components/TimelineMarkControls'

export const TransportControls = ({
  synchronizer,
}: {
  synchronizer?: ISynchronizer | undefined
}) => {
  const mediaSyncContext = useContext(MediaSyncContext)
  const clientID = getClientID()

  const { durationRef, playRef, frameRef } = useTransportContext()

  const handleTimeChange = useHandleTimeChange()
  const handlePlayClick = useHandlePlayClick({ synchronizer })
  const handlePlaybackRateChange = useHandlePlaybackRate()
  const handleRender = useHandleRender()
  const onJogMovement = useHandleJogMovement()

  useActiveTrial(handleTimeChange)

  return (
    <div className='controls-container'>
      <PlayControls
        onPlayPause={handlePlayClick}
        onChangePlaybackRate={handlePlaybackRateChange}
        ref={playRef}
        frameRef={frameRef}
      />
      <TimelineMarkControls />
      {clientID && <FrameView />}
      <DisplayElapsedTime
        duration={mediaSyncContext.totalDuration}
        ref={durationRef}
      />
      <JogWheel min={-8} max={8} onJog={onJogMovement} />
      {mediaSyncContext.totalDuration && (
        <ControlsSlider
          min={0}
          max={100}
          handleRender={handleRender}
          duration={mediaSyncContext.totalDuration}
        />
      )}

      <Shortcuts />
    </div>
  )
}
