import { Column, Row } from '@tanstack/react-table'
import dayjs from 'dayjs'
import jsPDF from 'jspdf'
import autoTable, { RowInput } from 'jspdf-autotable'
import { bodyStyling, headerStyling } from './style'
import { PaperFormat, PrintLayout } from './types'
import { csvAndPdfTransformer } from '@modules/reportTable/utils/csvTransformers'
import { ReportType } from '@modules/reportTable'

export const pdfCreator = (
  visibleColumns: Column<any, any>[],
  bodyRows: Row<any>[],
  format: PaperFormat,
  layout: PrintLayout,
  reportType: ReportType
) => {
  const superHeader = visibleColumns
    .filter(
      (col) =>
        !col.parent &&
        !col.id.includes('select') &&
        col.columns.some((col) => col.getIsVisible())
    )
    .map((col) => ({
      content: col.columnDef.header,
      colSpan: col.columns.filter((c) => c.getIsVisible()).length,
      styles: headerStyling,
    })) as RowInput[]

  const header = visibleColumns
    .filter((col) => col.parent && !col.id.includes('select'))
    .map((col) => ({
      content: col.columnDef.header,
      colSpan: 1,
      styles: headerStyling,
    })) as RowInput[]

  const headerData = [superHeader, header] as RowInput[]
  const bodyData = bodyRows.map((row) =>
    row
      ._getAllVisibleCells()
      .filter((cell) => !cell.id.includes('select'))
      .map((cell) => ({
        content: csvAndPdfTransformer(cell, reportType),
        colSpan: 1,
        styles: bodyStyling,
      }))
  ) as RowInput[]

  const document = new jsPDF(layout, 'mm', format)

  autoTable(document, {
    head: headerData,
    body: bodyData,
  })

  const name = `${dayjs().utc().format('DD-MM-YYYY HH:mm')}.pdf`

  document.save(name)
}
