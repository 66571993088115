import { DataSet } from 'vis-data'
import { toMilliseconds, toSeconds } from '@common/utils/time'
import { SortHighlights, TimelineItemData } from '../types/timeline'

const doItemsOverlap = (item1: TimelineItemData, item2: TimelineItemData) => {
  if (!item1.end && item2.end) {
    return +item1.start < +item2.end && +item1.start > +item2.start
  } else if (item1.end && !item2.end) {
    return +item2.start < +item1.end && +item2.start > +item1.start
  } else if (item1.end && item2.end) {
    return +item1.start < +item2.end && +item1.end > +item2.start
  } else {
    return +item1.start === +item2.start
  }
}

export const strechToMinLength = (
  item: TimelineItemData,
  minHighlightLength: number
) => {
  const itemLength = +item.end! - +item.start
  const delta = itemLength - minHighlightLength
  if (delta >= 0) {
    return {
      ...item,
      content: '<div></div>',
      className: 'highlight-100',
    }
  } else {
    const itemLengthTruePercentage = Math.round(
      (itemLength * 100) / minHighlightLength
    )

    const start =
      Math.floor(+item.start + delta / 2) < 0
        ? 0
        : Math.floor(+item.start + delta / 2)

    const end =
      Math.floor(+item.start + delta / 2) < 0
        ? Math.ceil(+item.end! - delta)
        : Math.ceil(+item.end! - delta / 2)

    return {
      ...item,
      start: start,
      end: end,
      content: '<div></div>',
      className: `highlight-${itemLengthTruePercentage}`,
    }
  }
}

const adjustItem = (
  overlappingItem: TimelineItemData,
  selectedGroupItems: TimelineItemData[]
) => {
  const sortedSelectedItems = selectedGroupItems.sort(
    (a, b) => +a.start - +b.start
  )
  const adjustedItems: TimelineItemData[] = []

  sortedSelectedItems.forEach((selectedItem) => {
    if (doItemsOverlap(overlappingItem, selectedItem)) {
      const overlapStart = Math.max(+overlappingItem.start, +selectedItem.start)
      const overlapEnd =
        overlappingItem.end &&
        selectedItem.end &&
        Math.min(+overlappingItem.end, +selectedItem.end)
      adjustedItems.push(
        overlapEnd
          ? {
              ...overlappingItem,
              start: overlapStart,
              end: overlapEnd,
            }
          : {
              ...overlappingItem,
              start: overlapStart,
            }
      )
    }
  })

  return adjustedItems
}

const repositionMainItems = (main: TimelineItemData[]) => {
  const distance: number[] = []
  let currentStart = 0
  const repositionedItems = main.map((item: TimelineItemData) => {
    const newItem = {
      ...item,
      start: currentStart,
      end: +currentStart + (+item.end! - +item.start),

      originalStart: item.start as number,
      originalEnd: item.end as number,
    }

    currentStart = newItem.end
    distance.push(+item.start - +newItem.start)

    return newItem
  })

  return [repositionedItems, distance]
}

const moveOtherItems = (
  mainItems: TimelineItemData[],
  otherItems: TimelineItemData[],
  distance: number[]
) => {
  const idList = mainItems.map((item) => item.id)
  const adjustedItems: TimelineItemData[] = []
  otherItems.forEach((item) => {
    const overlappingSelectedItems = mainItems.filter((selectedItem) =>
      doItemsOverlap(item, selectedItem)
    )

    if (overlappingSelectedItems.length > 0) {
      const adjustedSegments = adjustItem(item, overlappingSelectedItems)

      adjustedSegments.forEach((segment, i) => {
        segment.start =
          +segment.start -
          distance[idList.indexOf(overlappingSelectedItems[0 + i].id)]
        if (segment.end) {
          segment.end =
            +segment.end -
            distance[idList.indexOf(overlappingSelectedItems[0 + i].id)]
        }
      })

      adjustedItems.push(...adjustedSegments)
    }
  })

  return adjustedItems
}

export const getMainItems = (
  items: TimelineItemData[],
  groupId: number,
  minHighlightLength: number,
  sortBy: SortHighlights
) => {
  const mainItemsFiltered = items
    .filter((item) => item.group === groupId && item.end)
    .map((item) => {
      const updatedItem = strechToMinLength(
        item,
        toMilliseconds(minHighlightLength)
      )
      updatedItem.className += ` highlight-duration-${Math.round(toSeconds(+updatedItem.end! - +updatedItem.start!))}`
      return updatedItem
    })

  if (sortBy === 'gain') {
    return mainItemsFiltered.sort((a, b) => b.gain! - a.gain!)
  } else {
    return mainItemsFiltered.sort((a, b) => +b.start + +a.start)
  }
}

const transformTags = (
  tagItems: TimelineItemData[],
  rangeItems: TimelineItemData[]
) => {
  const transformedTagItems: TimelineItemData[] = []

  tagItems.forEach((tagItem) => {
    const tagStartTimestamp = new Date(tagItem.start).getTime()
    const tagEndTimestamp = tagItem.end ? new Date(tagItem.end).getTime() : null

    rangeItems.forEach((rangeItem) => {
      const rangeOriginalStartTimestamp = rangeItem.originalStart
      const rangeOriginalEndTimestamp = rangeItem.originalEnd
      const rangeStartTimestamp = rangeItem.start
      const rangeEndTimestamp = rangeItem.end

      const overlaps = tagEndTimestamp
        ? tagStartTimestamp <= rangeOriginalEndTimestamp! &&
          tagEndTimestamp >= rangeOriginalStartTimestamp!
        : tagStartTimestamp >= rangeOriginalStartTimestamp! &&
          tagStartTimestamp <= rangeOriginalEndTimestamp!

      if (overlaps) {
        const originalSpan =
          rangeOriginalEndTimestamp! - rangeOriginalStartTimestamp!
        const newSpan = +rangeEndTimestamp! - +rangeStartTimestamp!

        if (tagEndTimestamp) {
          const overlapStart = Math.max(
            tagStartTimestamp,
            rangeOriginalStartTimestamp!
          )
          const overlapEnd = Math.min(
            tagEndTimestamp,
            rangeOriginalEndTimestamp!
          )

          const relativeStartPos =
            (overlapStart - rangeOriginalStartTimestamp!) / originalSpan
          const relativeEndPos =
            (overlapEnd - rangeOriginalStartTimestamp!) / originalSpan
          const newStart = new Date(
            +rangeStartTimestamp + newSpan * relativeStartPos
          )
          const newEnd = new Date(
            +rangeStartTimestamp + newSpan * relativeEndPos
          )
          transformedTagItems.push({
            ...tagItem,
            start: newStart,
            end: newEnd,
          })
        } else {
          const relativePosition =
            (tagStartTimestamp - rangeOriginalStartTimestamp!) / originalSpan
          const newStart = new Date(
            +rangeStartTimestamp + newSpan * relativePosition
          )
          transformedTagItems.push({
            ...tagItem,
            start: newStart,
          })
        }
      }
    })
  })

  return transformedTagItems
}

export const applyNonLinearTransformation = (
  itemsDataSet: DataSet<TimelineItemData, 'id'>,
  groupId: number,
  minHighlightLength: number,
  sortBy: SortHighlights
) => {
  const items: TimelineItemData[] = []
  const placeholders: TimelineItemData[] = []
  const tags: TimelineItemData[] = []

  itemsDataSet.forEach((i) => {
    const startDate = new Date(i.start)
    const endDate = i.end && new Date(i.end)
    i.start = startDate.getTime()
    if (endDate) {
      i.end = endDate.getTime()
    }
    if (i.id.toString().includes('placeholder')) {
      placeholders.push(i)
    } else if (i.id.toString().includes('tag')) {
      tags.push(i)
    } else if (i.isItem) {
      items.push(i)
    }
  })

  const mainItems = getMainItems(items, groupId, minHighlightLength, sortBy)

  const otherItems = items.filter((item) => item.group !== groupId)

  const [repositionedItems, distance] = repositionMainItems(mainItems)

  const adjustedOtherItems = moveOtherItems(
    mainItems,
    otherItems,
    distance as number[]
  )

  const tagItems = transformTags(tags, repositionedItems as TimelineItemData[])
  const arrayOfItems = [
    ...placeholders,
    ...repositionedItems,
    ...adjustedOtherItems,
    ...tagItems,
  ].map((item, i) => {
    const index = (item as TimelineItemData).id.toString().includes('tag')
      ? `${i}_${(item as TimelineItemData).id}`
      : i
    return {
      ...(item as TimelineItemData),
      id: index,
    }
  })
  return new DataSet(arrayOfItems)
}
