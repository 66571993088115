import { useProjectData } from '@api/table/projects'
import { usePrevious } from '@common/hooks/usePrevious'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

export function useHandleProjectChange() {
  const { projectID } = useProjectData()
  const prevProjectID = usePrevious(projectID)
  const [, setSearchParams] = useSearchParams()

  useEffect(() => {
    if (prevProjectID && projectID !== prevProjectID) {
      setSearchParams((sp) => {
        sp.delete('page')
        sp.delete('day')
        sp.delete('delegateType')
        sp.delete('dtid')
        sp.delete('frameId')
        sp.delete('daysPage')
        return sp
      })
    }
  }, [prevProjectID, projectID, setSearchParams])
}
