import { Feature } from 'ol'
import { Point } from 'ol/geom'
import { checkMarkerVisibility, createPointStyle, source } from './map'

export const handleMarkerPosition = (pointThing: Point) => {
  const id = 'marker'
  const mapMarker = source.getFeatureById(id)

  if (mapMarker) {
    source.removeFeature(mapMarker)
  }

  const pfeaturething = new Feature({
    name: 'Point',
    obj: id,
    geometry: pointThing,
  })

  pfeaturething.setId(id)
  pfeaturething.setStyle(createPointStyle('O', 'blue', 8))
  source.addFeature(pfeaturething)

  checkMarkerVisibility(pointThing)
}
