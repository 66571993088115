import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useState,
} from 'react'

export interface ReportNavigationContextState {
  dashboardURL: string
}

export interface ReportNavigationContextDispatch {
  setDashboardURL: Dispatch<SetStateAction<string>>
}

export interface ReportNavigationContextType
  extends ReportNavigationContextState,
    ReportNavigationContextDispatch {}

export const ReportNavigationContext = createContext<
  ReportNavigationContextType | undefined
>(undefined)

export function ReportNavigationContextProvider({
  children,
}: PropsWithChildren) {
  const [dashboardURL, setDashboardURL] = useState<string>('')

  const value: ReportNavigationContextType = {
    dashboardURL,
    setDashboardURL,
  }

  return (
    <ReportNavigationContext.Provider value={value}>
      {children}
    </ReportNavigationContext.Provider>
  )
}
