import { TableRouteParams } from '@common/constants/paths'
import { getResponseStatusCode } from '@common/utils/api'
import { useDriveTrialContext } from '@pages/Details/providers/DriveTrialDataProvider'
import { useQueries, useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { camelCase, capitalize } from 'lodash'
import { useParams } from 'react-router-dom'
import { match } from 'ts-pattern'
import { FilterObject } from '../../models/table'
import { getBaseAuthData } from '../base'
import { getDriveTrials } from '../redirectionData'
import { useProjectData } from '../table/projects'
import { urls } from '../urls'
import { ObjectHighlight } from './types'
import { transformFilter } from './utils'
import { ReportType, useReportContext } from '@modules/reportTable'

export interface KpiFilterResponseData {
  name: string
  values: string[]
  isGeneral: number
  multiSelect: number
}

export interface KpiFilterResponse {
  data: KpiFilterResponseData[]
}

const getUrl = (reportType?: ReportType) => {
  switch (reportType) {
    case 'lanes':
      return urls.kpiSummaryLanesHighlights
    case 'ttd':
      return urls.kpiSummaryTTDHighlights
    case 'lights':
      return urls.kpiSummaryLightsHighligts
    case 'signs':
      return urls.kpiSummarySignsHighlights
    default:
      return ''
  }
}

const getLanesPayload = (
  highlightType: string,
  kpi: string,
  filters?: FilterObject
) => {
  const splitData = highlightType.split('-')
  const lane = splitData
    .find((data) => ['EGO', 'Adj'].includes(data))
    ?.replace('Adj', 'Adjacent')

  return {
    ...filters,
    lane,
    side: splitData[1],
    kpi,
  }
}

export const useKpiFiltersQuery = (enabled: boolean) => {
  const { preserveState, setPreserveState } = useReportContext()
  const { reportType } = useParams<TableRouteParams>()

  return useQuery({
    queryKey: ['kpiFilters', reportType],
    enabled,
    queryFn: (): Promise<KpiFilterResponseData[]> =>
      axios
        .get<KpiFilterResponse>(urls.kpiFilters, {
          params: {
            ...getBaseAuthData(),
            project: reportType === 'ttd' ? 'Lanes' : capitalize(reportType),
          },
        })
        .then(({ data }) => {
          const init_filters = {}
          data.data.forEach(
            (x) =>
              ((init_filters as { [key: string]: string })[camelCase(x.name)] =
                '')
          )

          setPreserveState({
            ...preserveState,
            [reportType!]: {
              ...preserveState[reportType!],
              filterKpiToSql: {
                ...init_filters,
                ...preserveState[reportType!].filterKpiToSql,
              },
            },
          })

          return data.data
        }),
  })
}

export const useKpiHighlightsQueries = () => {
  const { reportType } = useParams<TableRouteParams>()
  const { redirectData } = useDriveTrialContext()
  const dtids = getDriveTrials(redirectData)
  const { frameRate } = useProjectData()
  const filters = redirectData?.filters
  const columns = redirectData?.columns

  const queryData: FilterObject[] =
    columns?.flatMap(({ items, kpi }) =>
      items.map((highlight) => {
        const filter = match(reportType)
          .with('ttd', () => getLanesPayload(highlight, kpi, filters))
          .with('lanes', () => getLanesPayload(highlight, kpi, filters))
          .with('lights', () => ({
            ...filters,
            kpi: `${kpi}|${highlight}`,
          }))
          .with('signs', () => ({
            ...filters,
            kpi: kpi.replace('All Speeds', ''),
            metrics: `['${highlight}']`,
            frameRate,
          }))
          .otherwise(() => ({
            ...filters,
            kpi: highlight,
          }))

        return {
          ...transformFilter(filter),
          dtid: `[${dtids.toString()}]`,
          loaderKey: `${kpi}-${highlight}-loader`,
        }
      })
    ) || []

  const queries = useQueries({
    queries: queryData.map(({ loaderKey, ...rest }) => ({
      queryKey: ['kpiHighlights', reportType, rest],
      queryFn: (): Promise<ObjectHighlight[]> =>
        axios.post(getUrl(reportType), rest).then(({ data }) => data),
    })),
  })
  const combinedQueries = queries.map(
    ({ data, isFetched, isError, error }, index) => ({
      kpiData: data,
      isFetched,
      isError,
      loaderKey: queryData[index].loaderKey as string,
      statusCode: getResponseStatusCode(error),
    })
  )

  const isAllFetched = combinedQueries.every((query) => query.isFetched)

  return {
    queries: combinedQueries,
    isKpiFetched: isAllFetched,
  }
}
