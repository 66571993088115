import { useCallback, useContext } from 'react'
import { useTransportContext } from './useTransportContext'
import { MediaSyncContext } from '@pages/Details/types/providers'

export function useHandlePlaybackRate() {
  const mediaSyncContext = useContext(MediaSyncContext)

  const { playbackRate } = useTransportContext()

  return useCallback(
    (velocity: number) => {
      mediaSyncContext.timingObj?.update({
        velocity,
      })

      const videos = document.getElementsByTagName('video')
      for (let i = 0; i < videos.length; i++) {
        videos[i].playbackRate = velocity
      }

      playbackRate.current = velocity
      mediaSyncContext.playbackSpeed = velocity
      mediaSyncContext.isPlaying = true
    },
    [mediaSyncContext, playbackRate]
  )
}
