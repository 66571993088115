import { PROJECT_IDS } from '@common/constants/projects'

const DAYS_PER_PROJECT: { [key: number]: number } = {
  [PROJECT_IDS.MF1]: 93,
  [PROJECT_IDS.VOLVO]: 11,
}

export function getDays(projectID: PROJECT_IDS) {
  return Array.from({ length: DAYS_PER_PROJECT[projectID] }, (_, i) => i + 1)
}
