import { StyledTooltip } from '@common/components/StyledTooltip/StyledTooltip'
import ExploreIcon from '@mui/icons-material/Explore'
import MapIcon from '@mui/icons-material/Map'
import ZoomInIcon from '@mui/icons-material/ZoomIn'
import ZoomOutIcon from '@mui/icons-material/ZoomOut'
import { IconButton } from '@mui/material'
import { RefObject, useState } from 'react'
import { MapRef } from 'react-map-gl'
import { useDispatch } from 'react-redux'
import { MapOptionsRef } from '@modules/mapViewport'
import { viewportMapStyleChange } from 'store/details/viewport/viewportSlice'
import { iconStyles } from '@common/constants/colors'

interface IProps {
  mapRef: RefObject<MapRef>
  viewportId: number
  setFocusMap: React.Dispatch<React.SetStateAction<boolean>>
  mapOptionsRef: RefObject<MapOptionsRef>
}

export const MapTools = ({ mapRef, viewportId, mapOptionsRef }: IProps) => {
  const dispatch = useDispatch()
  const [mapDataTestId, setMapDataTestId] = useState<string>(
    `mapStyle-${
      mapRef.current
        ? mapRef.current.getMap().getStyle.name?.replace(' ', '')
        : 'MapboxSatellite'
    }-${viewportId}`
  )

  const zoomIn = () => {
    if (
      !mapRef.current ||
      !mapOptionsRef.current?.setFollowMode ||
      !mapOptionsRef.current.setShowFollowButton
    ) {
      return
    }
    mapOptionsRef.current.setFollowMode(false)
    mapOptionsRef.current.setShowFollowButton(true)
    mapRef.current.zoomIn()
  }

  const zoomOut = () => {
    if (
      !mapRef.current ||
      !mapOptionsRef.current?.setFollowMode ||
      !mapOptionsRef.current.setShowFollowButton
    ) {
      return
    }

    mapOptionsRef.current.setFollowMode(false)
    mapOptionsRef.current.setShowFollowButton(true)
    mapRef.current.zoomOut()
  }

  const toNorth = () => {
    if (mapRef.current) mapRef.current.resetNorth()
  }

  const changeMapStyle = () => {
    dispatch(
      viewportMapStyleChange({
        id: viewportId,
      })
    )
    if (mapRef.current) {
      const mapCopy = mapRef.current.getMap()
      const mapStyle = mapCopy.getStyle()
      mapCopy.setStyle(
        mapStyle?.name === 'Mapbox Streets'
          ? 'mapbox://styles/mapbox/satellite-v9'
          : 'mapbox://styles/mapbox/streets-v11'
      )
      setMapDataTestId(
        `mapStyle-${mapStyle?.name?.replace(' ', '')}-${viewportId}`
      )
    }
  }

  return (
    <>
      <StyledTooltip title='Zoom in'>
        <IconButton data-testid={`zoomInMap-${viewportId}`} onClick={zoomIn}>
          <ZoomInIcon sx={iconStyles} />
        </IconButton>
      </StyledTooltip>

      <StyledTooltip title='Zoom out'>
        <IconButton data-testid={`zoomOutMap-${viewportId}`} onClick={zoomOut}>
          <ZoomOutIcon sx={iconStyles} />
        </IconButton>
      </StyledTooltip>

      <StyledTooltip title='Return to north'>
        <IconButton
          data-testid={`returnMapToNorth-${viewportId}`}
          onClick={toNorth}
        >
          <ExploreIcon sx={iconStyles} />
        </IconButton>
      </StyledTooltip>

      <StyledTooltip title='Change map style'>
        <IconButton data-testid={mapDataTestId} onClick={changeMapStyle}>
          <MapIcon sx={iconStyles} />
        </IconButton>
      </StyledTooltip>
    </>
  )
}
