import {
  EditJiraRequest,
  useEditJiraMutation,
  useRemoveJiraMutation,
} from '@api/timeline/jira'
import { Loader } from '@common/components/Loader/Loader'
import DeleteIcon from '@mui/icons-material/Delete'
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Popover,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import {
  useTimelineContext,
  JiraTeam,
  jiraTeamIds,
  captureScreenshot,
  findKeyByValue,
  useTags,
} from '@modules/timelineViewport'
import { ChangeEvent, useEffect } from 'react'
import './style.scss'
import {
  formControlSx,
  formHelperTextSx,
  textFieldInputPropsSx,
} from './styles.sx'
import { useTagPopupContext } from '@modules/timelineViewport/hooks/useTagPopupContext'
import { useCreateJiraIssue } from '@modules/timelineViewport/hooks/useCreateJiraIssue'
import { handleError } from '@modules/timelineViewport/utils/handleError'
import { useDriveTrialContext } from '@pages/Details/providers/DriveTrialDataProvider'
import { IdType } from 'otto-vis-timeline/declarations'

export type onEditProps = (
  activeTag: number,
  text: string,
  summary: string,
  issueType?: string,
  team?: string,
  jiraID?: string | undefined
) => void

export type onRemoveProps = (activeTag: number) => void
export type setAnchorElType = React.Dispatch<
  React.SetStateAction<HTMLDivElement | undefined>
>
export type setActiveTagType = React.Dispatch<
  React.SetStateAction<number | undefined>
>

interface IProps {
  onEdit: onEditProps
}

export const TagPopup = ({ onEdit }: IProps) => {
  const timelineContext = useTimelineContext()

  const { removeTag } = useTags(timelineContext.selectedGroup as IdType)

  const {
    text,
    summary,
    disposition,
    issueType,
    jiraIssueID,
    start,
    end,
    isLoading,
    captureScreenshotOnClose,
    team,
    anchorTagPopup,
    activeTag,
    setText,
    setSummary,
    setDisposition,
    setIssueType,
    setJiraIssueID,
    setStart,
    setEnd,
    setCaptureScreenshotOnClose,
    setTeam,
    setAnchorTagPopup,
    setActiveTag,
  } = useTagPopupContext()

  const { highlightMode } = useDriveTrialContext()

  const open = Boolean(anchorTagPopup)

  const { removeJiraMutation } = useRemoveJiraMutation()
  const { editJiraMutation } = useEditJiraMutation()
  const id = open ? 'tag-popover' : undefined

  const closeNotationPopup = () => {
    const { items } = timelineContext

    const item = items.get(`tag-${activeTag}`)

    if (!item?.summary && activeTag) {
      removeTag(activeTag, highlightMode)
    }

    setAnchorTagPopup(undefined)
    setActiveTag(undefined)
    setCaptureScreenshotOnClose(false)
  }

  const handleCreateJiraIssue = useCreateJiraIssue({
    onEdit,
    closeNotationPopup,
  })

  // Handle editing an existing Jira issue
  const handleEditJiraIssue = async () => {
    if (!jiraIssueID) return
    const editJira: EditJiraRequest = {
      issueKey: jiraIssueID,
      description: text,
      summary: summary,
      team: jiraTeamIds[team],
      issueType: issueType,
    }
    try {
      await handleJiraItem(jiraIssueID, editJira)
      if (captureScreenshotOnClose) {
        captureScreenshot(jiraIssueID)
      }
      onEdit(activeTag!, text, summary, issueType, team, jiraIssueID)
    } catch (error) {
      handleError(error)
    } finally {
      closeNotationPopup()
    }
  }

  // Handle Jira item actions
  const handleJiraItem = async (
    jiraIssueID: string,
    editObject?: EditJiraRequest
  ) => {
    try {
      if (!editObject) {
        await removeJiraMutation({ issueKey: jiraIssueID })
      } else {
        await editJiraMutation(editObject)
      }
    } catch (error: unknown) {
      handleError(error)
    }
  }

  // Handle the publish button click
  const handlePublish = async () => {
    if (activeTag) {
      if (disposition === 'jira') {
        if (!jiraIssueID) {
          handleCreateJiraIssue()
        } else {
          handleEditJiraIssue()
        }
      } else {
        await onEdit(activeTag, text, summary, issueType, team)
        closeNotationPopup()
      }
    }
  }

  // Handle the delete button click
  const handleDelete = async () => {
    if (activeTag) {
      removeTag(activeTag, highlightMode)

      if (disposition === 'jira' && jiraIssueID) {
        handleJiraItem(jiraIssueID)
      }
      closeNotationPopup()
    }
  }

  // Handle disposition change
  const handleChangeDisposition = (event: ChangeEvent<HTMLInputElement>) => {
    setDisposition(event.target.value as 'otto' | 'jira')
  }

  const handleChangeJiraIssueType = (event: ChangeEvent<HTMLInputElement>) => {
    setIssueType(event.target.value as 'Bug' | 'Task')
  }

  const handleChangeTeam = (event: ChangeEvent<{ value: JiraTeam }>) => {
    setTeam(event.target.value as JiraTeam)
  }

  useEffect(() => {
    if (open) {
      const foundItem = timelineContext.items.get(`tag-${activeTag}`)
      if (foundItem) {
        setStart(foundItem.start)
        setEnd(foundItem.end!)
        setText(foundItem.title || '')
        setSummary(foundItem.summary || '')
        setIssueType(foundItem.issueType || 'Task')
        setJiraIssueID(foundItem.jiraID || '')
        setDisposition(foundItem.jiraID === undefined ? 'otto' : 'jira')
        setTeam(findKeyByValue(foundItem.team || '10022') as JiraTeam)
      }
    }
  }, [open, activeTag, timelineContext.items])

  return (
    <div className='MuiDialog-root dialog-container MuiModal-root'>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorTagPopup}
        onClose={closeNotationPopup}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {isLoading && (
          <div className='loader-wrapper'>
            <Loader center />
          </div>
        )}
        <Box
          component='div'
          sx={{
            padding: 1,
            backgroundColor: (theme) => theme.palette.primary.main,
          }}
        >
          {end ? (
            <>
              <FormControl sx={formControlSx}>
                <FormLabel sx={{ fontSize: '0.75rem' }} focused={false}>
                  Disposition:
                </FormLabel>
                <RadioGroup
                  value={disposition}
                  onChange={handleChangeDisposition}
                  sx={{ display: 'flex', flexDirection: 'row' }}
                >
                  <FormControlLabel
                    value='otto'
                    disabled={jiraIssueID !== undefined}
                    sx={{ color: disposition === 'otto' ? 'white' : '#8a8a8a' }}
                    control={
                      <Radio
                        size='small'
                        sx={{
                          color: '#8a8a8a',
                          '&.MuiRadio-root.Mui-checked': {
                            color: 'white',
                          },
                        }}
                      />
                    }
                    label='Otto'
                  />
                  <FormControlLabel
                    value='jira'
                    sx={{ color: disposition === 'jira' ? 'white' : '#8a8a8a' }}
                    control={
                      <Radio
                        size='small'
                        sx={{
                          color: '#8a8a8a',
                          '&.MuiRadio-root.Mui-checked': {
                            color: 'white',
                          },
                        }}
                      />
                    }
                    label='Jira'
                  />
                </RadioGroup>
                <span style={{ color: 'whitesmoke' }}>{jiraIssueID}</span>
              </FormControl>
              <div className='mark-container'>
                <Typography fontSize='0.75rem' color='whitesmoke'>
                  Mark in:{' '}
                  <span className='mark-in-mark-out'>{start.toString()}</span>
                </Typography>
                <Typography fontSize='0.75rem' color='whitesmoke'>
                  Mark out:{' '}
                  <span className='mark-in-mark-out'>{end.toString()}</span>
                </Typography>
              </div>
              <Divider
                sx={{ mt: 1, mb: 1, borderColor: 'rgba(255, 255, 255, 0.1)' }}
              />

              <div>
                <div>
                  <FormControl sx={formControlSx}>
                    <RadioGroup
                      value={issueType}
                      onChange={handleChangeJiraIssueType}
                      sx={{ display: 'flex', flexDirection: 'row' }}
                    >
                      <FormControlLabel
                        value='Bug'
                        sx={{ color: 'white' }}
                        control={
                          <Radio
                            size='small'
                            disabled={disposition !== 'jira'}
                            sx={{
                              color: '#8a8a8a',
                              '&.MuiRadio-root.Mui-checked': {
                                color: 'white',
                              },
                            }}
                          />
                        }
                        label='Bug'
                      />
                      <FormControlLabel
                        value='Task'
                        sx={{ color: 'white' }}
                        control={
                          <Radio
                            disabled={disposition !== 'jira'}
                            size='small'
                            sx={{
                              color: '#8a8a8a',
                              '&.MuiRadio-root.Mui-checked': {
                                color:
                                  disposition !== 'jira'
                                    ? 'rgba(0, 0, 0, 0.38)'
                                    : 'white',
                              },
                            }}
                          />
                        }
                        label='Task'
                      />
                    </RadioGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={captureScreenshotOnClose}
                          onChange={(e) =>
                            setCaptureScreenshotOnClose(e.target.checked)
                          }
                          sx={{
                            color: 'white',
                            '&.Mui-checked': {
                              color: 'white',
                            },
                          }}
                          disabled={disposition !== 'jira'}
                        />
                      }
                      label='Add screenshot'
                      sx={{ color: 'white' }}
                    />
                  </FormControl>
                </div>

                <FormControl
                  disabled={disposition !== 'jira'}
                  required={disposition !== 'jira' ? false : true}
                  sx={{
                    marginTop: 1,
                    width: '100%',
                    color: 'white',
                    '.MuiSelect-select': { color: 'white' },
                    '.MuiInputLabel-root': {
                      color:
                        disposition !== 'jira'
                          ? 'rgba(0, 0, 0, 0.38)'
                          : 'rgba(255, 255, 255)',
                    },
                    '.MuiOutlinedInput-notchedOutline': {
                      borderColor: 'rgba(255, 255, 255, 0.5)',
                    },
                  }}
                >
                  <InputLabel>Team</InputLabel>
                  <Select
                    label='Team'
                    value={team}
                    onChange={(e: any) => handleChangeTeam(e)}
                    required
                  >
                    {Object.entries(jiraTeamIds).map(([key, value]) => (
                      <MenuItem key={value} value={key}>
                        {key}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <Divider
                sx={{ mt: 1, mb: 1, borderColor: 'rgba(255, 255, 255, 0.1)' }}
              />
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <div className='textFieldContainerSx'>
                  <TextField
                    multiline
                    required={true}
                    maxRows={4}
                    onFocus={() => timelineContext.setInputInFocus(true)}
                    onBlur={() => timelineContext.setInputInFocus(false)}
                    inputProps={{ maxLength: 256 }}
                    InputProps={{
                      readOnly: false,
                      sx: textFieldInputPropsSx,
                    }}
                    onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                      setSummary(e.target.value)
                    }}
                    value={summary}
                    label='Summary'
                    variant='outlined'
                  />
                  <FormHelperText sx={formHelperTextSx}>
                    Characters left: {Math.abs(summary.length - 256)}
                  </FormHelperText>
                </div>
              </div>
            </>
          ) : null}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginTop: end ? '' : '8px',
            }}
          >
            <div className='textFieldContainerSx'>
              <TextField
                placeholder='Description...'
                multiline
                maxRows={4}
                onFocus={() => timelineContext.setInputInFocus(true)}
                onBlur={() => timelineContext.setInputInFocus(false)}
                inputProps={{ maxLength: 256 }}
                InputProps={{
                  readOnly: false,
                  sx: textFieldInputPropsSx,
                }}
                onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                  setText(e.target.value)
                }}
                value={text}
                label='Description'
                variant='outlined'
              />
              <FormHelperText sx={formHelperTextSx}>
                Characters left: {Math.abs(text.length - 256)}
              </FormHelperText>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <IconButton
              style={{
                marginTop: '12px',
              }}
              onClick={handleDelete}
            >
              <DeleteIcon fontSize='medium' color='error' />
            </IconButton>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '1rem',
              }}
            >
              <Button
                onClick={closeNotationPopup}
                color='secondary'
                variant='outlined'
                sx={{ marginRight: 1 }}
              >
                Cancel
              </Button>
              <Button
                onClick={handlePublish}
                color='primary'
                variant='contained'
                disabled={
                  isLoading ||
                  (end && !summary) ||
                  (disposition === 'jira' && !team)
                }
              >
                Publish
              </Button>
            </div>
          </div>
        </Box>
      </Popover>
    </div>
  )
}
