import { useEditTagMutation } from '@api/index'
import { useCreateJiraMutation } from '@api/timeline/jira'
import { getProjectID } from '@common/utils/storage'
import { useCallback } from 'react'
import { useTagPopupContext } from '@modules/timelineViewport/hooks/useTagPopupContext'
import { jiraTeamIds } from '../types/jira'
import { toSeconds } from '@common/utils/time'
import { jiraStatusUpdate } from '../utils/timeline'
import { captureScreenshot } from '../utils/tags'
import { onEditProps } from '../containers/TagPopup'
import { useTimelineContext } from './useTimelineContext'
import { handleError } from '../utils/handleError'

export function useCreateJiraIssue({
  onEdit,
  closeNotationPopup,
}: {
  onEdit: onEditProps
  closeNotationPopup: () => void
}) {
  const projectID = getProjectID()
  const timelineContext = useTimelineContext()

  const { createJiraMutation } = useCreateJiraMutation()
  const { editTagMutation } = useEditTagMutation()

  const {
    text,
    summary,
    issueType,
    start,
    captureScreenshotOnClose,
    team,
    jiraProjectID,
    activeTag,
    setJiraIssueID,
    setIsLoading,
  } = useTagPopupContext()

  return useCallback(async () => {
    if (!jiraProjectID) return

    const taskData = {
      projectKey: jiraProjectID,
      summary: summary,
      description: text,
      issueType: issueType!,
      team: jiraTeamIds[team],
      webLink: `${
        window.location.href.split('&')[0]
      }&jira=true&time=${toSeconds(+start)}&projectID=${projectID}`,
    }

    setIsLoading(true)

    try {
      const jiraResponse = await createJiraMutation(taskData)

      if (jiraResponse && jiraResponse.status === 201) {
        const jiraID = jiraResponse.data.issue_key
        setJiraIssueID(jiraID)
        setIsLoading(false)
        jiraStatusUpdate(jiraID, timelineContext, activeTag!, editTagMutation)
        if (captureScreenshotOnClose) {
          captureScreenshot(jiraID)
        }
        await onEdit(activeTag!, text, summary, issueType, team, jiraID)
      } else {
        setIsLoading(false)
      }
    } catch (error) {
      handleError(error)
      setIsLoading(false)
    } finally {
      closeNotationPopup()
    }
  }, [
    activeTag,
    captureScreenshotOnClose,
    closeNotationPopup,
    createJiraMutation,
    editTagMutation,
    issueType,
    jiraProjectID,
    onEdit,
    projectID,
    setIsLoading,
    setJiraIssueID,
    start,
    summary,
    team,
    text,
    timelineContext,
  ])
}
