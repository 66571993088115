import { isDevelopmentOrLocal } from '@common/constants/env'
import { useDriveTrialContext } from '@pages/Details/providers/DriveTrialDataProvider'
import { MediaSyncContext } from '@pages/Details/types/providers'
import { useCallback, useContext } from 'react'
import { useMapContext } from './useMapContext'

export function useRenderDevCoordinates() {
  const mediaSyncContext = useContext(MediaSyncContext)
  const { getDriveTrialById, highlightMode } = useDriveTrialContext()
  const { gpsHighMap, driveGps, coordinatesRef, markerRef, mapRef } =
    useMapContext()

  return useCallback(
    (time: number) => {
      if (!coordinatesRef.current || !isDevelopmentOrLocal) return

      if (highlightMode.id !== -1) {
        const cords = gpsHighMap[Math.floor(time)]
        if (cords && markerRef.current && mapRef.current) {
          coordinatesRef.current.innerHTML = `${cords[1]}, ${cords[0]}`
        }
        return
      }

      const activeVideoDTID = mediaSyncContext.activeVideo
      const activeGps = driveGps.find((x) => x.key === activeVideoDTID)
      const activeVideoID = mediaSyncContext.activeVideoId
      const activeVideo = getDriveTrialById(activeVideoID!)

      if (activeGps && activeVideo) {
        const offset = activeVideo.previousDuration
        const seconds = Math.floor(time) - offset
        const [long, lat] = activeGps.coordinates[seconds]
        coordinatesRef.current.innerHTML = `${lat}, ${long}`
      }
    },
    [
      coordinatesRef,
      driveGps,
      getDriveTrialById,
      gpsHighMap,
      highlightMode.id,
      mapRef,
      markerRef,
      mediaSyncContext.activeVideo,
      mediaSyncContext.activeVideoId,
    ]
  )
}
