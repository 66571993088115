import { useContext } from 'react'
import {
  TransportContext,
  TransportContextType,
} from '../context/TransportContext'

export function useTransportContext(): TransportContextType {
  const context = useContext(TransportContext)

  if (!context) {
    throw new Error(
      'useTransportContext must be used within a TransportContextProvider'
    )
  }

  return context
}
