import { useAdminLevel } from '@api/table/adminLevel'
import {
  getEmail,
  getStorageAdminLevelID,
  setStorageAdminLevelID,
} from '@common/utils/storage'
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { getSource, getToken, IdentityProvider } from '../utils/storage'

export type SetAuthContextProps = {
  token?: string
  source?: IdentityProvider
  email?: string
}

interface AuthContextProps extends SetAuthContextProps {
  isAuthenticated: boolean
  setAuthContext: (args: SetAuthContextProps) => void
  adminLevelID?: number
}

const initialValue = {
  isAuthenticated: false,
  setAuthContext: () => {},
}

const AuthContext = createContext<AuthContextProps>(initialValue)
const useAuthContext = () => useContext(AuthContext)

const AuthProvider = ({ children }: PropsWithChildren) => {
  const [token, setToken] = useState<string | undefined>(() => getToken())
  const [email, setEmail] = useState<string | undefined>(() => getEmail())
  const [source, setSource] = useState<IdentityProvider | undefined>(() =>
    getSource()
  )
  const [adminLevelID, setAdminLevelID] = useState<number | undefined>(() =>
    getStorageAdminLevelID()
  )
  const sourceURL = window.location.href

  const { data: adminLevel, refetch } = useAdminLevel()

  useEffect(() => {
    if (adminLevel) {
      setAdminLevelID(adminLevel.levelID)
      setStorageAdminLevelID(adminLevel.levelID ?? 0)
    }
  }, [adminLevel])

  if (sourceURL.includes('external=True') && !token) {
    window.localStorage.setItem('externalURL', sourceURL)
    const url = new URL(sourceURL)
    url.searchParams.delete('external')
    const newURL = url.toString()
    window.history.replaceState(null, '', newURL)
  }

  const setAuthContext = useCallback(
    ({ token, source, email }: SetAuthContextProps) => {
      setToken(token)
      setSource(source)
      setEmail(email)

      if (email) {
        refetch()
      } else {
        setAdminLevelID(0)
        setStorageAdminLevelID(0)
      }
    },
    [refetch]
  )

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: Boolean(token && source && email),
        email,
        token,
        source,
        adminLevelID,
        setAuthContext,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export { AuthProvider, useAuthContext }
