import { useDriveTrialContext } from '@pages/Details/providers/DriveTrialDataProvider'
import { MediaSyncContext } from '@pages/Details/types/providers'
import { useCallback, useContext } from 'react'
import { useTransportContext } from './useTransportContext'
import { secondsToTimeString, toSeconds } from '@common/utils/time'
import { TimingProgress } from '@common/services/timing/timing'
import { useProjectData } from '@api/table/projects'

export function useHandleTimeChange() {
  const mediaSyncContext = useContext(MediaSyncContext)

  const { getCurrentDriveTrial, highlightMode } = useDriveTrialContext()

  const {
    activeTrackRef,
    durationRef,
    playRef,
    frameRef,
    sliderRef,
    handleMouseDownRef,
  } = useTransportContext()

  const { frameRate } = useProjectData()

  const setDurationVal = useCallback(
    (currentTime: number) => {
      if (
        durationRef.current &&
        mediaSyncContext !== undefined &&
        mediaSyncContext.activeVideo
      ) {
        durationRef.current.innerText = secondsToTimeString(currentTime)
      }
    },
    [durationRef, mediaSyncContext]
  )

  return useCallback(
    (time: number) => {
      if (mediaSyncContext.totalDuration) {
        if (sliderRef.current && !handleMouseDownRef.current?.isMouseDown) {
          sliderRef.current.value = TimingProgress.position2percent(time, [
            0,
            mediaSyncContext.totalDuration,
          ]).toString()
        }

        if (activeTrackRef.current?.style) {
          activeTrackRef.current.style.width = `${
            (time / mediaSyncContext.totalDuration) * 100
          }%`
        }

        if (frameRef.current) {
          const currentVideo = getCurrentDriveTrial(time)

          if (
            !mediaSyncContext ||
            !mediaSyncContext.activeVideo ||
            !currentVideo
          ) {
            return
          }
          let frame = 0
          const frameOffset = Math.floor(
            currentVideo.previousDuration * frameRate
          )
          if (highlightMode.id === -1) {
            frame = Math.floor(
              (time + currentVideo.originalStartTime) * frameRate
            )
          } else {
            highlightMode.items
              .get({ returnType: 'Array' })
              .filter(
                (i) =>
                  i.className !== 'exclusion-background' &&
                  !i.id.toString().includes('tag') &&
                  i.group === highlightMode.id &&
                  i.originalStart !== undefined
              )
              .forEach((item) => {
                const start = toSeconds(new Date(item.start).getTime())
                const end = toSeconds(new Date(item.end!).getTime())
                const originalStart = toSeconds(
                  new Date(item.originalStart!).getTime()
                )
                if (start <= time && end >= time) {
                  frame = Math.floor((time - start + originalStart) * frameRate)
                }
              })
          }
          frameRef.current.value = Math.floor(frame - frameOffset).toString()
        }
        if (time === mediaSyncContext.totalDuration && playRef.current) {
          playRef.current?.setPlaying(false)
          mediaSyncContext.isPlaying = false
        }

        if (!mediaSyncContext.isPlaying) {
          playRef.current?.setPlaying(false)
        } else {
          playRef.current?.setPlaying(true)
        }

        setDurationVal(time)
      }
    },
    [
      activeTrackRef,
      frameRate,
      frameRef,
      getCurrentDriveTrial,
      handleMouseDownRef,
      highlightMode.id,
      highlightMode.items,
      mediaSyncContext,
      playRef,
      setDurationVal,
      sliderRef,
    ]
  )
}
