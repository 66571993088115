import {
  Box,
  FormControl,
  FormHelperText,
  SelectChangeEvent,
  TextField,
} from '@mui/material'
import { camelCase } from 'lodash'
import { Select } from '@common/components/Inputs/Select/Select'
import { MaskedInput } from '@common/components/MaskedInput/MaskedInput'
import { FilterProps } from '@modules/sidebarToolbox/types/sideFilters'
import { cameraOptions } from '@modules/sidebarToolbox/constants/filters'
import { ObjectKeyType } from '@modules/reportTable'

const filterObjectList = [
  'car',
  'truck',
  'bus',
  'motorcycle',
  'pedestrian',
  'cycle',
  'staticObjects',
  'movingObjects',
]

export const inPercentages = [
  'car',
  'truck',
  'bus',
  'motorcycle',
  'pedestrian',
  'cycle',
]

export const ObjectSelectors = ({ filters, onChange }: FilterProps) => {
  const handleChange = (value: string, name: string) =>
    onChange({
      ...filters,
      objectFilters: {
        ...filters.objectFilters,
        [name]: value,
      },
    })

  const handleCameraChange = (value: string, fieldName: string) => {
    onChange({
      ...filters,
      objectFilters: { ...filters.objectFilters, [fieldName]: value },
    })
  }

  return (
    <>
      <Box
        component='div'
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          columnGap: 4,
          rowGap: 2,
        }}
      >
        {filterObjectList.map((x) => {
          const dataTestId = `filter-objects-${camelCase(x)}`
          return (
            <FormControl key={x} variant='standard'>
              {inPercentages.includes(x) ? (
                <MaskedInput
                  mask={/^[1-9][0-9]?$|^100$/}
                  name={x}
                  value={filters.objectFilters[x as ObjectKeyType] ?? ''}
                  onChange={handleChange}
                  dataTestId={dataTestId}
                />
              ) : (
                <TextField
                  type='number'
                  inputProps={{
                    style: { color: 'whitesmoke' },
                    'data-testid': dataTestId,
                  }}
                  value={filters.objectFilters[x as ObjectKeyType] ?? ''}
                  variant='standard'
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleChange(event.target.value, x)
                  }}
                />
              )}
              <FormHelperText
                id={`standard-${x}-helper-text`}
                sx={{
                  color: '#d1d1d1',
                  textTransform: 'capitalize',
                }}
              >
                {x}
              </FormHelperText>
            </FormControl>
          )
        })}
      </Box>
      <FormControl fullWidth sx={{ mt: 4 }}>
        <Select
          variant='filled'
          title='camera'
          label='Camera'
          styledFirstChild
          value={filters.objectFilters?.camera}
          onChange={(e: SelectChangeEvent) =>
            handleCameraChange(e.target.value, 'camera')
          }
          options={[{ value: '', text: 'Reset' }, ...cameraOptions]}
        />
      </FormControl>
    </>
  )
}
