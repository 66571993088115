export const urls = {
  files: '/api/s3/files/<url>',
  reportsSummaryClause: '/api/reports_summary',
  reportsSummaryFiltered: '/api/reports_summary_filtered',
  reportsClause: '/api/reports_clause',
  reportsFiltered: '/api/reports',
  driveTrialHighlights: '/api/drive_trials/:id/highlights',
  kpiFilters: '/api/kpi_filters',
  kpiSummaryLanes: '/api/kpi_summary_lanes',
  kpiSummaryTTD: '/api/kpi_summary_ttd',
  kpiSummaryLights: '/api/kpi_summary_lights',
  kpiSummarySigns: '/api/kpi_summary_signs',
  kpiSummaryLightsHighligts: '/api/kpi_summary_lights_highlights',
  kpiSummaryLanesHighlights: '/api/kpi_summary_lanes_highlights',
  kpiSummarySignsHighlights: '/api/kpi_summary_signs_highlights',
  kpiSummaryTTDHighlights: '/api/kpi_summary_ttd_highlights',
  roadLanes: '/api/road_lanes',
  trafficSigns: '/api/traffic_signs',
  objects: '/api/objects_stream',
  trafficLights: '/api/layers/traffic_lights',
  ttd: 'api/ttd',
  canvasStream: '/api/canvas_stream',
  userAudit: '/api/user_audit',
  mapRoutes: '/api/drive_trial_path',
  driveTrials: '/api/drive_trial',
  driveTrialEgo: '/api/drive_trials/:id/drive_trial_ego',
  driveTrialDescriptions: '/api/drive_trial_descriptions',
  reportLog: '/api/report_log',
  reportLogExit: '/api/report_log_exit',
  reportLogFilter: '/api/report_log_filter',
  signIn: '/sign_in',
  refreshToken: '/refresh_tokens',
  getTimelineTags: '/api/drive_trials/:id/tags',
  deleteTimelineTags: '/api/drive_trials/tags/:id',
  editDriveTrial: '/api/drive_trials/edit',
  clientViewportMenuOptions: '/api/viewports',
  dashboards: '/api/dashboards',
  tableau: '/api/tableau',
  redirectionData: '/api/redirection_data',
  reportHeaders: '/api/report_headers',
  hilVersions: '/drive_trials/versions',
  projects: '/projects',
  adminLevel: '/admin_level',
  changelog: '/api/changelog',
  exclusions: '/api/exclusions',
  reportHeaderTooltips: '/api/report_headers_tooltips',
  jira: '/api/jira',
  jiraSubscribe: '/api/subscribe/:issueID',
  captureScreenshot: '/api/upload_screenshot/:issueID',
  sprGallery: '/api/s3/spr_frames',
  nlGallery: '/api/s3/nl_gallery',
  logoutInfo: '/logout_info',
}

export const createVideoPath = (dtid: number, videoName: string) => {
  return `${dtid}/${videoName}`
}
