import { useHeadersQuery } from '@api/headers'
import { SendToDetailsData } from '@api/redirectionData'
import { HeaderTooltips, useHeaderTooltips } from '@api/reportHeaderTooltips'
import { SparklineVariant } from '@common/components/Sparkline/Sparkline'
import { getProjectID } from '@common/utils/storage'
import {
  Column,
  ColumnPinningState,
  RowSelectionState,
} from '@tanstack/react-table'
import {
  Dispatch,
  PropsWithChildren,
  RefObject,
  SetStateAction,
  createContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import {
  INIT_BREADCRUMBS,
  INIT_PINNING_STATE,
} from '../constants/initialStates'
import {
  BreadcrumbItem,
  HandleQueryBuilder,
  PerserveState,
  ReportDepthRules,
  SelectedRow,
  TableHeaders,
} from '../types'
import { usePreservedState } from '../hooks/usePreservedState'
import { usePrevious } from '@common/hooks/usePrevious'

export const ReportDepths: ReportDepthRules = {
  completeness: 0,
  quality: 2,
  characterization: 2,
  lanes: 0,
  signs: 0,
  lights: 0,
  ttd: 0,
}

export interface ReportContextProps {
  projectID: string
  sendToDetailsData: SendToDetailsData | undefined
  queryBuilderRef: RefObject<HandleQueryBuilder | undefined>
  preserveState: PerserveState
  allColumns: Column<any, any>[]
  columnPinning: ColumnPinningState
  searchColumns: string[]
  selectedRowIds: Set<SelectedRow>
  page: number
  limit: number
  applyKpiFilters: boolean
  displayModeColumns: {
    [key: string]: SparklineVariant
  }
  breadcrumbs: BreadcrumbItem[]
  isLoadingHeaders: boolean
  headers: TableHeaders | undefined
  setBreadcrumbs: Dispatch<SetStateAction<BreadcrumbItem[]>>
  handleBreadcrumbClick: (index: number) => void
  setDisplayModeColumns: Dispatch<
    SetStateAction<{
      [key: string]: SparklineVariant
    }>
  >
  hilVersion: string
  setPreserveState: Dispatch<SetStateAction<PerserveState>>
  setAllColumns: Dispatch<SetStateAction<Column<any, any>[]>>
  setColumnPinning: Dispatch<SetStateAction<ColumnPinningState>>
  setSearchColumns: Dispatch<SetStateAction<string[]>>
  setPage: Dispatch<SetStateAction<number>>
  setLimit: Dispatch<SetStateAction<number>>
  setApplyKpiFilters: Dispatch<SetStateAction<boolean>>
  setSelectedRowIds: Dispatch<SetStateAction<Set<SelectedRow>>>
  setHilVersion: Dispatch<SetStateAction<string>>
  headerTooltips: HeaderTooltips | undefined
  setSendToDetailsData: Dispatch<SetStateAction<SendToDetailsData | undefined>>
  setProjectID: Dispatch<SetStateAction<string>>
  publishCounter: number
  setPublishCounter: Dispatch<SetStateAction<number>>
  setRowSelection: Dispatch<SetStateAction<RowSelectionState>>
  rowSelection: RowSelectionState
}

export const ReportContext = createContext<ReportContextProps>(
  {} as ReportContextProps
)

export function TableContextProvider({ children }: PropsWithChildren) {
  const [publishCounter, setPublishCounter] = useState<number>(0)
  const [projectID, setProjectID] = useState<string>(getProjectID() || '')
  const [sendToDetailsData, setSendToDetailsData] =
    useState<SendToDetailsData>()
  const { preserveState, initialState, setPreserveState } = usePreservedState()
  const [allColumns, setAllColumns] = useState<Column<any, any>[]>([])
  const [columnPinning, setColumnPinning] =
    useState<ColumnPinningState>(INIT_PINNING_STATE)
  const [selectedRowIds, setSelectedRowIds] = useState<Set<SelectedRow>>(
    new Set()
  )
  const [searchColumns, setSearchColumns] = useState<string[]>([])
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(50)
  const [applyKpiFilters, setApplyKpiFilters] = useState(false)
  const [displayModeColumns, setDisplayModeColumns] = useState<{
    [key: string]: SparklineVariant
  }>({})
  const [breadcrumbs, setBreadcrumbs] =
    useState<BreadcrumbItem[]>(INIT_BREADCRUMBS)
  const queryBuilderRef = useRef<HandleQueryBuilder>()
  const [hilVersion, setHilVersion] = useState(
    localStorage.getItem('hil') || 'all'
  )
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({})
  const prevProjectID = usePrevious(projectID)

  const { data: headers, isLoading: isLoadingHeaders } = useHeadersQuery()
  const { data: headerTooltips } = useHeaderTooltips()

  const handleBreadcrumbClick = (index: number) => {
    const crumbs = breadcrumbs.slice(0, index + 1)
    setBreadcrumbs(crumbs)
    setPage(1)
  }

  useEffect(() => {
    setRowSelection({})
    setSelectedRowIds(new Set())

    if (prevProjectID && projectID !== prevProjectID) {
      setPreserveState(initialState)
    }
  }, [initialState, prevProjectID, projectID, setPreserveState])

  return (
    <ReportContext.Provider
      value={{
        publishCounter,
        projectID,
        sendToDetailsData,
        selectedRowIds,
        queryBuilderRef,
        preserveState,
        allColumns,
        columnPinning,
        searchColumns,
        page,
        limit,
        applyKpiFilters,
        displayModeColumns,
        breadcrumbs,
        headers,
        isLoadingHeaders,
        handleBreadcrumbClick,
        setBreadcrumbs,
        setDisplayModeColumns,
        setPreserveState,
        setAllColumns,
        setColumnPinning,
        setSearchColumns,
        setPage,
        setLimit,
        setApplyKpiFilters,
        setSelectedRowIds,
        hilVersion,
        setHilVersion,
        headerTooltips,
        setSendToDetailsData,
        setProjectID,
        setPublishCounter,
        rowSelection,
        setRowSelection,
      }}
    >
      {children}
    </ReportContext.Provider>
  )
}
