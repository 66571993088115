import { ReportType } from '@modules/reportTable'

export type TableRouteParams = {
  project: ProjectValues
  reportType: ReportType
}

export const Routes = {
  INDEX: '/',
  APP: 'app',
  LANDING: 'landing',
  DASHBOARD: 'dashboards',
  LANDING_PAGE: '/app/landing',
  SPR_GALLERY: 'spr-gallery',
  NL_GALLERY: 'nl-gallery',

  REPORTING: 'reporting',

  NOT_FOUND: '*',
  DETAILS: 'details',
  KPI_DETAILS: 'kpi-details',
  ERROR: 'error',
}

export const URLS = {
  KPI_SUMMARY_SENSOR: 'kpi-sensor',
  KPI_SUMMARY_SYSTEM: 'kpi-system',
  DRIVE_TRIAL: 'drive-trials',
}

export const ReportTypes = {
  COMPLETENESS: 'completeness',
  QUALITY: 'quality',
  CHARACTERIZATION: 'characterization',
  KPI_EVAL: 'kpi-evaluations',
  PIPELINE_STATUS: 'pipeline-status',
}

export const Projects = {
  LIGHTS: 'Lights',
  SIGNS: 'Signs',
  LANES: 'Lanes',
  TTD: 'TTD',
  ADJACENT_LEFT: 'AdjacentLeft',
  ADJACENT_RIGHT: 'AdjacentRight',
  EGO_LEFT: 'EGOLeft',
  EGO_RIGHT: 'EGORight',
} as const

export type ProjectKeys = keyof typeof Projects
export type ProjectValues = (typeof Projects)[ProjectKeys]

export const supportEmail = 'support@ottometric.com'
