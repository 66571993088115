import { useCallback, useContext } from 'react'
import { useTransportContext } from './useTransportContext'
import { MediaSyncContext } from '@pages/Details/types/providers'

export function useHandleJogMovement() {
  const mediaSyncContext = useContext(MediaSyncContext)

  const { playRef, playbackRate } = useTransportContext()

  return useCallback(
    (velocity: number) => {
      if (velocity !== 0) {
        mediaSyncContext.timingObj?.update({
          velocity,
        })
        mediaSyncContext.playbackSpeed = velocity
        mediaSyncContext.isPlaying = true
        playRef.current?.setPlaying(true)
        return
      }

      mediaSyncContext.timingObj?.update({
        velocity: 0,
      })
      mediaSyncContext.playbackSpeed = playbackRate.current
      mediaSyncContext.isPlaying = false
      playRef.current?.setPlaying(false)
    },
    [mediaSyncContext, playRef, playbackRate]
  )
}
