import { DriveTrialsResp, useDriveTrialsQuery } from '@api/index'
import {
  DriveTrialDataRedirect,
  getDriveTrials,
  useRedirectData,
} from '@api/redirectionData'
import { useProject, useProjectData } from '@api/table/projects'
import { enUS } from '@common/constants/messages'
import {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { DataSet } from 'vis-data'
import {
  DriveTrial,
  DriveTrialContext,
  MediaSyncContext,
  SamplerReference,
  SynchronizerReference,
  TimingSamplerInterface,
} from '../types/providers'
import { HighlightMode } from '../../../modules/timelineViewport/context/TimelineContextProvider'

import { REASON_ENUM, useCreateLogoutInfoMutation } from '@api/logout'
import { useQueryParams } from '@common/hooks/useQueryParams'
import {
  SkewConverter,
  TimingObject,
  TimingSampler,
} from '@common/services/timing/timing'
import { Loader } from '@common/components/Loader/Loader'
import { getEmail } from '@common/utils/storage'
import { toSeconds } from '@common/utils/time'
import StatusPage from '@pages/StatusPage/StatusPage'
import { useLogout } from '@modules/auth'

function formHighlightTrials(
  driveTrials: DriveTrial[],
  highlightMode: HighlightMode
): DriveTrial[] {
  return highlightMode.items
    .get()
    .filter(
      (highlight) =>
        highlight.selectable && highlight.group === highlightMode.id
    )
    .map((highlight, i) => {
      const dt = driveTrials.find(
        (driveTrial) => driveTrial.DTID === highlight.dtid
      )!

      const startInSeconds = toSeconds(highlight.start as number)
      const endInSeconds = toSeconds(highlight.end as number)

      return {
        id: i,
        DTID: highlight.dtid,
        parentDTID: dt.parentDTID,
        description: dt.description,
        startDate: dt.startDate,
        endDate: dt.endDate,
        startTime: startInSeconds,
        endTime: endInSeconds,
        duration: endInSeconds - startInSeconds,
        cumulativeDuration: endInSeconds,
        previousDuration: dt.previousDuration,
        originalStartTime: toSeconds(highlight.originalStart!),
        dtidDuration: dt.duration,
      } as DriveTrial
    })
}

const formDriveTrialData = (
  data: DriveTrialsResp,
  rowData: DriveTrialDataRedirect[]
): DriveTrial[] =>
  Object.keys(data)
    .map((key, i) => {
      const driveKey = parseInt(key)
      const DTID = driveKey
      const value = data[driveKey]
      const redirectData = rowData.find((x) => x.DTID === driveKey)!
      const parentDTID = redirectData?.parentDTID

      return {
        ...value,
        id: i,
        DTID,
        parentDTID,
        version: redirectData?.version,
        startTime: value.cumulativeDuration - value.duration,
        endTime: value.cumulativeDuration,
        previousDuration: value.previousDuration,
        originalStartTime: 0,
      } as DriveTrial
    })
    .sort((a, b) => a.startTime - b.startTime)
    .map((trial, i) => ({
      ...trial,
      id: i,
    }))

export const useDriveTrialContext = () => useContext(DriveTrialContext)

function DriveTrialDataProvider({ children }: PropsWithChildren) {
  const queryParams = useQueryParams()
  const { frameRate } = useProjectData()
  const { data: projectData } = useProject()
  const logout = useLogout(REASON_ENUM.PROJECT_NOT_AUTHORIZED)
  const guid = queryParams.get('guid')
  const mediaSyncContext = useContext(MediaSyncContext)
  const synchronizerRef = useRef<SynchronizerReference>(null)
  const [highlightMode, setHighlightMode] = useState<HighlightMode>({
    id: -1,
    items: new DataSet([]),
  })
  const email = getEmail()
  const { createLogoutInfoMutation } = useCreateLogoutInfoMutation()

  const isHighlightMode = highlightMode.id !== -1

  const modeKey = `${mediaSyncContext.totalDuration}-${highlightMode.id}-${highlightMode.sortBy}`

  const {
    data: redirectData,
    isError: isRedDataError,
    isLoading: isLoadingRedData,
  } = useRedirectData(guid ?? '')

  const trials = getDriveTrials(redirectData)
  const projectID = queryParams.get('projectID')
  const isFromJira = queryParams.get('jira')
  const hasProject =
    projectID &&
    projectData &&
    projectData.some((project) => project.projectID === +projectID)

  const hasRedirectData =
    !isRedDataError && redirectData && Object.keys(redirectData).length > 0

  const {
    data: dataTrials,
    isError: isTrialsError,
    isLoading: isLoadingTrials,
  } = useDriveTrialsQuery({
    queryData: trials.toString(),
    enabled: !isLoadingRedData && hasRedirectData,
  })

  const dataResults = dataTrials
  const driveTrials = dataResults
    ? formDriveTrialData(dataResults, redirectData?.rows ?? [])
    : []
  const hightlightedDriveTrials = isHighlightMode
    ? formHighlightTrials(driveTrials, highlightMode)
    : null

  const data = hightlightedDriveTrials ? hightlightedDriveTrials : driveTrials
  const keys = trials
  const cumulativeDuration = Math.max(
    ...Object.values(data).map((d) => d.cumulativeDuration as number)
  )
  const timingObject = new TimingObject({
    range: [0, cumulativeDuration],
    velocity: 0,
  })
  const timingSampler = new TimingSampler(timingObject, {
    period: frameRate,
  }) as TimingSamplerInterface
  const initialSkew =
    (data.at(0)?.originalStartTime || 0) - (data.at(0)?.previousDuration || 0)
  const delay = new SkewConverter(timingObject, initialSkew)

  // set `mediaSyncContext` context state
  mediaSyncContext.activeVideo = keys.length > 0 ? keys[0] : undefined
  mediaSyncContext.activeVideoId = data[0]?.id ?? 1
  mediaSyncContext.timingObj = timingObject
  mediaSyncContext.sampler = timingSampler
  mediaSyncContext.delay = delay
  mediaSyncContext.totalDuration = cumulativeDuration

  const getCurrentDriveTrial = useCallback(
    (timestamp: number): DriveTrial | undefined =>
      data.find((x) => timestamp >= x.startTime && timestamp <= x.endTime),
    [data]
  )

  const getDriveTrialByParentDTID = useCallback(
    (key: number): DriveTrial | undefined =>
      data.find((x) => x.parentDTID === key),
    [data]
  )

  const getDriveTrialByKey = useCallback(
    (key: number): DriveTrial | undefined => data.find((x) => x.DTID === key),
    [data]
  )

  const getDriveTrialById = useCallback(
    (id: number | undefined): DriveTrial | undefined =>
      data.find((x) => {
        return x.id === id
      }),
    [data]
  )

  const isConcatenation = useCallback((): boolean => data?.length > 1, [data])

  const handleJiraActions = () => {
    const sourceURL = window.location.href
    window.localStorage.removeItem('jiraURL')
    const url = new URL(sourceURL)
    url.searchParams.delete('jira')
    const newURL = url.toString()
    window.history.replaceState(null, '', newURL)
  }

  useEffect(() => {
    if (hasProject === false) {
      if (isFromJira) {
        handleJiraActions()
      }
      logout()
    }
    if (hasProject === true) {
      if (isFromJira) {
        window.localStorage.setItem('projectID', projectID)
        handleJiraActions()
        createLogoutInfoMutation({
          email: email,
          reason: REASON_ENUM.JIRA_LOGIN,
        })
      }
    }
  }, [hasProject])

  useEffect(() => {
    let samplerEvent: SamplerReference | null = null
    if (mediaSyncContext.sampler !== undefined) {
      samplerEvent = mediaSyncContext.sampler.on('change', (time: number) => {
        const activeTrial = getCurrentDriveTrial(time)
        if (activeTrial && mediaSyncContext.activeVideoId !== activeTrial?.id) {
          mediaSyncContext.activeVideo = activeTrial.parentDTID
          mediaSyncContext.activeVideoId = activeTrial.id
          mediaSyncContext.isSeeking = true
          synchronizerRef.current?.pauseVideos()
          if (mediaSyncContext.delay) {
            const newSkew =
              -activeTrial.startTime +
              activeTrial.originalStartTime -
              (isHighlightMode ? activeTrial.previousDuration : 0)
            mediaSyncContext.delay.skew = newSkew
          }
        }
      })
    }
    return () => {
      samplerEvent?.terminate()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaSyncContext, getCurrentDriveTrial])

  if (
    !guid ||
    (!hasRedirectData && !isLoadingRedData) ||
    isRedDataError ||
    isTrialsError
  ) {
    return (
      <StatusPage
        message={`${enUS.ERR_SERVER}: ${enUS.ERR_EXTRACTING_DATA}`}
        normalText
        hideReturnToHomePage
        status={500}
      />
    )
  }

  if (isLoadingTrials || isLoadingRedData) {
    return (
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Loader text='Preparing data' scale={1.5} />
      </div>
    )
  }

  if (mediaSyncContext.totalDuration === 0) {
    return (
      <StatusPage message={enUS.NO_VIDEO} normalText hideReturnToHomePage />
    )
  }

  return (
    <DriveTrialContext.Provider
      value={{
        modeKey,
        driveTrials: data,
        getCurrentDriveTrial,
        getDriveTrialByKey,
        getDriveTrialByParentDTID,
        getDriveTrialById,
        isConcatenation,
        highlightMode,
        setHighlightMode,
        synchronizerRef,
        redirectData: redirectData!,
      }}
    >
      {children}
    </DriveTrialContext.Provider>
  )
}

export default DriveTrialDataProvider
