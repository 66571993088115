import VectorSource from 'ol/source/Vector'
import Stroke from 'ol/style/Stroke'
import Style from 'ol/style/Style'
import { Point } from 'ol/geom'
import Fill from 'ol/style/Fill'
import Text from 'ol/style/Text'
import { View } from 'ol'
import { fromLonLat } from 'ol/proj'

export const createLineStyle = (color: string, width: number) => {
  const ret = () => {
    const style = new Style({
      stroke: new Stroke({
        color: color,
        width: width,
      }),
    })

    return style
  }

  return ret
}

export const createPointStyle = (id: string, color: string, width: number) => {
  const style = new Style({
    text: new Text({
      text: '' + id,
      fill: new Fill({ color: 'white' }),
      stroke: new Stroke({ color: color, width: width }),
    }),
  })

  return style
}

export const checkMarkerVisibility = (marker: Point) => {
  const markerPosition = marker.getCoordinates()
  const viewExtent = view.calculateExtent()

  if (
    markerPosition[0] <= viewExtent[0] ||
    markerPosition[0] >= viewExtent[2] ||
    markerPosition[1] <= viewExtent[1] ||
    markerPosition[1] >= viewExtent[3]
  ) {
    view.animate({
      duration: 150,
      center: markerPosition,
    })
  }
}

export const source = new VectorSource()

export const view = new View({
  projection: 'EPSG:3857',
  center: fromLonLat([-83.21104937105021, 42.64401623615981]),
  zoom: 17,
  maxZoom: 19,
})
