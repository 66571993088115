import { useProjectData } from '@api/table/projects'
import { TableRouteParams } from '@common/constants/paths'
import { useDebounce } from '@common/hooks/useDebounced'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import PauseIcon from '@mui/icons-material/Pause'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import Replay10Icon from '@mui/icons-material/Replay10'
import { Button, Stack } from '@mui/material'
import { useDriveTrialContext } from '@pages/Details/providers/DriveTrialDataProvider'
import { useTimelineContext } from '@modules/timelineViewport'
import { MediaSyncContext } from '@pages/Details/types/providers'
import { PlaybackRateMenu } from '@modules/transportControls/components/PlaybackRateMenu'
import { JUMP_FRAME_TIME_OFFSET } from '@common/constants/transport'
import { useSnackbar } from 'notistack'
import Platform from 'platform'
import {
  forwardRef,
  Ref,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { useParams } from 'react-router-dom'
import { StyledTooltip } from '@common/components/StyledTooltip/StyledTooltip'
import { useSeekToTag } from '@modules/transportControls/hooks/useSeekToTag'

interface IProps {
  frameRef: React.RefObject<HTMLInputElement>
  onPlayPause: (playing: boolean) => void
  onChangePlaybackRate: (velocity: number) => void
}

export interface PlayControlsRef {
  isPlaying: boolean
  setPlaying: (playing: boolean) => void
  setFrameInputFocused: (focused: boolean) => void
}

const iconSX = {
  color: '#f4f4f4',
}

const buttonSX = {
  minWidth: '32px',
  padding: 0,
}

export const PlayControls = forwardRef(function PlayControls(
  { onPlayPause, onChangePlaybackRate, frameRef }: IProps,
  ref: Ref<PlayControlsRef>
) {
  const timelineCtx = useTimelineContext()
  const mediaSyncCtx = useContext(MediaSyncContext)
  const { highlightMode } = useDriveTrialContext()
  const [isPlaying, setIsPlaying] = useState(false)
  const [tagSeekMode, setTagSeekMode] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const { project } = useParams<TableRouteParams>()
  const { frameRate } = useProjectData()
  const [frameInputFocused, setFrameInputFocused] = useState<boolean>(false)

  const debounceChangeTimingPosition = useDebounce((num: number) => {
    mediaSyncCtx.isSeeking = true

    const time = mediaSyncCtx.timingObj?.pos + num / frameRate
    mediaSyncCtx.timingObj?.update({
      position: time + JUMP_FRAME_TIME_OFFSET,
    })
  }, 300)

  useImperativeHandle(
    ref,
    () => ({ setPlaying: setIsPlaying, isPlaying, setFrameInputFocused }),
    [isPlaying]
  )

  const seekToTag = useSeekToTag()

  useEffect(() => {
    const onShiftKey = (e: KeyboardEvent) => {
      if (e.key !== 'Shift' || highlightMode.id !== -1) {
        return
      }

      setTagSeekMode(!tagSeekMode)
    }

    const keyPressHandlers = (e: KeyboardEvent) => {
      const { code, key, ctrlKey } = e
      const isSpace = code === 'Space'
      const isArrowLeft = key === 'ArrowLeft'
      const isArrowRight = key === 'ArrowRight'
      const isFrameInputFocused = frameInputFocused
      const isModalOpen =
        document.getElementsByClassName(
          'MuiDialog-root dialog-container MuiModal-root'
        ).length > 0

      if (isSpace) {
        if (!isModalOpen) {
          e.preventDefault()
          const isPlaying = !mediaSyncCtx.isPlaying
          setIsPlaying(isPlaying)
          onPlayPause(isPlaying)
        }
        return
      }

      if (tagSeekMode) {
        if (isArrowLeft && !isFrameInputFocused) {
          seekToTag(false)
        } else if (isArrowRight && !isFrameInputFocused) {
          seekToTag(true)
        }
        return
      }

      if (!isFrameInputFocused) {
        e.preventDefault()
        const frameChange =
          (ctrlKey ? -10 * frameRate : -1) *
          (isArrowLeft ? 1 : isArrowRight ? -1 : 0)
        debounceChangeTimingPosition(frameChange)
        mediaSyncCtx.isSeeking = true
        if (frameRef.current) {
          const currentValue = +frameRef.current.value
          frameRef.current.value = (currentValue + frameChange).toString()
        }
      }
    }

    const combineKeyDown = (e: KeyboardEvent) => {
      if (!timelineCtx.inputInFocus) {
        onShiftKey(e)
        keyPressHandlers(e)
      }
    }

    window.addEventListener('keyup', combineKeyDown)

    return () => {
      window.removeEventListener('keyup', combineKeyDown)
    }
    // Jumping by tag or by frame
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    seekToTag,
    project,
    tagSeekMode,
    enqueueSnackbar,
    mediaSyncCtx.timingObj,
    timelineCtx.items,
    timelineCtx.inputInFocus,
    debounceChangeTimingPosition,
  ])

  const leftArrow = (
    <StyledTooltip
      title={tagSeekMode ? 'Previous tag' : '-1 frame (left arrow)'}
      placement='top'
    >
      <Button
        data-testid={tagSeekMode ? 'previousTag' : 'previousFrame'}
        sx={buttonSX}
        disabled={isPlaying}
        onClick={() => {
          if (tagSeekMode) return seekToTag(false)

          debounceChangeTimingPosition(-1)
          mediaSyncCtx.isSeeking = true
          if (frameRef.current) {
            frameRef.current.value = (+frameRef.current?.value - 1).toString()
          }
        }}
      >
        <FiberManualRecordIcon
          fontSize='small'
          style={{
            color: tagSeekMode ? 'white' : 'transparent',
            width: '10px',
            marginRight: '-8px',
          }}
        />
        <ChevronLeftIcon sx={iconSX} fontSize='medium' />
      </Button>
    </StyledTooltip>
  )

  const rightArrow = (
    <StyledTooltip
      title={tagSeekMode ? 'Next tag' : '+1 frame (right arrow)'}
      placement='top'
    >
      <Button
        data-testid={tagSeekMode ? 'nextTag' : 'nextFrame'}
        sx={buttonSX}
        disabled={isPlaying}
        onClick={() => {
          if (tagSeekMode) return seekToTag(true)

          debounceChangeTimingPosition(1)
          mediaSyncCtx.isSeeking = true
          if (frameRef.current) {
            frameRef.current.value = (+frameRef.current?.value + 1).toString()
          }
        }}
      >
        <ChevronRightIcon sx={iconSX} fontSize='medium' />
        <FiberManualRecordIcon
          fontSize='small'
          style={{
            color: tagSeekMode ? 'white' : 'transparent',
            width: '10px',
            marginLeft: '-8px',
          }}
        />
      </Button>
    </StyledTooltip>
  )

  return (
    <Stack direction='row'>
      <StyledTooltip
        title={
          Platform.os?.family === 'OS X'
            ? '-10s (command+<left arrow>)'
            : '-10s (ctrl+<left arrow>)'
        }
        placement='top'
      >
        <Button
          data-testid='10sBack'
          sx={buttonSX}
          onClick={() => {
            debounceChangeTimingPosition(-10 * frameRate)
            mediaSyncCtx.isSeeking = true
            if (frameRef.current) {
              frameRef.current.value = (
                +frameRef.current?.value -
                10 * frameRate
              ).toString()
            }
          }}
        >
          <Replay10Icon sx={iconSX} fontSize='medium' />
        </Button>
      </StyledTooltip>

      {leftArrow}

      <StyledTooltip
        title={`${isPlaying ? 'Pause' : 'Play'} (space)`}
        placement='top'
      >
        <Button
          data-testid={isPlaying ? 'pauseVideo' : 'playVideo'}
          sx={buttonSX}
          onClick={() => {
            onPlayPause(!mediaSyncCtx.isPlaying)
            setIsPlaying(!mediaSyncCtx.isPlaying)
          }}
        >
          {isPlaying ? (
            <PauseIcon sx={iconSX} fontSize='large' />
          ) : (
            <PlayArrowIcon sx={iconSX} fontSize='large' />
          )}
        </Button>
      </StyledTooltip>

      {rightArrow}

      <PlaybackRateMenu onChange={onChangePlaybackRate} />
    </Stack>
  )
})
