import { MediaSyncContext } from '@pages/Details/types/providers'
import { useCallback, useContext } from 'react'
import { useGetCurrentCoordinate } from './useGetCurrentCoordinate'
import { useHandlePosition } from './useHandlePosition'
import { useMapContext } from './useMapContext'
import { useRenderDevCoordinates } from './useRenderDevCoordinates'

export function useHandleTimeChange() {
  const mediaSyncContext = useContext(MediaSyncContext)
  const { setIsPlaying, markerRef } = useMapContext()

  const renderDevCoordinates = useRenderDevCoordinates()
  const handlePosition = useHandlePosition()
  const getCurrentCoordinate = useGetCurrentCoordinate()

  const calculateRotationAngle = useCallback(() => {
    const currentPosition = getCurrentCoordinate()
    const radians = currentPosition[3]
    return radians * (180 / Math.PI)
  }, [getCurrentCoordinate])

  return useCallback(
    (time: number) => {
      if (time !== undefined) {
        setIsPlaying(mediaSyncContext.isPlaying)
        renderDevCoordinates(time)
        handlePosition(time)
        markerRef.current?.setRotationAngle(calculateRotationAngle())
      }
    },
    [
      calculateRotationAngle,
      handlePosition,
      markerRef,
      mediaSyncContext.isPlaying,
      renderDevCoordinates,
      setIsPlaying,
    ]
  )
}
