import { StyledTooltip } from '@common/components/StyledTooltip/StyledTooltip'
import {
  Alert,
  Box,
  Pagination,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableHeadProps,
  TableProps,
  TableRow,
  styled,
} from '@mui/material'
import { PropsWithChildren } from 'react'

interface StyledTableProps extends TableProps {
  position: 'left' | 'center' | 'right'
}

interface StyledTableHeadProps extends TableHeadProps {
  position: 'left' | 'center' | 'right'
}

export const StyledTableContainer = styled(TableContainer)(() => ({
  overflow: 'auto',
  width: 'auto',
  display: 'flex',
  boxShadow: 'rgba(0, 0, 0, 0.7) 0px 0px 8px 0px',
  backgroundColor: '#fff',
  borderRadius: '4px',

  // Webkit scrollbar styles
  '::-webkit-scrollbar': {
    width: 8,
    height: 8,
  },

  '::-webkit-scrollbar-track': {
    backgroundColor: 'transparent',
    borderRadius: '4px',
  },

  '::-webkit-scrollbar-thumb': {
    backgroundColor: '#3f4c6b',
    border: '1px solid #d8dbe1',
    borderRadius: '4px',

    '&:hover': {
      backgroundColor: '#6779a5',
    },
  },
}))

export const StyledTable = styled(Table)<StyledTableProps>(({ position }) => ({
  position: 'sticky',
  borderCollapse: 'separate',
  left: position === 'left' ? 0 : '',
  right: position === 'right' ? 0 : '',
  boxShadow:
    position === 'left'
      ? '4px 0 6px 0 #0000004d'
      : position === 'right'
        ? '-4px 0 6px 0 #0000004d'
        : '',
  zIndex: position === 'left' || position === 'right' ? 2 : 0,
}))

export const StyledTableHead = styled(TableHead)<StyledTableHeadProps>(
  ({ position }) => ({
    position: 'sticky',
    top: 0,
    backgroundColor: '#3f4c6b',
    zIndex: position === 'left' || position === 'right' ? 2 : 'unset',
  })
)

export const StyledTableHeaderRow = styled(TableRow)(() => ({
  fontSize: '0.875rem',
  backgroundColor: '#CFD4E0',
  '&:first-of-type': {
    backgroundColor: '#EDEDED',
    fontSize: '1rem',
  },
}))

export const StyledAlert = styled(Alert)(() => ({
  width: '100%',
  marginTop: '32px',
}))

export const StyledTableHeaderCell = styled(TableCell)(({ align }) => ({
  position: 'relative',
  textAlign: align,
  color: '#141414',
  whiteSpace: 'nowrap',
  borderLeft: '1px solid #EDEDED',
  borderRight: '1px solid #EDEDED',
  fontSize: 'inherit',
  padding: '4px 8px',
  fontFamily: '"DM Sans", sans-serif',
  fontWeight: 700,
  height: '33px',
  '&:first-of-type': {
    borderleft: 0,
  },
  '&:last-child': {
    borderRight: 0,
  },
}))

export const StyledHeaderCellLayout = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}))

export const StyledTableBodyRow = styled(TableRow)(() => ({
  backgroundColor: '#fff',
  fontSize: '0.875rem',
  '&:nth-of-type(odd)': {
    backgroundColor: '#F4F4F4',
  },
}))

export const StyledTableFooterRow = styled(TableRow)(() => ({
  backgroundColor: '#E0DBCF',
  fontWeight: 500,
  fontSize: '0.875rem',
}))

export const StyledTableBodyCell = styled(TableCell)(() => ({
  padding: '4px 8px',
  fontFamily: '"DM Mono", monospace',
  whiteSpace: 'nowrap',
  borderLeft: '1px solid #EDEDED',
  borderRight: '1px solid #EDEDED',
  color: '#1D2433',
  fontWeight: 'inherit',
  fontSize: 'inherit',
  height: '32px',
}))

export const StyledTableFooterCell = styled(TableCell)(() => ({
  padding: '4px 8px',
  fontFamily: '"DM Mono", monospace',
  whiteSpace: 'nowrap',
  borderTop: '1px solid #9e9e9e',
  borderLeft: '1px solid #EDEDED',
  borderRight: '1px solid #EDEDED',
  color: '#1D2433',
  fontWeight: 'inherit',
  fontSize: 'inherit',
  height: '32px',
}))

export const StyledPagination = styled(Pagination)(() => ({
  alignSelf: 'flex-end',
  '& .MuiPaginationItem-root.Mui-selected': {
    backgroundColor: '#102D92',
  },
  '& .MuiPaginationItem-root.Mui-selected:hover': {
    backgroundColor: '#1740d0',
  },
  '& .MuiPaginationItem-root': {
    color: '#e5e5e5',
  },
}))

interface StyledDivProps {
  textAlign: 'left' | 'center' | 'right'
  tooltip?: string
}

export const StyledDiv = ({
  textAlign,
  tooltip,
  children,
}: PropsWithChildren<StyledDivProps>) => {
  const content = (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: textAlign,
      }}
    >
      {children}
    </div>
  )

  return tooltip && tooltip !== '-' ? (
    <StyledTooltip
      title={tooltip}
      showArrow={false}
      followCursor={true}
      enterDelay={50}
      placement='bottom'
    >
      {content}
    </StyledTooltip>
  ) : (
    content
  )
}
