import { TrackDisplayMode, getTrackWidth } from '@modules/timelineViewport'
import { bisector, line, pointer, scaleLinear, select } from 'd3'
import { TimelineWindow } from 'otto-vis-timeline'

export interface ChartData {
  start: number
  end: number
  value: number
  label: number
}

export interface TimelineRangeChangeEvent {
  start: Date
  end: Date
  byUser: boolean
}

export function drawChart(
  window: TimelineWindow,
  svgElement: SVGSVGElement,
  data: (ChartData | null)[],
  color: string,
  yScaleA: number,
  chartType: TrackDisplayMode
) {
  const updateScales = () => {
    const trackWidth = getTrackWidth()
    const xScale = scaleLinear()
      .domain([window.start.getTime(), window.end.getTime()])
      .range([0, trackWidth])
    const yScale = scaleLinear().domain([0, yScaleA]).range([yScaleA, 0])

    return { xScale, yScale, trackWidth }
  }
  const { xScale, yScale, trackWidth } = updateScales()

  const mousemove = (event: MouseEvent) => {
    const [x] = pointer(event, svgElement)
    const filteredData = data.filter((d) => d !== null) as ChartData[]
    const x0 = xScale.invert(x)
    const i = bisector((d: ChartData) => d.start).left(filteredData, x0, 1)
    const d0 = filteredData[i - 1]
    const d1 = filteredData[i]
    if (d0 && d1) {
      const d = x0 - d0.start > d1.start - x0 ? d1 : d0
      select('#tooltip')
        .style('visibility', 'visible')
        .style('top', 100 + 'px')
        .style('left', event.pageX + 10 + 'px')
        .html(`x: ${d.start / 1000}s, <br> y: ${d.label}`)
    } else {
      select('#tooltip').style('visibility', 'hidden')
    }
  }

  const svg = select(svgElement)
    .attr('xmlns', 'http://www.w3.org/2000/svg')
    .attr('viewBox', `0 0 ${trackWidth} ${yScaleA}`)
    .attr('width', `${trackWidth}px`)
    .attr('height', `${yScaleA}px`)

  svg.selectAll('*').remove()

  if (chartType === 'lines') {
    const segmentData = data.reduce(
      (segments, point, index, array) => {
        if (point !== null) {
          segments.current.push(point)
          if (index === array.length - 1 || array[index + 1] === null) {
            segments.result.push(segments.current)
            segments.current = []
          }
        }
        return segments
      },
      { current: [], result: [] } as {
        current: ChartData[]
        result: ChartData[][]
      }
    )

    if (segmentData.current.length) {
      segmentData.result.push(segmentData.current)
    }

    const lines = line<ChartData>()
      .x((d) => xScale(d.start))
      .y((d) => yScale(d.value))

    segmentData.result.forEach((segment) => {
      svg
        .append('path')
        .datum(segment)
        .attr('fill', 'none')
        .attr('stroke', color)
        .attr('stroke-width', 2)
        .attr('d', lines)
    })

    svg
      .append('rect')
      .attr('width', trackWidth)
      .attr('height', yScaleA)
      .style('fill', 'none')
      .style('pointer-events', 'all')
      .on('mousemove', mousemove)
  } else if (chartType === 'bars') {
    svg
      .selectAll('.bar')
      .data(data.filter((d) => d !== null) as ChartData[])
      .join('rect')
      .attr('class', 'bar')
      .attr('x', (d) => xScale(d.start))
      .attr('y', (d) => yScale(d.value))
      .attr('width', (d) => xScale(d.end) - xScale(d.start) - 1)
      .attr('height', (d) => yScaleA - yScale(d.value))
      .attr('fill', color)
      .attr('stroke', 'black')
      .attr('stroke-width', 1)
      .on('mousemove', mousemove)

    svg
      .append('rect')
      .attr('width', trackWidth)
      .attr('height', yScaleA)
      .style('fill', 'none')
      .style('pointer-events', 'all')
      .on('mousemove', mousemove)
  }

  svg.on('mouseout', () => {
    select('#tooltip').style('visibility', 'hidden')
  })
}

export const createChartElement = (
  window: TimelineWindow,
  data: (ChartData | null)[],
  color: string,
  yScaleA: number,
  trackWidth: number,
  id: string,
  display: TrackDisplayMode
) => {
  const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
  svg.id = id
  svg.style.display = 'block'
  svg.style.width = `${trackWidth}px`
  svg.style.height = `${yScaleA}px`
  drawChart(window, svg, data, color, yScaleA, display)
  return svg
}
