import { useMapQuery } from '@api/map'
import { ISynchronizer } from '@common/services/synchronizer/synchronizer'
import { MediaSyncContext } from '@pages/Details/types/providers'
import { useContext, useEffect } from 'react'
import { useHandleRecenter } from './useHandleRecenter'
import { useMapContext } from './useMapContext'

export function useHandleMapInteraction({
  synchronizer,
  viewportId,
  isFullscreen,
}: {
  synchronizer?: ISynchronizer
  viewportId: number
  isFullscreen: boolean
}) {
  const mediaSyncContext = useContext(MediaSyncContext)
  const {
    mapRef,
    setInitialZoomFinished,
    setShowFollowButton,
    setFollowMode,
    setIsAlreadyPlayed,
    initialZoomFinished,
    isAlreadyPlayed,
    isPlaying,
  } = useMapContext()

  const recenterMap = useHandleRecenter()

  const { isLoading, isPending } = useMapQuery()

  // TODO: not sure what exactly is this for
  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.stop()
    }

    mediaSyncContext.isPlaying = false
    setInitialZoomFinished(false)
    setShowFollowButton(false)
    setFollowMode(false)
    setIsAlreadyPlayed(false)
  }, [
    mapRef,
    mediaSyncContext,
    setFollowMode,
    setInitialZoomFinished,
    setIsAlreadyPlayed,
    setShowFollowButton,
  ])

  // update synchronizer
  useEffect(() => {
    if (isLoading || isPending) {
      synchronizer?.updateStatus(viewportId, false)
      return
    }

    synchronizer?.updateStatus(viewportId, true)
  }, [isLoading, isPending, synchronizer, viewportId])

  // observe resize on fullscreen change
  useEffect(() => {
    mapRef.current?.resize()
  }, [isFullscreen, mapRef])

  // handle zoom animation
  useEffect(() => {
    if (!mapRef.current) {
      return
    }

    mapRef.current.on('zoomend', () => {
      if (initialZoomFinished) {
        return
      }

      setFollowMode(false)
      setInitialZoomFinished(true)
    })
  }, [initialZoomFinished, mapRef, setFollowMode, setInitialZoomFinished])

  useEffect(() => {
    if (isAlreadyPlayed) {
      return
    }

    if (isPlaying) {
      if (mapRef.current?.isZooming) {
        mapRef.current.stop()
      }

      setIsAlreadyPlayed(true)
      recenterMap()
    }
  }, [isAlreadyPlayed, isPlaying, mapRef, recenterMap, setIsAlreadyPlayed])
}
