import { Layer, Source } from 'react-map-gl'
import { GpsPoint } from '../../types/map'

interface SourceProps {
  id: string
  type: 'geojson'
  data: {
    id: string
    type: 'Feature'
    geometry: {
      type: 'LineString'
      coordinates: GpsPoint[]
    }
    properties: null
  }
}

interface LineLayerPaint {
  'line-width': number
  'line-color': string
  'line-opacity'?: number
  'line-blur'?: number
}

interface LineProps {
  id: string
  type: 'line'
  source: string
  paint: LineLayerPaint
}

interface GpsSourceProps {
  paint: LineLayerPaint
  route: GpsPoint[]
  driveKey: number
}

function GpsSource({ paint, route, driveKey }: GpsSourceProps) {
  const gpsSourceProps: SourceProps = {
    id: `sourceLine-${driveKey}`,
    type: 'geojson',
    data: {
      id: `ap-route-${driveKey}`,
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates: route,
      },
      properties: null,
    },
  }

  const gpsLayerProps: LineProps = {
    id: `layerLine-${driveKey}`,
    type: 'line',
    source: 'sourceLine',
    paint: paint,
  }

  return (
    <Source {...gpsSourceProps}>
      <Layer {...gpsLayerProps} />
    </Source>
  )
}

export default GpsSource
