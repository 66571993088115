export const ottoDefaultColors = [
  '#D73027',
  '#514AFF',
  '#F78B02',
  '#E1D74C',
  '#618300',
  '#00CE5F',
  '#42D1F0',
]
export const meDefaultColors = [
  '#EDFBFC',
  '#E8AB96',
  '#4990EA',
  '#424EF0',
  '#190061',
  '#CE006F',
  '#F06142',
]
export const ottoPalette1Colors = [
  '#EE6677',
  '#AA3377',
  '#BBBBBB',
  '#228833',
  '#4477AA',
  '#66CCEE',
  '#CCBB44',
]
export const mePalette1Colors = [
  '#66EEDD',
  '#33AA66',
  '#353535',
  '#882277',
  '#AA7744',
  '#EE8866',
  '#4455CC',
]
export const ottoColorblindColors = [
  '#2C3D7E',
  '#415497',
  '#596FB0',
  '#8298CF',
  '#A2B7E7',
  '#C6D6F7',
  '#E2EBFB',
]
export const meColorblindColors = [
  '#FDD9AD',
  '#F4A164',
  '#EE7733',
  '#CC5725',
  '#AB3C19',
  '#8A2510',
  '#721509',
]

export const colorsDict = {
  ActiveEgoLineColor: 'Ego Lane',
  ActiveEgoLineClass: 'Ego Class & Color',
  ActiveAdjacentLaneColor: 'Adjacent Lane',
  ActiveAdjacentLaneClass: 'Adjacent Class & Color',
  ActivePedColor: 'Pedestrian',
  ActiveCarColor: 'Car',
  ActiveBusColor: 'Bus',
  ActiveCycleColor: 'Cycle',
  ActiveTruckColor: 'Truck',
  ActiveSignColor: 'Sign',
  ActiveMotorcycleColor: 'Motorcycle',
  ActiveTrafficLightColor: 'Traffic Light',
  ActiveTtdColor: 'TTD',
}
export type ColorKeys = keyof typeof colorsDict

export const getColorName = (name: ColorKeys) => {
  const dictName = colorsDict[name]
  return dictName ? dictName : name
}

export const colorsDictRev = {
  'Ego Lane': 'ActiveEgoLineColor',
  'Adjacent Lane': 'ActiveAdjacentLaneColor',
  Pedestrian: 'ActivePedColor',
  Car: 'ActiveCarColor',
  Bus: 'ActiveBusColor',
  Cycle: 'ActiveCycleColor',
  Truck: 'ActiveTruckColor',
  Sign: 'ActiveSignColor',
  Motorcycle: 'ActiveMotorcycleColor',
  'Traffic Light': 'ActiveTrafficLightColor',
  TTD: 'ActiveTtdColor',
}

export const iconStyles = {
  color: '#fff',
}
