import { MouseEvent, PropsWithChildren, useRef, useState } from 'react'
import './style.scss'

interface ResizableBoxProps {
  minHeight: number
  minWidth: number
  initHeight: number
  initWidth: number
}

export function ResizableBox({
  children,
  minHeight,
  minWidth,
  initHeight,
  initWidth,
}: PropsWithChildren<ResizableBoxProps>) {
  const [dimensions, setDimensions] = useState({
    width: initWidth,
    height: initHeight,
  })
  const boxRef = useRef<HTMLDivElement>(null)

  const handleMouseDown = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault()
    document.addEventListener('mousemove', handleMouseMove)
    document.addEventListener('mouseup', handleMouseUp)
  }

  const handleMouseMove = (e: globalThis.MouseEvent) => {
    if (!boxRef.current) return

    const { left, top } = boxRef.current.getBoundingClientRect()

    const newWidth = Math.max(e.clientX - left, minWidth)
    const newHeight = Math.max(e.clientY - top, minHeight)

    setDimensions({
      width: newWidth,
      height: newHeight,
    })
  }

  const handleMouseUp = () => {
    document.removeEventListener('mousemove', handleMouseMove)
    document.removeEventListener('mouseup', handleMouseUp)
  }

  return (
    <div
      ref={boxRef}
      className='resizable-box'
      style={{
        width: `${dimensions.width}px`,
        height: `${dimensions.height}px`,
        minHeight,
        minWidth,
        borderRadius: '4px',
      }}
    >
      <div
        className='content'
        style={{
          overflow: 'auto',
          width: '100%',
          height: '100%',
        }}
      >
        {children}
      </div>
      <div className='resizer' onMouseDown={handleMouseDown} />
    </div>
  )
}
