import './style.scss'

interface IProps {
  min?: number
  max: number
  handleRender?: (node: HTMLInputElement) => void
}

export const Slider = ({ max, min, handleRender }: IProps) => (
  <input
    data-testid='contorlsSlider'
    defaultValue={0}
    className='slider-range-input'
    type='range'
    step={'any'}
    min={min}
    max={max}
    aria-label='Default'
    onFocus={(e) => e.currentTarget.blur()}
    ref={handleRender}
  />
)
