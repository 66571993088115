import { TableRouteParams } from '@common/constants/paths'
import { getResponseStatusCode } from '@common/utils/api'
import { useDriveTrialContext } from '@pages/Details/providers/DriveTrialDataProvider'
import { useQueries } from '@tanstack/react-query'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { useProjectData } from '../table/projects'
import { urls } from '../urls'
import { ObjectHighlight } from './types'

const getUrl = (dtid: number) =>
  urls.driveTrialHighlights.replace(':id', dtid.toString())

export const useHighlightsQueries = (allColumns: string[]) => {
  const { reportType } = useParams<TableRouteParams>()
  const { redirectData } = useDriveTrialContext()
  const { sectionLength } = useProjectData()
  const driveTrialData = redirectData?.rows || []

  const table =
    reportType === 'characterization' ? 'Characterizations' : 'QualityMetrics'
  const queryData = allColumns
    .filter((column) => !column.includes('Country'))
    .flatMap((highlightType) =>
      driveTrialData.map((dt) => ({
        table,
        sectionLength,
        dtid: dt.DTID,
        parentDTID: dt.parentDTID,
        highlightType,
      }))
    )

  const queries = useQueries({
    queries: queryData.map(
      ({ table, dtid, parentDTID, highlightType, sectionLength }) => ({
        queryKey: [
          'highlights',
          reportType,
          table,
          sectionLength,
          dtid,
          parentDTID,
          highlightType,
          driveTrialData,
        ],
        queryFn: (): Promise<ObjectHighlight[]> =>
          axios
            .post(
              getUrl(dtid),
              { table, sectionLength, parentDTID },
              { params: { highlightType } }
            )
            .then(({ data }) => data),
      })
    ),
  })
  const combinedQueries = queries.map(
    ({ data, isFetched, isError, error }, index) => ({
      data,
      isFetched,
      isError,
      highlightType: queryData[index].highlightType,
      table: table,
      sectionLength,
      DTID: queryData[index].dtid,
      parentDTID: queryData[index].parentDTID,
      statusCode: getResponseStatusCode(error),
    })
  )

  const isAllFetched = combinedQueries.every((query) => query.isFetched)

  return {
    highlights: combinedQueries,
    isHighlightsFetched: isAllFetched,
  }
}
