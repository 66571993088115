import { useSprGalleryQuery } from '@api/sprGallery/spr-gallery'
import { useProjectData } from '@api/table/projects'
import { Loader } from '@common/components/Loader/Loader'
import { Routes } from '@common/constants/paths'
import { PROJECT_IDS } from '@common/constants/projects'
import {
  ImageBox,
  useHandleCloseImageModal,
  useHandlePagination,
} from '@modules/gallery'
import { Box, Breadcrumbs, Fade, Modal, Typography } from '@mui/material'
import { Navigate, useSearchParams } from 'react-router-dom'
import { ImageItem } from './components/ImageItem'
import { Sidebar } from './components/Sidebar'
import { useBreadcrumbs } from './hooks/useBreadcrumbs'
import { useHandleImageClick } from './hooks/useHandleImageClick'
import { useHandleProjectChange } from './hooks/useHandleProjectChange'
import { StyledPagination } from '@modules/reportTable'
import './styles.scss'

export function SprGallery() {
  const { data, isLoading, page, count, frameId, dtid, delegateType, day } =
    useSprGalleryQuery()
  const { projectID } = useProjectData()
  const [searchParams] = useSearchParams()
  const isImageBoxOpen = searchParams.get('selectedIndex') !== null

  useHandleProjectChange()
  const breadcrumbs = useBreadcrumbs()
  const handleClose = useHandleCloseImageModal()
  const handleImageClick = useHandleImageClick()
  const handlePagination = useHandlePagination()

  if (projectID && ![PROJECT_IDS.VOLVO, PROJECT_IDS.MF1].includes(projectID)) {
    return <Navigate to={Routes.INDEX} replace={true} />
  }

  if (isLoading) {
    return <Loader text='SPR' center />
  }

  return (
    <div className='gallery-container'>
      <Sidebar
        day={day}
        frameId={frameId}
        delegateType={delegateType}
        dtid={dtid}
      />
      {data?.length ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
              position: 'relative',
              p: 1,
              pb: 0,
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', lineHeight: 1 }}>
              <Breadcrumbs
                className='gallery-breadcrumbs'
                sx={{ color: 'white' }}
                separator='›'
              >
                {breadcrumbs}
              </Breadcrumbs>
            </Box>

            <Typography
              sx={{
                transform: 'translate(-50%, -50%)',
                position: 'absolute',
                fontSize: '14px',
                color: 'white',
                left: '50%',
                top: '60%',
              }}
            >
              SPR - 5%
            </Typography>

            <StyledPagination
              sx={{ ml: 'auto', alignSelf: 'auto' }}
              onChange={handlePagination}
              count={count}
              page={page}
              size='small'
              shape='rounded'
            />
          </Box>

          <div className='gallery-container--right'>
            {data?.map((item) => (
              <ImageItem
                key={item.dtid + item.frameId}
                handleClick={handleImageClick}
                item={item}
              />
            ))}
          </div>
        </Box>
      ) : (
        <Box p={3}>
          <Typography sx={{ opacity: 0.8 }} variant='body1' color={'white'}>
            No results found
          </Typography>
        </Box>
      )}
      <Modal
        slotProps={{
          backdrop: {
            style: {
              backgroundColor: 'rgba(0, 0, 0, 0.7)',
            },
          },
        }}
        open={isImageBoxOpen}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade style={{ outline: 'none' }} in={isImageBoxOpen}>
          <Box>
            <ImageBox data={data} onClose={handleClose} />
          </Box>
        </Fade>
      </Modal>
    </div>
  )
}
