import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes as Router,
} from 'react-router-dom'
import { Dashboard } from '@pages/Dashboard'
import { Header, ReportNavigationContextProvider } from '@modules/header'
import { Routes, URLS } from '@common/constants/paths'
import { Details } from './pages/Details'
import { ErrorComponent } from './pages/Error/Error'
import LoginComponent from './pages/Login'
import StatusPage from './pages/StatusPage/StatusPage'
import { useAuthContext } from '@modules/auth'
import { SprGallery } from './pages/SprGallery'
import { NlGallery } from './pages/NlGallery'
import DriveTrials from '@pages/DriveTrials'
import KpiSensor from '@pages/KPISensor'
import KpiSystem from '@pages/KPISystem'
import ReportLayout from '@common/components/Layouts/ReportLayout'
import GalleryLayout from '@common/components/Layouts/GalleryLayout'

function App() {
  const { isAuthenticated } = useAuthContext()

  return (
    <BrowserRouter>
      <Router>
        <Route
          index
          path={Routes.INDEX}
          errorElement={<ErrorComponent errorType='An error has occured.' />}
          element={<LoginComponent />}
        />
        {!isAuthenticated && (
          <Route
            path={Routes.NOT_FOUND}
            element={<Navigate to={Routes.INDEX} replace={true} />}
          />
        )}
        {isAuthenticated && (
          <>
            <Route
              path={`${Routes.REPORTING}/`}
              element={
                <>
                  <ReportNavigationContextProvider>
                    <Header />
                    <div className='page-container'>
                      <Outlet />
                    </div>
                  </ReportNavigationContextProvider>
                </>
              }
            >
              <Route path={URLS.DRIVE_TRIAL} element={<ReportLayout />}>
                <Route path=':reportType' element={<DriveTrials />} />
              </Route>
              <Route path={URLS.KPI_SUMMARY_SENSOR} element={<ReportLayout />}>
                <Route path=':reportType' element={<KpiSensor />} />
              </Route>
              <Route path={URLS.KPI_SUMMARY_SYSTEM} element={<ReportLayout />}>
                <Route path=':reportType' element={<KpiSystem />} />
              </Route>

              <Route
                path={`${Routes.DASHBOARD}/Dashboard`}
                element={<Dashboard />}
              />

              <Route path={Routes.SPR_GALLERY} element={<GalleryLayout />}>
                <Route path='' element={<SprGallery />} />
              </Route>
              <Route path={Routes.NL_GALLERY} element={<GalleryLayout />}>
                <Route path='' element={<NlGallery />} />
              </Route>
            </Route>

            <Route
              path={Routes.ERROR}
              element={
                <ErrorComponent errorType='Please contact your sistem administrator.' />
              }
            />
            <Route path=':reportType'>
              <Route path={Routes.DETAILS} element={<Details />} />
              <Route path={Routes.KPI_DETAILS} element={<Details />} />
            </Route>
            <Route path={Routes.NOT_FOUND} element={<StatusPage />} />
          </>
        )}
      </Router>
    </BrowserRouter>
  )
}

export default App
