import { useTimelineContext } from '@modules/timelineViewport'
import { useCallback } from 'react'
import { useMapContext } from './useMapContext'

export function useHandleMouseUp() {
  const { setDragMap, setMouseDown } = useMapContext()
  const { setShouldMoveMarker } = useTimelineContext()

  return useCallback(() => {
    setDragMap(true)
    setMouseDown(false)
    setShouldMoveMarker(true)
  }, [setDragMap, setMouseDown, setShouldMoveMarker])
}
