import { Dashboard, useDashboardsQuery } from '@api/dashboards/dashboards'
import { useProjectData } from '@api/table/projects'
import { PROJECT_IDS } from '@common/constants/projects'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Button, Menu, MenuItem } from '@mui/material'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { NestedReportNavigationMenu } from '../../components/NestedReportNavigationMenu'
import { shouldSkipElement, useReportContext } from '@modules/reportTable'
import './styles.scss'
import { useReportNavigationContext } from '@modules/header/hooks/useReportNavigationContext'
import { getProjectID } from '@common/utils/storage'
import { NestedMenuItem } from 'mui-nested-menu'

const DRIVE_TRIALS_MENU_ITEMS = ['completeness', 'quality', 'characterization']
const KPI_SENSOR_MENU_ITEMS = ['FCM']
const KPIS_SENSOR_FCM_MENU_ITEMS = ['lanes', 'signs', 'lights']
const KPIS_MB1_ITEMS = ['TTD']

export type MenuItemType = {
  name: string
  menuItems: ReadonlyArray<string>
  routeBase: string
  anchorEl: HTMLElement | undefined
  handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  close: () => void
  projects?: ReadonlyArray<PROJECT_IDS>
}

export function ReportNavigation() {
  const navigate = useNavigate()
  const { data } = useDashboardsQuery()
  const { setDashboardURL } = useReportNavigationContext()
  const { setPage } = useReportContext()
  const { pathname } = useLocation()
  const [driveAnchorEl, setDriveAnchorEl] = useState<HTMLElement>()
  const [kpiSensorAnchorEl, setKpiSensorAnchorEl] = useState<HTMLElement>()
  const [kpiSystemAnchorEl, setKpiSystemAnchorEl] = useState<HTMLElement>()
  const [dashboardAnchorEl, setDashboardAnchorEl] = useState<HTMLElement>()
  const [sprAnchorEl, setSprAnchorEl] = useState<HTMLElement>()
  const [nlAnchorEl, setNlAnchorEl] = useState<HTMLElement>()
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const { projectID } = useProjectData()

  const navigateToNestedMenu = (
    menu: MenuItemType,
    item: string,
    data: Dashboard[] | undefined
  ) => {
    const { name, routeBase } = menu

    switch (name) {
      case 'SPR Gallery':
      case 'NL Query':
        navigate(routeBase)
        break

      case 'Dashboards': {
        const chosenDashboard = data!.find((x) => x.name === item)
        if (chosenDashboard) {
          navigate(`${routeBase}Dashboard`)
          setDashboardURL(chosenDashboard.url)
        }
        break
      }

      default: {
        navigate(`${routeBase}${item}`)
      }
    }
  }

  const navigateToMenu = (menu: MenuItemType) => {
    const { name, routeBase } = menu

    switch (name) {
      case 'SPR Gallery':
      case 'NL Query':
        navigate(routeBase)
        break
      case 'Dashboards':
        navigate(`${routeBase}Dashboard`)
        break
    }
  }

  const dashboardMenuItems = useMemo(() => {
    return data?.map((x) => x.name) || []
  }, [data])

  useEffect(() => {
    if (data) {
      setDashboardURL(data[0].url)
    }
  }, [data])

  const menus: ReadonlyArray<MenuItemType> = [
    {
      name: 'Drive Trials',
      menuItems: DRIVE_TRIALS_MENU_ITEMS,
      routeBase: '/reporting/drive-trials/',
      anchorEl: driveAnchorEl,
      handleClick: (event: React.MouseEvent<HTMLButtonElement>) =>
        setDriveAnchorEl(event.currentTarget),
      close: () => setDriveAnchorEl(undefined),
    },
    {
      name: 'KPI Sensor',
      menuItems: KPI_SENSOR_MENU_ITEMS,
      routeBase: '/reporting/kpi-sensor/',
      anchorEl: kpiSensorAnchorEl,
      handleClick: (event: React.MouseEvent<HTMLButtonElement>) =>
        setKpiSensorAnchorEl(event.currentTarget),

      close: () => setKpiSensorAnchorEl(undefined),
    },
    {
      name: 'KPI System',
      menuItems: KPIS_MB1_ITEMS,
      routeBase: '/reporting/kpi-system/',
      anchorEl: kpiSystemAnchorEl,
      handleClick: (event: React.MouseEvent<HTMLButtonElement>) =>
        setKpiSystemAnchorEl(event.currentTarget),
      close: () => setKpiSystemAnchorEl(undefined),
    },
    {
      name: 'Dashboards',
      menuItems: dashboardMenuItems,
      routeBase: '/reporting/dashboards/',
      anchorEl: dashboardAnchorEl,
      handleClick: (event: React.MouseEvent<HTMLButtonElement>) =>
        setDashboardAnchorEl(event.currentTarget),
      close: () => setDashboardAnchorEl(undefined),
    },
    {
      name: 'SPR Gallery',
      menuItems: ['SPR Gallery'],
      projects: [PROJECT_IDS.VOLVO, PROJECT_IDS.MF1],
      routeBase: '/reporting/spr-gallery',
      anchorEl: sprAnchorEl,
      handleClick: (event: React.MouseEvent<HTMLButtonElement>) =>
        setSprAnchorEl(event.currentTarget),
      close: () => setSprAnchorEl(undefined),
    },
    {
      name: 'NL Query',
      menuItems: ['NL Query'],
      projects: [PROJECT_IDS.VOLVO],
      routeBase: '/reporting/nl-gallery',
      anchorEl: nlAnchorEl,
      handleClick: (event: React.MouseEvent<HTMLButtonElement>) =>
        setNlAnchorEl(event.currentTarget),
      close: () => setNlAnchorEl(undefined),
    },
  ]

  const handleMenuItemClick = useCallback(
    (menu: (typeof menus)[0]) => {
      menu.close()
      setPage(1)
      navigateToMenu(menu)
    },
    [dashboardMenuItems, navigate, setPage]
  )

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  if (windowWidth < 1200) {
    return (
      <NestedReportNavigationMenu menus={menus} setPage={setPage} data={data} />
    )
  }

  return (
    <div style={{ display: 'flex', gap: '1.2em', height: '100%' }}>
      {menus.map((menu) => {
        return menu.projects && !menu.projects.includes(projectID) ? null : (
          <div key={menu.name} style={{ position: 'relative' }}>
            {menu.menuItems.length <= 1 &&
            menu.name !== 'KPI System' &&
            menu.name !== 'KPI Sensor' ? (
              <Button
                onClick={() => handleMenuItemClick(menu)}
                data-testid={
                  menu.name +
                  (pathname.includes(menu.routeBase) ? '-active' : '')
                }
                sx={{
                  minWidth: 0,
                  textTransform: 'capitalize',
                  p: 0,
                  color: menu.anchorEl
                    ? '#fff'
                    : pathname.includes(menu.routeBase)
                      ? '#e5e5e5'
                      : '#bbbbbb',
                  '&:hover': {
                    color: '#fff',
                  },
                }}
              >
                {menu.name}
              </Button>
            ) : (
              <>
                <Button
                  disabled={
                    (PROJECT_IDS.MB1.toString() !== getProjectID() &&
                      menu.name === 'KPI System') ||
                    (PROJECT_IDS.MB1.toString() === getProjectID() &&
                      menu.name === 'KPI Sensor')
                  }
                  data-testid={
                    menu.name +
                    (pathname.includes(menu.routeBase) ? '-active' : '')
                  }
                  sx={{
                    minWidth: 0,
                    textTransform: 'capitalize',
                    p: 0,
                    color: menu.anchorEl
                      ? '#fff'
                      : pathname.includes(menu.routeBase)
                        ? '#e5e5e5'
                        : '#bbbbbb',
                    '&:hover': {
                      color: '#fff',
                    },
                  }}
                  onClick={menu.handleClick}
                  disableFocusRipple
                  disableRipple
                  disableTouchRipple
                >
                  {menu.name}
                  <ExpandMoreIcon fontSize='small' />
                </Button>
                <Menu
                  anchorEl={menu.anchorEl}
                  open={Boolean(menu.anchorEl)}
                  onClose={menu.close}
                >
                  {menu.menuItems?.map((item) => {
                    if (shouldSkipElement(projectID, item)) {
                      return null
                    }

                    return menu.name === 'KPI Sensor' ? (
                      <NestedMenuItem
                        key={item}
                        label={item}
                        parentMenuOpen={true}
                      >
                        {item === 'FCM' &&
                          KPIS_SENSOR_FCM_MENU_ITEMS.map((item) => {
                            if (shouldSkipElement(projectID, item)) {
                              return null
                            }
                            return (
                              <MenuItem
                                data-testid={`${menu.name}-${item}`}
                                sx={{ textTransform: 'capitalize' }}
                                key={item}
                                onClick={() => {
                                  menu.close()
                                  setPage(1)
                                  navigateToNestedMenu(menu, item, data)
                                }}
                              >
                                {item}
                              </MenuItem>
                            )
                          })}
                      </NestedMenuItem>
                    ) : (
                      <MenuItem
                        data-testid={`${menu.name}-${item}`}
                        sx={{ textTransform: 'capitalize' }}
                        key={item}
                        onClick={() => {
                          menu.close()
                          setPage(1)
                          if (
                            menu.name !== 'Dashboards' &&
                            menu.name !== 'KPI System'
                          ) {
                            navigate(`${menu.routeBase}${item}`)
                          } else if (
                            menu.name === 'KPI System' &&
                            item === 'TTD'
                          ) {
                            navigate(
                              `${menu.routeBase}${item.toLocaleLowerCase()}`
                            )
                          } else {
                            const chosenDashboard = data?.find(
                              (x) => x.name === item
                            )
                            navigate(`${menu.routeBase}Dashboard`)
                            setDashboardURL(chosenDashboard!.url)
                          }
                        }}
                      >
                        {item}
                      </MenuItem>
                    )
                  })}
                </Menu>
              </>
            )}
            {pathname.includes(menu.routeBase) && (
              <div className='highlight-active-route' />
            )}
          </div>
        )
      })}
    </div>
  )
}
