import { useEffect } from 'react'
import { useReportContext } from './useReportContext'
import {
  INIT_BREADCRUMBS,
  INIT_FILTER_STATE,
  INIT_QUERY_BUILDER_STATE,
} from '../constants/initialStates'
import { ReportType } from '../types'
import { shouldSkipElement } from '../utils'
import { Navigate, useLocation, useParams } from 'react-router-dom'
import { Routes } from '@common/constants/paths'
import { useProjectData } from '@api/table/projects'

interface ReportParams {
  [key: string]: string | undefined
  reportType: ReportType
}

export function useReportChange() {
  const { reportType } = useParams<ReportParams>()
  const { pathname } = useLocation()
  const { projectID } = useProjectData()

  const {
    preserveState,
    queryBuilderRef,
    breadcrumbs,
    setSelectedRowIds,
    setBreadcrumbs,
    setPreserveState,
    setRowSelection,
  } = useReportContext()

  useEffect(() => {
    setRowSelection({})
    setSelectedRowIds(new Set())
    setBreadcrumbs(INIT_BREADCRUMBS)
  }, [reportType])

  useEffect(() => {
    if (!reportType) return
    queryBuilderRef.current?.resetQuery()
    const resetMetaFilters =
      preserveState[reportType].searchType === 'sql'
        ? {
            filterState: INIT_FILTER_STATE,
            filterToSql: {},
          }
        : {}

    setPreserveState({
      ...preserveState,
      [reportType!]: {
        ...preserveState[reportType],
        queryBuilderState: INIT_QUERY_BUILDER_STATE,
        searchType: 'filter',
        ...resetMetaFilters,
      },
    })
    setRowSelection({})
    setSelectedRowIds(new Set())
  }, [breadcrumbs])

  if (shouldSkipElement(projectID, pathname)) {
    return <Navigate to={Routes.INDEX} replace={true} />
  }
}
