import { MediaSyncContext } from '@pages/Details/types/providers'
import { useCallback, useContext } from 'react'
import { useTransportContext } from './useTransportContext'
import { ISynchronizer } from '@common/services/synchronizer/synchronizer'

export function useHandlePlayClick({
  synchronizer,
}: {
  synchronizer?: ISynchronizer | undefined
}) {
  const mediaSyncContext = useContext(MediaSyncContext)
  const { frameRef } = useTransportContext()

  return useCallback(
    (isPlaying: boolean) => {
      if (!mediaSyncContext?.timingObj || !mediaSyncContext?.totalDuration) {
        return
      }

      const hasEnded =
        mediaSyncContext.timingObj.pos >= mediaSyncContext.totalDuration
      if (isPlaying && hasEnded) {
        mediaSyncContext.timingObj.update({ position: 0 })
      }

      const anyStillLoading = synchronizer?.anyLoadingViewports() ?? false
      mediaSyncContext.timingObj.update({
        velocity:
          isPlaying && !anyStillLoading ? mediaSyncContext.playbackSpeed : 0,
      })
      frameRef.current?.blur()
      mediaSyncContext.isPlaying = isPlaying
    },
    [frameRef, mediaSyncContext, synchronizer]
  )
}
