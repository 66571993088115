import { Header } from '@tanstack/react-table'
import { useParams } from 'react-router-dom'
import { TableRouteParams } from '@common/constants/paths'
import { useReportContext } from '..'

export function useSorting(header: Header<any, unknown>) {
  const { reportType } = useParams<TableRouteParams>()
  const {
    searchColumns,
    setPreserveState,
    preserveState,
    setPage,
    breadcrumbs,
  } = useReportContext()
  const sortDirection = header.getContext().column.getIsSorted()
  const headerValue = header.getContext().column.columnDef.header?.toString()
  const headerParentValue = header
    .getContext()
    .column.parent?.columnDef.header?.toString()
  const breadCrumbsLevel = breadcrumbs.length

  const findSortingValue = () => {
    if (!headerValue || !headerParentValue) {
      return
    }

    if (reportType === 'completeness') {
      return searchColumns.find((x) => x.includes(headerValue))?.split('|')[1]
    } else if (reportType === 'quality' || reportType === 'characterization') {
      if (
        headerParentValue === 'Drive Trial Identification' ||
        headerParentValue === 'Drive Data' ||
        headerParentValue === 'Tags' ||
        headerParentValue === 'Gps Speed'
      ) {
        if (
          headerValue === 'DTID' &&
          (breadCrumbsLevel === 2 || breadCrumbsLevel === 3)
        ) {
          return searchColumns[0].split('|')[1]
        }

        return searchColumns.find((x) => x.includes(headerValue))?.split('|')[1]
      }

      const toLowerSearchColumns = searchColumns.map((x) =>
        x.toLocaleLowerCase()
      )

      return toLowerSearchColumns
        .find(
          (x) =>
            x.includes(headerValue.toLocaleLowerCase()) &&
            x.includes(headerParentValue.toLocaleLowerCase().replace(/ /g, ''))
        )
        ?.split('|')[1]
    } else {
      if (headerParentValue === 'Drive Trial Identification') {
        return headerValue
      } else {
        return `${headerParentValue}|${headerValue}`
      }
    }
  }

  const handleSorting = () => {
    let sortBy = findSortingValue()

    if (sortBy === 'geo_country_compoundlabel') {
      sortBy = 'Geo_Country_CompoundLabel'
    }

    const newSortDirection =
      !sortDirection || sortDirection === 'asc' ? 'desc' : 'asc'

    const sortingConfig = {
      sorting: [{ id: header.id, desc: newSortDirection === 'desc' }],
      fetchByOrder: { sortBy, sortDirection: newSortDirection },
    }

    if (sortBy) {
      let newState

      if (breadCrumbsLevel === 2) {
        newState = { sections: sortingConfig }
      } else if (breadCrumbsLevel === 3) {
        newState = { details: sortingConfig }
      } else {
        newState = sortingConfig
      }

      setPreserveState({
        ...preserveState,
        [reportType!]: {
          ...preserveState[reportType!],
          ...newState,
        },
      })
    }

    setPage(1)
  }

  return { handleSorting, sortDirection }
}
