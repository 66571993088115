import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import { IconButton } from '@mui/material'
import { ITimelineContext } from '@modules/timelineViewport'
import { camelCase } from 'lodash'
import { IdType, TimelineGroup } from 'otto-vis-timeline/types'
import { createRoot } from 'react-dom/client'
import { hideShow } from '@modules/timelineViewport/utils/tags'

interface CustomGroupTemplateProps {
  group: TimelineGroup
  element: HTMLDivElement
  handleTimelineMenu: (
    event: React.MouseEvent<HTMLButtonElement>,
    group: IdType
  ) => void
  viewportId: number
  timelineContext: ITimelineContext
}

export function CustomGroupTemplate({
  element,
  group,
  handleTimelineMenu,
  viewportId,
  timelineContext,
}: CustomGroupTemplateProps) {
  const handleShowHide = () => {
    const groupId = +group.id
    const { hidden, setHidden } = timelineContext
    hideShow(timelineContext, groupId)
    const element = document.getElementById(`group-tags-visibility-${group.id}`)
    if (element) {
      element.style.display = 'none'
      setHidden(hidden.filter((item) => item !== groupId))
    }
  }
  if (!group || !element) return ''

  const renderMoreIcon = !Object.hasOwn(group, 'nestedGroups')
  const content = camelCase(group.title as string)

  element.setAttribute(
    'data-testid',
    'timelineGroup-' + content + '-' + viewportId
  )

  element
  element.style.width = '100%'

  const container = document.createElement('div')
  container.id = `group-template-${group.id}`
  container.style.width = '100%'
  container.style.height = '100%'
  container.style.display = 'flex'
  container.style.justifyContent = 'space-between'
  container.style.alignItems = 'center'

  const label = document.createElement('span')
  label.innerHTML = group.content as string
  container.appendChild(label)

  const root = createRoot(container)
  root.render(
    <>
      <div style={{ color: 'white', display: 'flex', alignItems: 'center' }}>
        {group.nestedGroups && (
          <KeyboardArrowDownIcon
            className='timeline-dropdown'
            sx={{
              transform: !group.showNested ? 'rotate(-90deg)' : 'rotate(0)',
              color: !group.showNested ? '#b7b7b7' : 'whitesmoke',
            }}
          />
        )}
        {renderMoreIcon && (
          <IconButton
            data-testid={`dragButton-${content}-${viewportId}`}
            sx={{ padding: 0, marginRight: 1, marginLeft: 0.5 }}
            disableFocusRipple
            disableRipple
            disableTouchRipple
          >
            <DragIndicatorIcon fontSize='small' sx={{ color: 'transparent' }} />
          </IconButton>
        )}
        {group.content as string}
      </div>
      {renderMoreIcon && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div
            id={`group-tags-visibility-${group.id}`}
            style={{ display: 'none' }}
          >
            <IconButton
              data-testid={`hide_show-${content}-${viewportId}`}
              sx={{ padding: 0, marginRight: 1, marginLeft: 0.5 }}
              disableFocusRipple
              disableRipple
              disableTouchRipple
              onClick={handleShowHide}
            >
              <VisibilityOffIcon
                fontSize='small'
                sx={{ color: 'whitesmoke' }}
              />
            </IconButton>
          </div>
          <IconButton
            className='timeline-track-menu-button'
            data-testid={`groupMenu-${content}-${viewportId}`}
            sx={{ padding: 0, marginRight: 1 }}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              handleTimelineMenu(e, group.id)
            }}
            disableFocusRipple
            disableRipple
            disableTouchRipple
          >
            <MoreVertIcon
              className='timeline-track-menu-icon'
              fontSize='small'
            />
          </IconButton>
        </div>
      )}
    </>
  )
  return container
}
