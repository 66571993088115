import { SourceType } from '@api/objects'
import { ISynchronizer } from '@common/services/synchronizer/synchronizer'
import { ObjectColor } from '@modules/videoViewport'
import { camelCase } from 'lodash'
import { EnabledLayers } from '@pages/Details/types/layersPanel'
import { LoadingContext } from '@pages/Details/types/providers'
import { useCallback, useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { selectViewportLayout } from 'store/details/viewport/selectors'
import { selectViewportState } from 'store/details/viewportData/selectors'
import {
  getCanvasObjectType,
  getVisibleCanvases,
} from '../../utils/canvasLayers'
import { Canvas } from '../Canvas'
import { ReportType } from '@modules/reportTable'

export interface Dimensions {
  width: number
  height: number
}

interface CanvasLayersProps {
  videoId: number
  playerId: string
  DTID: number
  parentDTID: number
  colors: ObjectColor[]
  canvasSize: Dimensions
  viewportId: number
  synchronizer: ISynchronizer | undefined
  isFullscreen: boolean
}

export function CanvasLayers({
  videoId,
  DTID,
  parentDTID,
  colors,
  canvasSize,
  viewportId,
  synchronizer,
  isFullscreen,
}: CanvasLayersProps) {
  const { reportType } = useParams()
  const viewportContent = useSelector(selectViewportLayout(viewportId))
  const { clearUnusedCanvases } = useContext(LoadingContext)

  const enabledLayers = useSelector(
    selectViewportState(viewportId)
  ) as EnabledLayers

  useEffect(() => {
    const data = [
      ...enabledLayers.me.map((x) => `${getCanvasObjectType(x)}-me`),
      ...enabledLayers.otto.map((x) => `${getCanvasObjectType(x)}-otto`),
    ]
    synchronizer?.clearUnusedCanvases(viewportContent, data)
    clearUnusedCanvases(data)

    //needs to be triggered only on change of enabledLayers
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabledLayers])

  const renderCanvases = useCallback(
    (objects: string[], source: SourceType) => {
      const canvases = getVisibleCanvases(
        objects,
        colors,
        source,
        reportType as ReportType
      )

      return canvases.map((obj) => {
        const dataTestId = `layer-${source}-${camelCase(
          obj.name
        )}-${viewportId}-canvas`

        return (
          <Canvas
            isFullscreen={isFullscreen}
            key={`canvas-${obj.name}-${videoId}-${viewportContent}`}
            videoId={videoId}
            dataTestId={dataTestId}
            size={canvasSize}
            DTID={DTID}
            parentDTID={parentDTID}
            colors={colors}
            sourceType={source}
            objectType={obj.name}
            camera={viewportContent}
            synchronizer={synchronizer}
            viewportId={viewportId}
            lineType={
              obj.name === 'egoLane' || obj.name === 'egoLabel'
                ? 'EGO'
                : obj.name === 'adjacentLane' || obj.name === 'adjacentLabel'
                  ? 'ADJACENT'
                  : undefined
            }
          />
        )
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      viewportContent,
      DTID,
      parentDTID,
      videoId,
      canvasSize,
      colors,
      viewportId,
      isFullscreen,
    ]
  )

  return (
    <>
      {renderCanvases(enabledLayers?.me, 'me')}
      {renderCanvases(enabledLayers?.otto, 'otto')}
    </>
  )
}
