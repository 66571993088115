import { useReportNavigationContext } from '@modules/header/hooks/useReportNavigationContext'
import { createToken } from '@pages/Dashboard/utils/tableau'

export function useGetTableauParams() {
  const { dashboardURL } = useReportNavigationContext()
  const userid = process.env.REACT_APP_TABLEAU_USER || ''
  const iss = process.env.REACT_APP_TABLEAU_ID || ''
  const kid = process.env.REACT_APP_TABLEAU_SECRET_ID || ''
  const secret = process.env.REACT_APP_TABLEAU_CREATOR_SECRET_VALUE || ''
  if (!userid || !iss || !kid || !secret || !dashboardURL) {
    return {
      wurl: '',
      token: '',
    }
  }

  const token = createToken(userid, kid, secret, iss)

  return {
    token,
    wurl: dashboardURL,
  }
}
