import OlGeoJSON from 'ol/format/GeoJSON'
import { Projection, fromLonLat } from 'ol/proj'
import { createLineStyle, createPointStyle, source } from './map'
import { Point } from 'ol/geom'
import Feature from 'ol/Feature'
import { GeoJSONObject } from '@api/topViewMap'

export const showJSON = (geojsonObject: GeoJSONObject) => {
  const interpolatedCoords = geojsonObject.features.find(
    (x) => x.properties?.layer === 'interpolated_gps'
  )?.geometry.coordinates

  if (!interpolatedCoords) return

  const features = new OlGeoJSON().readFeatures(geojsonObject, {
    featureProjection: new Projection({ code: 'EPSG:3857' }),
  })

  const interpolatedFeature = features.find(
    (x) => x.getProperties().layer === 'interpolated_gps'
  )

  if (interpolatedFeature) {
    interpolatedFeature.setId('' + Date.now())
    interpolatedFeature.setStyle(createLineStyle('#ff0000', 3))
    source.addFeature(interpolatedFeature)
  }

  const coords20Hz = []

  for (let i = 0; i < interpolatedCoords.length - 1; i++) {
    const vv = interpolatedCoords[i]
    const cc = [vv[0], vv[1]]
    const point = fromLonLat(cc)
    const point_thing = new Point(point)

    coords20Hz.push([point[0], point[1]])

    if (i % 20 === 0) {
      const id = i + '' + Date.now()
      const pfeaturething = new Feature({
        name: 'Point',
        obj: id,
        geometry: point_thing,
      })
      pfeaturething.setId(id)
      if (i % 100 === 0)
        pfeaturething.setStyle(createPointStyle('+', '#00ff00', 1))
      else pfeaturething.setStyle(createPointStyle('-', '#00ff00', 1))

      source.addFeature(pfeaturething)
    }
  }

  const layers = features.map((feature) => {
    if (
      feature.getProperties().color &&
      feature.getProperties().layer !== 'interpolated_gps'
    ) {
      feature.setStyle(createLineStyle(feature.getProperties().color, 2))
    }
    return feature
  })

  source.addFeatures(layers)

  return coords20Hz
}
