import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useState,
} from 'react'
import { JiraTeam } from '../types/jira'
import { DateType } from 'otto-vis-timeline'

type DispositionType = 'otto' | 'jira'
type IssueType = 'Bug' | 'Task' | undefined

export interface TagPopupContextState {
  anchorTagPopup: HTMLDivElement | undefined
  text: string
  summary: string
  disposition: DispositionType
  issueType: IssueType
  jiraIssueID: string
  start: DateType
  end: DateType
  isLoading: boolean
  captureScreenshotOnClose: boolean
  team: JiraTeam
  jiraProjectID: string | undefined
  activeTag: number | undefined
}

export interface TagPopupContextDispatch {
  setText: Dispatch<SetStateAction<string>>
  setSummary: Dispatch<SetStateAction<string>>
  setDisposition: Dispatch<SetStateAction<DispositionType>>
  setIssueType: Dispatch<SetStateAction<IssueType>>
  setJiraIssueID: Dispatch<SetStateAction<string>>
  setStart: Dispatch<SetStateAction<DateType>>
  setEnd: Dispatch<SetStateAction<DateType>>
  setIsLoading: Dispatch<SetStateAction<boolean>>
  setCaptureScreenshotOnClose: Dispatch<SetStateAction<boolean>>
  setTeam: Dispatch<SetStateAction<JiraTeam>>
  setAnchorTagPopup: Dispatch<SetStateAction<HTMLDivElement | undefined>>
  setActiveTag: Dispatch<SetStateAction<number | undefined>>
}

export interface TagPopupContextType
  extends TagPopupContextState,
    TagPopupContextDispatch {}

export const TagPopupContext = createContext<TagPopupContextType>(
  {} as TagPopupContextType
)

export function TagPopupContextProvider({ children }: PropsWithChildren) {
  const jiraProjectID = process.env.REACT_APP_JIRA_PROJECT_ID

  // status
  const [activeTag, setActiveTag] = useState<number>()
  const [isLoading, setIsLoading] = useState(false)
  const [anchorTagPopup, setAnchorTagPopup] = useState<HTMLDivElement>()

  // tag popup content
  const [text, setText] = useState('')
  const [summary, setSummary] = useState('')
  const [disposition, setDisposition] = useState<DispositionType>('otto')
  const [issueType, setIssueType] = useState<IssueType>(undefined)
  const [jiraIssueID, setJiraIssueID] = useState('')
  const [team, setTeam] = useState<JiraTeam>('FE')
  const [captureScreenshotOnClose, setCaptureScreenshotOnClose] =
    useState(false)

  // time
  const [start, setStart] = useState<DateType>(0)
  const [end, setEnd] = useState<DateType>(0)

  const value = {
    text,
    summary,
    disposition,
    issueType,
    jiraIssueID,
    start,
    end,
    isLoading,
    captureScreenshotOnClose,
    team,
    jiraProjectID,
    anchorTagPopup,
    activeTag,
    setText,
    setSummary,
    setDisposition,
    setIssueType,
    setJiraIssueID,
    setStart,
    setEnd,
    setIsLoading,
    setCaptureScreenshotOnClose,
    setTeam,
    setAnchorTagPopup,
    setActiveTag,
  }

  return (
    <TagPopupContext.Provider value={value}>
      {children}
    </TagPopupContext.Provider>
  )
}
