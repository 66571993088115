import { DelegateType } from '@api/sprGallery/spr-gallery'
import { useProjectData } from '@api/table/projects'
import { getDays } from '@modules/gallery'
import { ArrowLeft, ArrowRight } from '@mui/icons-material'
import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import { ChangeEvent } from 'react'
import { useSearchParams } from 'react-router-dom'
import './styles.scss'

type SidebarProps = {
  day: number
  frameId?: number
  dtid?: number
  delegateType: DelegateType
}

const DAYS_LIMIT = 15

export function Sidebar({ day, frameId, dtid, delegateType }: SidebarProps) {
  const [searchParams, setSearchParams] = useSearchParams()
  const { projectID } = useProjectData()

  const daysPage = Number(searchParams.get('daysPage') || 0)

  const daysList = getDays(projectID)
  const totalDays = daysList[daysList.length - 1]
  const daysStart = daysPage * DAYS_LIMIT
  const daysEnd = daysStart + DAYS_LIMIT
  const isLastDaysPage = daysPage >= Math.floor(Number(totalDays) / DAYS_LIMIT)

  const isDelegateView = !frameId || !dtid

  const labelStyle = (isActive = false) => ({
    transition: 'all 0.13s ease-in',
    fontSize: '1rem',
    color: 'white',
    opacity: isActive ? 1 : 0.6,
    ':hover': {
      opacity: '0.9',
    },
  })

  const handleDayChange = (day: number) => {
    setSearchParams((sp) => {
      sp.set('day', String(day))
      sp.delete('dtid')
      sp.delete('frameId')
      sp.delete('prevDelegatePage')
      sp.set('page', '1')
      return sp
    })
  }

  const handleTypeChange = (
    _: ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    setSearchParams((sp) => {
      sp.set('delegateType', value)
      sp.delete('page')
      return sp
    })
  }

  const handlePrevDaysPage = () => {
    if (daysPage > 0) {
      setSearchParams((sp) => {
        sp.set('daysPage', String(daysPage - 1))
        return sp
      })
    }
  }

  const handleNextDaysPage = () => {
    if (!isLastDaysPage) {
      setSearchParams((sp) => {
        sp.set('daysPage', String(daysPage + 1))
        return sp
      })
    }
  }

  return (
    <div className='spr-gallery-sidebar'>
      <FormControl>
        <Box sx={{ display: 'flex', alignItems: 'center', color: 'white' }}>
          <Typography fontSize={'0.8rem'}>Days</Typography>

          {totalDays > DAYS_LIMIT ? (
            <ButtonGroup size='small' sx={{ ml: 'auto', padding: 0 }}>
              <IconButton
                autoFocus={false}
                onClick={handlePrevDaysPage}
                disabled={daysPage <= 0}
                sx={{
                  padding: 0,
                  ':disabled': {
                    opacity: 0.6,
                  },
                }}
              >
                <ArrowLeft sx={{ color: 'whitesmoke' }} />
              </IconButton>

              <IconButton
                autoFocus={false}
                onClick={handleNextDaysPage}
                disabled={isLastDaysPage}
                sx={{
                  padding: 0,
                  ':disabled': {
                    opacity: 0.6,
                  },
                }}
              >
                <ArrowRight sx={{ color: 'whitesmoke' }} />
              </IconButton>
            </ButtonGroup>
          ) : null}
        </Box>
        <ButtonGroup
          orientation='vertical'
          variant='text'
          sx={{ whiteSpace: 'nowrap' }}
        >
          {daysList.slice(daysStart, daysEnd).map((current) => (
            <Button
              key={current}
              sx={{
                px: 0,
                display: 'flex',
                justifyContent: 'flex-start',
                color: day === current ? '#fff' : '#BBB',
                ':hover': { color: '#fff' },
              }}
              onClick={() => handleDayChange(current)}
            >
              <Typography fontSize={'0.85rem'}>Day {current}</Typography>
            </Button>
          ))}
        </ButtonGroup>
      </FormControl>

      {isDelegateView ? (
        <>
          <FormControl>
            <FormLabel>
              <Typography fontSize={'0.8rem'}>Type</Typography>
            </FormLabel>
            <RadioGroup
              onChange={handleTypeChange}
              value={delegateType}
              defaultValue={DelegateType.RARE}
              name='radio-buttons-group'
            >
              <FormControlLabel
                value={DelegateType.RARE}
                control={<Radio size='small' />}
                label={
                  <Typography
                    fontSize={'0.85rem'}
                    sx={labelStyle(delegateType === DelegateType.RARE)}
                  >
                    Rare
                  </Typography>
                }
              />
              <FormControlLabel
                value={DelegateType.COMMON}
                control={<Radio size='small' />}
                label={
                  <Typography
                    fontSize={'0.85rem'}
                    sx={labelStyle(delegateType === DelegateType.COMMON)}
                  >
                    Common
                  </Typography>
                }
              />
            </RadioGroup>
          </FormControl>
        </>
      ) : null}
    </div>
  )
}
