import { IconButton } from '@mui/material'
import { Header } from '@tanstack/react-table'
import { SortingIcon } from '@common/components/SortingIcon/SortingIcon'
import { useSorting } from '@modules/reportTable'

interface SortingProps {
  header: Header<any, unknown>
}

export function Sorting({ header }: SortingProps) {
  const { handleSorting, sortDirection } = useSorting(header)

  return (
    <IconButton
      data-testid={`sorting-${sortDirection}-${header.id}`}
      sx={{ padding: 0, marginRight: 3 }}
      onClick={handleSorting}
    >
      <SortingIcon sortDirection={sortDirection} />
    </IconButton>
  )
}
