import { enUS } from '@common/constants/messages'
import { enqueueSnackbar } from 'notistack'
import { useCallback } from 'react'
import { useMapContext } from './useMapContext'

export function useCopyCoordinates() {
  const { coordinatesRef } = useMapContext()

  return useCallback(() => {
    if (coordinatesRef.current?.innerText) {
      navigator.clipboard
        .writeText(coordinatesRef.current?.innerText)
        .then(() => {
          coordinatesRef.current!.classList.add('copied')
          setTimeout(() => {
            if (coordinatesRef.current) {
              coordinatesRef.current.classList.remove('copied')
            }
          }, 1000)
          enqueueSnackbar({
            message: enUS.COORDINATES_COPIED_TO_CLIPBOARD,
            variant: 'info',
          })
        })
    }
  }, [coordinatesRef])
}
