import { useQuery } from '@tanstack/react-query'
import axios, { AxiosResponse } from 'axios'
import { useLocation, useParams } from 'react-router-dom'
import {
  DEFAULT_PAYLOAD_LANES_KPI,
  DEFAULT_PAYLOAD_SIGNS_KPI,
  DEFAULT_PAYLOAD_LIGHTS_KPI,
} from './defaultKPIPayloads'
import { TableRouteParams } from '@common/constants/paths'
import { TableResponse } from '../../models/table'
import { getClientID } from '@common/utils/storage'
import { urls } from '../urls'
import { useAuthContext } from '@modules/auth'
import { useReportContext } from '@modules/reportTable'

export const useKpiSensorTableQuery = () => {
  const { reportType } = useParams<TableRouteParams>()
  const { pathname } = useLocation()
  const {
    preserveState,
    limit,
    page,
    hilVersion,
    projectID,
    publishCounter,
    setSearchColumns,
  } = useReportContext()
  const { adminLevelID } = useAuthContext()

  const offset = (page - 1) * limit

  const lanesPayload = {
    ...DEFAULT_PAYLOAD_LANES_KPI,
    clientId: getClientID(),
    projectId: projectID,
    offset,
    limit,
    startDate: '',
    endDate: '',
    timeOfDay: '',
    weatherConditions: '',
    roadType: '',
    clause: preserveState.lanes.clause,
    version: hilVersion === 'all' ? '' : hilVersion,
    sortColumn: preserveState.lanes.fetchByOrder.sortBy,
    sortOrder: preserveState.lanes.fetchByOrder.sortDirection,
    ...preserveState.lanes.filterKpiToSql,
    adminLevelID: adminLevelID || 0,
  }

  const signsPayload = {
    ...DEFAULT_PAYLOAD_SIGNS_KPI,
    clientId: getClientID(),
    projectId: projectID,
    offset,
    limit,
    speedRange: '',
    startDate: '',
    endDate: '',
    timeOfDay: '',
    weatherConditions: '',
    roadType: '',
    clause: preserveState.signs.clause,
    version: hilVersion === 'all' ? '' : hilVersion,
    sortColumn: preserveState.signs.fetchByOrder.sortBy,
    sortOrder: preserveState.signs.fetchByOrder.sortDirection,
    ...preserveState.signs.filterKpiToSql,
    adminLevelID: adminLevelID || 0,
  }

  const lightsPayload = {
    ...DEFAULT_PAYLOAD_LIGHTS_KPI,
    clientId: getClientID(),
    projectId: projectID,
    offset,
    limit,
    speedRange: '',
    startDate: '',
    endDate: '',
    timeOfDay: '',
    weatherConditions: '',
    roadType: '',
    clause: preserveState.lights.clause,
    version: hilVersion === 'all' ? '' : hilVersion,
    sortColumn: preserveState.lights.fetchByOrder.sortBy,
    sortOrder: preserveState.lights.fetchByOrder.sortDirection,
    ...preserveState.lights.filterKpiToSql,
    adminLevelID: adminLevelID || 0,
  }

  return useQuery({
    enabled: pathname.includes('kpi'),
    queryKey: [
      'kpiSensorTable',
      lanesPayload,
      signsPayload,
      lightsPayload,
      reportType,
      publishCounter,
    ],
    staleTime: Infinity,
    refetchOnWindowFocus: false,
    queryFn: (): Promise<AxiosResponse<TableResponse>> => {
      if (reportType === 'lights') {
        return axios.post(urls.kpiSummaryLights, lightsPayload).then((resp) => {
          setSearchColumns(resp.data.searchColumns)
          return resp
        })
      } else if (reportType === 'signs') {
        return axios.post(urls.kpiSummarySigns, signsPayload).then((resp) => {
          setSearchColumns(resp.data.searchColumns)
          return resp
        })
      } else {
        return axios.post(urls.kpiSummaryLanes, lanesPayload).then((resp) => {
          setSearchColumns(resp.data.searchColumns)
          return resp
        })
      }
    },
  })
}
