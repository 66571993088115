import { useActiveTrial } from '@common/hooks/useActiveTrial'
import { Typography } from '@mui/material'
import { PropsWithChildren } from 'react'
import { useLocation } from 'react-router-dom'
import './style.scss'

export const HeaderTitle = ({ children }: PropsWithChildren) => {
  const location = useLocation()
  const isDetailsPage = location.pathname.includes('details')
  const { activeTrial } = useActiveTrial()

  if (children) return <>{children}</>

  return (
    <Typography
      {...(!isDetailsPage && { className: 'heading', variant: 'h5' })}
    >
      {isDetailsPage && (
        <span className='header-title'>
          Drive ID:<span> {activeTrial?.DTID}</span>{' '}
          {activeTrial?.version && (
            <>
              HIL:
              <span> {activeTrial?.version}</span>
            </>
          )}
        </span>
      )}
    </Typography>
  )
}
