import { source, view } from '@modules/topViewMapViewport/utils/map'
import { Map } from 'ol'
import TileLayer from 'ol/layer/Tile'
import VectorLayer from 'ol/layer/Vector'
import { BingMaps, TileImage } from 'ol/source'
import Stroke from 'ol/style/Stroke'
import Style from 'ol/style/Style'

const featuresLayer = new VectorLayer({
  source: source,
  style: new Style({
    stroke: new Stroke({
      color: '#00ff00',
      width: 2,
    }),
  }),
})

export const TopViewMapElement = new Map({
  layers: [
    new TileLayer({
      visible: false,
      source: new TileImage({
        projection: 'proj3857D',
        url: 'http://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}',
      }),
    }),
    new TileLayer({
      visible: true,
      source: new BingMaps({
        key: 'Aq9R6UW215ihWE5oxEc2GpBckZFYnaV5bkpETNrX5gkww51KeYhfo4GVK2Zz7bdT',
        imagerySet: 'Aerial',
      }),
    }),
    featuresLayer,
  ],
  view: view,
  controls: [],
}) as Map
