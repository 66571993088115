import { toMilliseconds, toSeconds } from '@common/utils/time'
import {
  animation,
  TimelineItemData,
  timelineTagId,
  useTimelineContext,
} from '@modules/timelineViewport'
import { MediaSyncContext } from '@pages/Details/types/providers'
import { enqueueSnackbar } from 'notistack'
import { useCallback, useContext } from 'react'
import { notEmpty } from '@common/utils/misc'
import dayjs from 'dayjs'

export function useSeekToTag() {
  const mediaSyncCtx = useContext(MediaSyncContext)
  const timelineCtx = useTimelineContext()

  return useCallback(
    (greaterThanCurrentTime: boolean) => {
      if (!mediaSyncCtx.timingObj) {
        return
      }

      const currentTime = mediaSyncCtx.timingObj.pos
      let tags = timelineCtx.items
        .map((x) => {
          if ((x.id as string).includes(timelineTagId)) {
            return x
          }

          return undefined
        })
        .filter(notEmpty)
        .sort((a, b) => +a.start - +b.start)

      let tagToSeek: TimelineItemData | undefined = undefined
      const compareTime = Math.round(toMilliseconds(currentTime))
      if (greaterThanCurrentTime) {
        tagToSeek = tags.find((x) => +x.start > compareTime)
      } else {
        tags = tags.reverse()
        tagToSeek = tags.find((x) => +x.start < compareTime)
      }

      if (tagToSeek) {
        mediaSyncCtx.isSeeking = true
        const position = toSeconds(+tagToSeek.start)
        mediaSyncCtx.timingObj.update({
          position,
          velocity: 0,
        })
        setTimeout(() => {
          if (tagToSeek) {
            timelineCtx.timeline?.moveTo(
              dayjs(+tagToSeek.start).toDate(),
              animation
            )
          }
        }, 0)
      } else {
        enqueueSnackbar({
          message: 'No next tag to seek to',
          variant: 'info',
        })
      }
    },
    [mediaSyncCtx, timelineCtx.items, timelineCtx.timeline]
  )
}
