import { useEffect } from 'react'
import { useReportContext } from './useReportContext'
import { INIT_BREADCRUMBS } from '../constants/initialStates'
import { shouldSkipElement } from '../utils'
import { Navigate, useLocation } from 'react-router-dom'
import { Routes } from '@common/constants/paths'
import { useProjectData } from '@api/table/projects'

export function useProjectChange() {
  const { pathname } = useLocation()
  const { projectID } = useProjectData()

  const { setSelectedRowIds, setBreadcrumbs, setRowSelection } =
    useReportContext()

  useEffect(() => {
    setRowSelection({})
    setSelectedRowIds(new Set())
    setBreadcrumbs(INIT_BREADCRUMBS)
  }, [projectID])

  if (shouldSkipElement(projectID, pathname)) {
    return <Navigate to={Routes.INDEX} replace={true} />
  }
}
