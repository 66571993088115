import { useDriveTrialContext } from '@pages/Details/providers/DriveTrialDataProvider'
import { useMemo } from 'react'

export function useMapColors() {
  const { highlightMode } = useDriveTrialContext()
  const startMarkerPositionColor = 'rgba(0, 255, 0, 0.7)'
  const endMarkerPositionColor = 'rgba(255, 0, 0, 0.7)'
  const startEndMarkerDriveTrialBorder = '1px solid blue'

  return useMemo(() => {
    const gpsPathActiveColor = {
      'line-color': `${
        highlightMode.id !== -1
          ? 'rgba(66, 133, 244, 0.5)'
          : 'rgba(66, 133, 244, 1)'
      }`,
      'line-width': 4,
    }

    const gpsPathColor = {
      'line-color': '#874EFE',
      'line-width': 2,
      'line-opacity': 0.9,
    }
    const gpsPathHighColor = {
      'line-color': '#FF0000',
      'line-width': 4,
    }

    return {
      startMarkerPositionColor,
      endMarkerPositionColor,
      startEndMarkerDriveTrialBorder,
      gpsPathColor,
      gpsPathHighColor,
      gpsPathActiveColor,
    }
  }, [highlightMode.id])
}
