import { useMapQuery } from '@api/index'
import { Loader } from '@common/components/Loader/Loader'
import { NoData } from '@common/components/NoData/NoData'
import { isDevelopmentOrLocal } from '@common/constants/env'
import { enUS } from '@common/constants/messages'
import { useActiveTrial } from '@common/hooks/useActiveTrial'
import {
  useNavigationArrowImage,
  useMapCoordinatesAndBounds,
  useCopyCoordinates,
  useGetCurrentCoordinate,
  useHandleDrag,
  useHandleMapClick,
  useHandleMapInteraction,
  useHandleMapInteractions,
  useHandleMouseDown,
  useHandleMouseOver,
  useHandleMouseOverRoute,
  useHandleMouseUp,
  useHandleRecenter,
  useHandleTimeChange,
  useMapColors,
  useMapContext,
} from '@modules/mapViewport'
import { NavigationOutlined } from '@mui/icons-material'
import { Typography } from '@mui/material'
import Box from '@mui/material/Box/Box'
import Button from '@mui/material/Button'
import { useDriveTrialContext } from '@pages/Details/providers/DriveTrialDataProvider'
import { MediaSyncContext } from '@pages/Details/types/providers'
import React, { useContext, useImperativeHandle } from 'react'
import Map from 'react-map-gl'
import { getViewportLocalStorageState } from '../../../../store/details/viewportStateUtils'
import GpsSource from '../../components/GpsSource'
import MarkerSource from '../../components/MarkerSource'
import PositionMarker from '../../components/PositionMarker'
import { MapViewProps } from '../../types/map'
import { extractBackgroundColor, rgbaToHex } from '../../utils/map'
import './style.scss'

export function MapView({
  synchronizer,
  viewportId,
  isFullscreen,
}: MapViewProps) {
  const {
    coordinatesRef,
    gpsHighCoordinates,
    driveGps,
    startEndMarkers,
    mouseDown,
    dragMap,
    showFollowButton,
    followMode,
    mapBounds,
    mainHighlightItems,
    setFollowMode,
    setShowFollowButton,
    markerRef,
    mapRef,
    mapOptionsRef,
  } = useMapContext()

  const mediaSyncContext = useContext(MediaSyncContext)
  const { getDriveTrialById, modeKey } = useDriveTrialContext()
  const { isLoading, isError } = useMapQuery()

  const currentMapViewportState = getViewportLocalStorageState()
  const getCurrentCoordinate = useGetCurrentCoordinate()
  const copyCoordinatesToClipboard = useCopyCoordinates()

  const recenterMap = useHandleRecenter()
  const handleMapInteractions = useHandleMapInteractions()
  const handleMapClick = useHandleMapClick()
  const handleMouseOver = useHandleMouseOver()
  const handleMouseOverRoute = useHandleMouseOverRoute()
  const handleDrag = useHandleDrag()
  const handleTimeChange = useHandleTimeChange()
  const handleMouseUp = useHandleMouseUp()
  const handleMouseDown = useHandleMouseDown()

  const {
    startMarkerPositionColor,
    endMarkerPositionColor,
    startEndMarkerDriveTrialBorder,
    gpsPathColor,
    gpsPathHighColor,
    gpsPathActiveColor,
  } = useMapColors()

  useHandleMapInteraction({
    synchronizer,
    viewportId,
    isFullscreen,
  })
  useMapCoordinatesAndBounds({
    synchronizer,
    viewportId,
  })
  useNavigationArrowImage()

  useImperativeHandle(
    mapOptionsRef,
    () => ({
      followMode,
      setFollowMode,
      showFollowButton,
      setShowFollowButton,
    }),
    [followMode, setFollowMode, setShowFollowButton, showFollowButton]
  )

  useActiveTrial(handleTimeChange)

  if (isLoading) {
    return <Loader text='Loading map' center />
  }

  if (isError) {
    return <NoData languageCode='enUS' />
  }

  return (
    <Box
      component='div'
      sx={{
        '& .mapboxgl-control-container': {
          display: 'none',
        },
        '& div[mapboxgl-children]': {
          display: 'none',
        },
        '& .mapboxgl-ctrl-attrib-button': {
          display: 'none',
        },
        position: 'relative',
        width: '100%',
      }}
    >
      {isDevelopmentOrLocal && (
        <span
          className='lat-lng-text'
          ref={coordinatesRef}
          onClick={copyCoordinatesToClipboard}
        />
      )}
      <Map
        key={modeKey}
        ref={mapRef}
        dragPan={dragMap}
        pitchWithRotate={false}
        onDragStart={(event) => {
          handleMapInteractions({ ...event })
        }}
        onRotateStart={(event) => {
          handleMapInteractions({ ...event })
        }}
        onZoomStart={(event) => {
          handleMapInteractions({ ...event })
        }}
        onClick={handleMapClick}
        initialViewState={{
          longitude: (mapBounds._ne.lng + mapBounds._sw.lng) / 2,
          latitude: (mapBounds._ne.lat + mapBounds._sw.lat) / 2,
          pitch: 0,
        }}
        style={{
          position: 'absolute',
          width: '100%',
        }}
        keyboard={false}
        mapStyle={
          currentMapViewportState &&
          currentMapViewportState[viewportId].mapStyle === 'street'
            ? 'mapbox://styles/mapbox/streets-v11'
            : 'mapbox://styles/mapbox/satellite-v9'
        }
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
        onMouseMove={(e) => {
          handleMouseOver(e)
          handleMouseOverRoute(e)
          if (!dragMap && mouseDown) {
            handleDrag(e)
          }
        }}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
      >
        {driveGps.map((drive) => (
          <React.Fragment key={drive.key}>
            <GpsSource
              key={drive.key}
              driveKey={drive.key}
              paint={
                drive.key !==
                getDriveTrialById(mediaSyncContext.activeVideoId!)?.DTID
                  ? gpsPathColor
                  : gpsPathActiveColor
              }
              route={drive.coordinates}
            />
          </React.Fragment>
        ))}
        {gpsHighCoordinates.map((g, index) => {
          const highlightItem = mainHighlightItems![index]
          const paint = highlightItem
            ? {
                'line-color':
                  rgbaToHex(extractBackgroundColor(highlightItem.style)) ??
                  gpsPathHighColor['line-color'],
                'line-width': 4,
              }
            : gpsPathHighColor

          return (
            <GpsSource
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              driveKey={index}
              paint={paint}
              route={g}
            />
          )
        })}
        {startEndMarkers.map((markers) =>
          markers.map((marker, index) => (
            <PositionMarker
              key={marker.key}
              longitude={marker.lng}
              latitude={marker.lat}
              viewportId={viewportId}
              color={
                marker.position.split('-')[1] === 'end'
                  ? endMarkerPositionColor
                  : startMarkerPositionColor
              }
              anchor='bottom'
              title={
                marker.position.split('-')[1] === 'end'
                  ? enUS.END_DRIVE_TRAIL_ID
                  : enUS.START_DRIVE_TRAIL_ID
              }
              DTID={marker.key}
              offsetY={-25 * (index + 1)}
              activeBorder={
                +(
                  getDriveTrialById(mediaSyncContext.activeVideoId!)?.DTID || 0
                ) === marker.key
                  ? startEndMarkerDriveTrialBorder
                  : ''
              }
            />
          ))
        )}
        <MarkerSource
          icon={'navigationArrow'}
          initPosition={getCurrentCoordinate()}
          ref={markerRef}
        />
      </Map>
      {showFollowButton && (
        <Button
          startIcon={<NavigationOutlined />}
          onClick={recenterMap}
          variant='contained'
          size='small'
          sx={{
            textTransform: 'none',
            position: 'absolute',
            bottom: 30,
            right: 10,
          }}
        >
          <Typography fontSize={'0.9rem'}>Recenter</Typography>
        </Button>
      )}
    </Box>
  )
}
