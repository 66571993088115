import { useDriveTrialContext } from '@pages/Details/providers/DriveTrialDataProvider'
import { MediaSyncContext } from '@pages/Details/types/providers'
import { MapMouseEvent } from 'mapbox-gl'
import { useCallback, useContext } from 'react'
import { getNearestPointForDragging } from '../utils/map'
import { useMapContext } from './useMapContext'

export function useHandleDrag() {
  const mediaSyncContext = useContext(MediaSyncContext)
  const { highlightMode } = useDriveTrialContext()
  const { dragMap, mouseDown, gpsHighMap, gpsCoordinates } = useMapContext()

  return useCallback(
    (e: MapMouseEvent) => {
      if (dragMap || !mouseDown) return
      const [nearestLongitude, nearestLatitude] = getNearestPointForDragging(
        e,
        gpsCoordinates,
        Object.values(gpsHighMap)
      )
      if (nearestLatitude === null && nearestLongitude === null) return

      mediaSyncContext.isSeeking = true

      let gpsTime = 0
      if (highlightMode.id === -1) {
        gpsTime = gpsCoordinates.findIndex(
          (point) =>
            point[0] === nearestLongitude && point[1] === nearestLatitude
        )
      } else {
        const key = Object.entries(gpsHighMap).find(
          (x) =>
            x[1] && x[1][0] === nearestLongitude && x[1][1] === nearestLatitude
        )

        if (key) gpsTime = key[0] as unknown as number
      }

      mediaSyncContext.timingObj?.update({
        position: +gpsTime,
        velocity: 0,
      })
    },
    [
      dragMap,
      gpsCoordinates,
      gpsHighMap,
      highlightMode.id,
      mediaSyncContext,
      mouseDown,
    ]
  )
}
