import { useActiveTrial } from '@common/hooks/useActiveTrial'
import { useDriveTrialContext } from '@pages/Details/providers/DriveTrialDataProvider'
import { MediaSyncContext } from '@pages/Details/types/providers'
import { useCallback, useContext } from 'react'
import { useMapContext } from './useMapContext'

export function useHandlePosition() {
  const mediaSyncContext = useContext(MediaSyncContext)
  const { highlightMode } = useDriveTrialContext()
  const { activeTrial } = useActiveTrial()
  const {
    mapRef,
    gpsCoordinates,
    gpsHighMap,
    markerRef,
    followMode,
    driveGps,
  } = useMapContext()

  return useCallback(
    (time: number) => {
      const roundPosition = Math.floor(time)
      const coords =
        highlightMode.id !== -1
          ? gpsHighMap[roundPosition]
          : gpsCoordinates[roundPosition]

      if (!gpsCoordinates.length || !mapRef.current || !coords) return

      const [longitude, latitude, speed, yaw] = coords

      if (highlightMode.id !== -1) {
        if (markerRef.current && mapRef.current) {
          markerRef.current.setPosition([longitude, latitude, speed, yaw])

          if (followMode) {
            mapRef.current.flyTo({
              center: [longitude, latitude],
              duration: 700 / mediaSyncContext.playbackSpeed,
            })
          }
        }
        return
      }

      const positionOffset = Math.floor(roundPosition - activeTrial!.startTime)

      const driveHasData = driveGps.find((x) => x.key === activeTrial?.DTID)
        ?.coordinates[positionOffset]

      if (driveHasData && markerRef.current) {
        markerRef.current.setPosition([longitude, latitude, speed, yaw])

        if (followMode) {
          mapRef.current.flyTo({
            center: [longitude, latitude],
            duration: 700 / mediaSyncContext.playbackSpeed,
          })
        }
      }
    },
    [
      highlightMode.id,
      gpsHighMap,
      gpsCoordinates,
      mapRef,
      activeTrial,
      driveGps,
      markerRef,
      followMode,
      mediaSyncContext.playbackSpeed,
    ]
  )
}
