import { TimingProgress } from '@common/services/timing/timing'
import { MediaSyncContext } from '@pages/Details/types/providers'
import { useCallback, useContext } from 'react'
import { useTransportContext } from './useTransportContext'

export function useHandleRender() {
  const mediaSyncContext = useContext(MediaSyncContext)

  const { sliderRef } = useTransportContext()

  return useCallback(
    (node: HTMLInputElement) => {
      if (!node) {
        return
      }

      sliderRef.current = node

      const p = new TimingProgress(mediaSyncContext.timingObj, node, {
        sampler: mediaSyncContext.sampler,
        range: [0, mediaSyncContext.totalDuration ?? 0],
      })

      mediaSyncContext.progress = p
    },
    [mediaSyncContext, sliderRef]
  )
}
