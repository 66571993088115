import {
  INIT_DATA_TABLE_COLUMN_VISIBILITY,
  INIT_DATA_TABLE_COLUMN_VISIBILITY_FOR_PUBLISH_ADMIN,
} from '../constants/initColumnVisibility'
import { useEffect, useMemo, useState } from 'react'
import { INIT_PERSERVE_STATE } from '../constants/initialStates'
import { PUBLISH_ADMIN } from '@api/table/adminLevel'
import { PerserveState } from '../types'
import { useAuthContext } from '@modules/auth'

export function usePreservedState() {
  const { adminLevelID } = useAuthContext()

  const [preserveState, setPreserveState] =
    useState<PerserveState>(INIT_PERSERVE_STATE)

  const isAdmin = adminLevelID === PUBLISH_ADMIN

  const initialState = useMemo(
    () => ({
      ...INIT_PERSERVE_STATE,
      completeness: {
        ...INIT_PERSERVE_STATE.completeness,
        columnVisibility: isAdmin
          ? INIT_DATA_TABLE_COLUMN_VISIBILITY_FOR_PUBLISH_ADMIN
          : INIT_DATA_TABLE_COLUMN_VISIBILITY,
      },
      quality: {
        ...INIT_PERSERVE_STATE.quality,
        columnVisibility: isAdmin
          ? INIT_DATA_TABLE_COLUMN_VISIBILITY_FOR_PUBLISH_ADMIN
          : INIT_DATA_TABLE_COLUMN_VISIBILITY,
      },
      characterization: {
        ...INIT_PERSERVE_STATE.characterization,
        columnVisibility: isAdmin
          ? INIT_DATA_TABLE_COLUMN_VISIBILITY_FOR_PUBLISH_ADMIN
          : INIT_DATA_TABLE_COLUMN_VISIBILITY,
      },
    }),
    [isAdmin]
  )

  useEffect(() => {
    setPreserveState({ ...initialState })
  }, [initialState, isAdmin])

  return {
    initialState,
    preserveState,
    setPreserveState,
  }
}
