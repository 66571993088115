import { useMapQuery } from '@api/map'
import { useEffect } from 'react'
import { GpsPoint } from '../types/map'
import { calculateBounds } from '../utils/map'
import { useMapContext } from './useMapContext'

const navigationArrow = process.env.PUBLIC_URL + '/nav-marker.svg'

export function useNavigationArrowImage() {
  const { mapRef, gpsCoordinates, setMapBounds, markerRef } = useMapContext()
  const { data } = useMapQuery()

  useEffect(() => {
    const map = mapRef.current
    const img = new Image(20, 20)

    map?.on('styleimagemissing', (e) => {
      const eventWithId = e as typeof e & { id: string }
      if (eventWithId.id === 'navigationArrow') {
        map.addImage('navigationArrow', img)
      }
    })

    if (!map || map.hasImage('navigationArrow')) return

    img.onload = () => {
      if (map.hasImage('navigationArrow')) {
        map.removeImage('navigationArrow')
      } else {
        map.addImage('navigationArrow', img)
      }
    }
    img.src = navigationArrow

    if (!data?.map) return

    const cords = data.map.map(
      ({ longitude, latitude, speed, yaw }) =>
        [longitude, latitude, speed, yaw] as GpsPoint
    )
    if (gpsCoordinates.length > 0) {
      const gp = markerRef.current?.getPosition()
      gp && mapRef.current?.setCenter([gp[0], gp[1]])
      const bounds = calculateBounds(cords)
      setMapBounds(bounds)
      mapRef?.current?.getMap().fitBounds(bounds, {
        padding: { top: 60, bottom: 60, left: 60, right: 60 },
      })
    }
  }, [data?.map, gpsCoordinates, mapRef, markerRef, setMapBounds])
}
