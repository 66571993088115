import {
  ADMIN_LEVEL_ID_KEY,
  STORAGE_CLIENT_ID_KEY,
  STORAGE_EMAIL_KEY,
  STORAGE_PROJECT_ID_KEY,
  STORAGE_UAID_KEY,
} from '@common/constants/storage'

export const setEmail = (email: string) =>
  window.localStorage.setItem(STORAGE_EMAIL_KEY, email)

export const getEmail = () =>
  window.localStorage.getItem(STORAGE_EMAIL_KEY) || ''

export const setClientID = (clientId: number) => {
  window.localStorage.setItem(STORAGE_CLIENT_ID_KEY, clientId.toFixed(0))
}

export const getClientID = () =>
  window.localStorage.getItem(STORAGE_CLIENT_ID_KEY)

export const setProjectID = (projectId: number) =>
  window.localStorage.setItem(STORAGE_PROJECT_ID_KEY, projectId.toFixed(0))

export const getProjectID = () =>
  window.localStorage.getItem(STORAGE_PROJECT_ID_KEY)

export const setUaid = (uaid: string) =>
  window.localStorage.setItem(STORAGE_UAID_KEY, uaid)

export const getUaid = () => window.localStorage.getItem(STORAGE_UAID_KEY)

export const setStorageAdminLevelID = (adminLevelId: number) =>
  window.localStorage.setItem(ADMIN_LEVEL_ID_KEY, adminLevelId.toFixed(0))

export const getStorageAdminLevelID = (): number => {
  const value = window.localStorage.getItem(ADMIN_LEVEL_ID_KEY)
  return parseInt(value || '', 10) || 0
}
